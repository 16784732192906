<template>
  <div class="box">
    <h2 class="text-center mb-10">
      Вход в систему управления<br>контентом «Атис Трейд»
    </h2>

    <ValidationObserver
      ref="observer"
    >
      <v-form>
        <ValidationProvider
          v-slot="{ errors }"
          vid="email"
          name="E-mail"
          rules="required"
        >
          <v-text-field
            v-model="model.email"
            outlined
            dense
            rounded
            color="primary"
            placeholder="E-mail"
            label="E-mail"
            type="email"
            :error-messages="errors"
            required
          />
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          vid="password"
          name="Пароль"
          rules="required"
        >
          <v-text-field
            v-model="model.password"
            outlined
            dense
            rounded
            color="primary"
            placeholder="Пароль"
            label="Пароль"
            type="password"
            :error-messages="errors"
            required
          />
        </ValidationProvider>
        <div class="d-flex justify-space-between">
          <v-spacer />
          <v-btn
            text
            color="primary"
            elevation="0"
            small
            @click="$emit('forgot-password')"
          >
            Забыли пароль?
          </v-btn>
        </div>

        <v-btn
          color="primary"
          rounded
          dense
          block
          elevation="0"
          type="submit"
          class="font-weight-bold mt-5"
          :loading="loading"
          :disabled="loading"
          @click="login"
        >
          ВХОД
        </v-btn>
      </v-form>
    </validationobserver>
  </div>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
  name: "LoginBox",
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      loading: false,
      model: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    async login() {
      try {
        this.loading = true
        await this.$store.dispatch('login', this.model)
        await this.$router.push('/')
      } catch(error) {
        if (error.fields) {
          this.$refs.observer.setErrors(error.fields);
        } else {
          this.$refs.observer.setErrors({ email: error.message });
        }
      } finally {
        this.loading = false
      }

    },
  }
}
</script>

<style scoped>
.box {
  background: #FFFFFF;
  padding: 48px 60px;
  width: 100%;
  max-width: 624px;
}
</style>
