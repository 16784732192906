import { extend, localize } from 'vee-validate';
import { required, email, image, max, regex, min, digits, length, numeric, double, integer, min_value, max_value } from 'vee-validate/dist/rules';

import ru from 'vee-validate/dist/locale/ru.json';

localize('ru', ru);

localize('ru', {
    fields: {
        'email': {
            required: "Укажите e-mail",
            email: "Поле e-mail должно быть действительным электронным адресом"
        },
        'phone': {
            required: "Укажите номер телефона",
            phone: "Телефон указан в неверном формате (должен начинаться с \"+7\" и содержать 11 цифр)"
        }
    }
});

extend('required', { ...required });
extend('email', { ...email });
extend('image', { ...image });
extend('max', { ...max });
extend('min', { ...min });
extend('min_value', { ...min_value });
extend('max_value', { ...max_value });
extend('lengths', { ...length });
extend('digits', { ...digits });
extend('regex', { ...regex });
extend('numeric', { ...numeric });
extend('integer', { ...integer });
extend('double', { ...double });

extend('phone', {
    validate: (value) => {
        const filteredValue = value.replace(/[^+0-9]+/g, ''); // keep only '+' and digits
        return /^\+7\d{10}$/.test(filteredValue);
    },
    message: 'Телефон указан в неверном формате (должен начинаться с "+7" и содержать 11 цифр)'
});
