<template>
  <v-container v-if="organization">
    <div class="mb-2 information-section-title">
      Основные сведения
    </div>

    <v-divider class="mb-5" />

    <form-row v-if="organization.type !== 'private'" label="Статус компании из ДаДаты">
      <span :style="{ color: getDaDataStatusColor(organization.dadata_status) }">{{ fallback(getDaDataStatusText(organization.dadata_status)) }}</span>
      <v-tooltip
        top
      >
        <template #activator="{ on, attrs }">
          <v-btn
            style="margin: 4px;"
            icon
            x-small
            v-bind="attrs"
            v-on="on"
            :loading="updatingDaDataStatus"
            @click="updateDaDataStatus"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Последнее обновление: {{ formatDateHumaneNoWeekday(organization.dadata_status_updated_at) }}</span>
      </v-tooltip>
    </form-row>

    <form-row label="Полное наименование организации">
      {{ organization.name }}
    </form-row>

    <form-row label="Организационно-правовая форма">
      {{ ORGANIZATION_TYPE[organization.type] }}
    </form-row>

    <form-row label="ИНН">
      {{ organization.inn }}
    </form-row>

    <form-row label="КПП">
      {{ fallback(organization.kpp) }}
    </form-row>

    <form-row label="ОГРН">
      {{ fallback(organization.ogrn) }}
    </form-row>

    <form-row label="Юридический адрес">
      {{ fallback(legalAddress) }}
    </form-row>

    <form-row label="Почтовый адрес">
      {{ fallback(postalAddress, '&mdash;') }}
    </form-row>

    <form-row label="Роль на площадке">
      {{ fallback(ROLES[organization.role], '&mdash;') }}
    </form-row>

    <div class="mb-2 mt-10 information-section-title">
      Контактная информация
    </div>

    <v-divider class="mb-5" />

    <form-row label="ФИО">
      {{ organization.contact_last_name }} {{ organization.contact_name }} {{ organization.contact_middle_name }}
    </form-row>

    <form-row label="Служебный e-mail">
      <span class="email">{{ fallback(organization.contact_email, '&mdash;') }}</span>
    </form-row>

    <form-row label="Служебный номер телефона">
      <span>{{ fallback(organization.contact_phone, '&mdash;') }}</span>
    </form-row>

    <div class="mb-2 mt-10 information-section-title">
      Баланс
    </div>

    <v-divider class="mb-5" />

    <form-row label="Деньги">
      {{ organization.wallet_balance }} руб.
    </form-row>

    <form-row label="Бонусы">
      {{ organization.wallet_bonuses }}
    </form-row>

    <organization-add-bonuses
      :organization-inn="organization.inn"
      @success="$emit('refresh-required')"
    />

    <template v-if="organization.categories?.length">
      <div class="mb-2 mt-10 information-section-title">
        Сферы деятельности
      </div>

      <v-divider class="mb-5" />

      <form-row
        v-for="(item, index) in organization.categories"
        :key="`category-${index}`"
        :label="`Сфера ${index + 1}`"
      >
        {{ item }}
      </form-row>
    </template>

    <template v-if="organization.delivery_addresses?.length">
      <div class="mb-2 mt-10 information-section-title">
        Адреса доставки
      </div>

      <v-divider class="mb-5" />

      <form-row
        v-for="(address, index) in organization.delivery_addresses"
        :key="`address-${index}`"
        :label="`Адрес ${index + 1}`"
      >
        <span>{{ address.city }}, {{ address.street }} {{ address.house }}</span>
      </form-row>
    </template>


    <template v-if="organization.documents?.length">
      <div class="mb-2 mt-10 information-section-title">
        Документы
      </div>
      <v-divider class="mb-5" />

      <div
        v-for="(document, index) in organization.documents"
        :key="`document-${index}`"
        class="document mb-4"
      >
        <a
          :href="document.url"
          target="_blank"
        >
          <v-icon color="primary">
            mdi-file
          </v-icon> {{ document.original_name }}
        </a>
      </div>
    </template>

    <template v-if="!hideActions">
      <div
        v-if="(type === 'deactivation' && deactivationStatus === 'pending') || type !== 'deactivation'"
        class="mb-2 mt-10 information-section-title"
      >
        Действия
      </div>
      <v-divider class="mb-5" />

      <v-row v-if="type !== 'deactivation'">
        <v-col
          cols="6"
        >
          <v-btn
            block
            elevation="0"
            color="success"
            :disabled="organization.trade_admission_status === 'approved'"
            @click="openSubmitDialog(SUBMIT_TYPE.trade, 'apply')"
          >
            Допустить к торгам
          </v-btn>
        </v-col>

        <v-col
          cols="6"
        >
          <v-btn
            block
            elevation="0"
            color="error"
            :disabled="organization.trade_admission_status === 'rejected' || submitting"
            @click="openSubmitDialog(SUBMIT_TYPE.trade, 'reject')"
          >
            Отклонить доступ к торгам
          </v-btn>
        </v-col>

        <v-col cols="12">
          <v-tooltip bottom :disabled="canDetachOrganization || submitting">
            <template #activator="{ on, attrs }">
              <div
                  v-on="on"
                  v-bind="attrs"
              >
                <v-btn
                    block
                    elevation="0"
                    color="error"
                    outlined
                    :disabled="!canDetachOrganization || submitting"
                    @click="detach"
                >
                  Открепить организацию от пользователя
                </v-btn>
              </div>
            </template>
            <div v-if="organization.wallet_balance > 0">Действие недоступно, поскольку на счету организации есть средства</div>
          </v-tooltip>
        </v-col>
        <v-col cols="12">
          <v-btn
            v-if="organization.is_detached"
            :disabled="submitting"
            elevation="0"
            block
            color="info"
            @click="isShowAddOwnerDialog = true"
          >
            Прикрепить организацию к пользователю
          </v-btn>
        </v-col>
      </v-row>
    </template>

    <v-row v-if="type === 'deactivation' && deactivationStatus === 'pending'">
      <v-col cols="12">
        <v-textarea
          v-model="declineComment"
          outlined
          name="input-7-4"
          label="Оставьте комментарий"
        />
      </v-col>

      <v-col
        cols="6"
      >
        <v-btn
          block
          color="primary"
          elevation="0"
          @click="openSubmitDialog(SUBMIT_TYPE.delete, 'apply')"
        >
          Исполнить заявку на деактивацию
        </v-btn>
      </v-col>

      <v-col
        cols="6"
      >
        <v-btn
          block
          color="normal"
          elevation="0"
          @click="openSubmitDialog(SUBMIT_TYPE.delete, 'cancel')"
        >
          Отклонить заявку на деактивацию
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog
      v-model="isShowAddOwnerDialog"
      width="820"
      scrollable
    >
      <organization-users
        :organization="organization"
        :employees="employees"
        @back="isShowAddOwnerDialog = false"
        @close="closeOwnersSetup"
        @refresh-required="$emit('refresh-required')"
      />
    </v-dialog>

    <v-dialog
      v-model="isShowSubmitDialog"
      width="500"
      :persistent="submitDialogLoading"
    >
      <v-card>
        <v-card-title>Подтвердите действие</v-card-title>

        <v-card-text v-if="submitDialogType === SUBMIT_TYPE.attach">
          Вы уверены, что хотите открепить организацию от пользователя?
        </v-card-text>

        <v-card-text v-else-if="submitDialogType === SUBMIT_TYPE.trade">
          Вы уверены, что хотите {{ dialogType === 'apply' ? 'выдать' : 'отклонить' }} доступ организации к торгам?
        </v-card-text>

        <v-card-text v-else-if="submitDialogType === SUBMIT_TYPE.delete && dialogType === 'apply'">
          Вы уверены, что хотите деактивировать организацию?
        </v-card-text>

        <v-card-text v-else-if="submitDialogType === SUBMIT_TYPE.delete && dialogType === 'cancel'">
          Вы уверены, что хотите отклонить заявку на деактивацию организации?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            :loading="submitDialogLoading"
            style="margin-right: 1em"
            @click="acceptSubmitDialog"
          >
            Подтвердить
          </v-btn>
          <v-btn
            color="normal"
            text
            :disabled="submitDialogLoading"
            @click="isShowSubmitDialog = false"
          >
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import FormRow from "@/components/FormRow";
import { ROLES, ORGANIZATION_TYPE } from "@/constants/constants";
import {
  approveTradeAdmissionRequest, callAPI,
  declineDeactivationRequest,
  deleteOrganization, detachOrganization,
  listUsers, rejectTradeAdmissionRequest,
} from "@/services/api";
import OrganizationUsers from "@/components/Orgranizations/OrgranizationsList/OrganizationDialogTabs/OrganizationUsers";
import OrganizationAddBonuses from "@/components/Orgranizations/OrgranizationsList/OrganizationAddBonuses.vue";
import { fallback, formatDateHumaneNoWeekday } from "@/utils/helpers.js";

const SUBMIT_TYPE = {
  attach: 'ATTACH',
  trade: 'TRADE',
  delete: 'DELETE'
}

export default {
  name: "OrganizationInformation",

  components: {
    OrganizationAddBonuses,
    OrganizationUsers,
    FormRow
  },

  props: {
    organization: {
      type: Object,
      default: () => ({})
    },

    employees: {
      type: Array,
      default: () => ([])
    },

    type: {
      type: String,
      default: ''
    },

    deactivationStatus: {
      type: String,
      default: ''
    },

    hideActions: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      ROLES,
      SUBMIT_TYPE,
      ORGANIZATION_TYPE,

      loadingUsers: false,
      isShowAddOwnerDialog: false,
      isShowSubmitDialog: false,
      dialogType: '',

      submitDialogLoading: false,
      submitDialogType: '',

      updatingDaDataStatus: false,
      submitting: false,

      search: '',
      headers: [
        {
          text: 'ФИО',
          align: 'start',
          sortable: false,
          value: 'full_name',
        },
        { text: 'Email', value: 'email' },
        { text: 'Телефон', value: 'phone' }
      ],
      users: [],
      declineComment: ''
    }
  },

  computed: {
    legalAddress () {
      const address = this.organization.legal_address;
      if (address) {
        return `${address.city ?? ''} ${address.street ?? ''} ${address.house ?? ''}`;
      }

      return '';
    },

    postalAddress () {
      const address = this.organization.postal_address;
      if (address) {
        return `${address.city || ''} ${address.street || ''} ${address.house || ''}`.trim();
      }

      return '';
    },

    canDetachOrganization() {
      if (this.organization.is_detached) {
        return false; // can't detach already detached organization
      }

      if (this.organization.wallet_balance > 0) {
        return false; // can't detach organization with money on balance
      }

      return true;
    }
  },

  methods: {
    formatDateHumaneNoWeekday,
    fallback,

    async findUsers () {
      try {
        this.loadingUsers = true;

        const { items } = await listUsers({query: this.search, page: 1, limit: 10});

        this.users = items.map(i => ({
          ...i,
          email: i.email,
          phone: i.phone,
          full_name: `${i.last_name} ${i.name} ${i.middle_name}`
        }));
      } finally {
        this.loadingUsers = false;
      }
    },

    async acceptDetach () {
      this.submitting = true;

      try {
        await detachOrganization({
          organization_inn: this.organization.inn,
        });

        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Организация откреплена от пользователя',
          duration: 5000
        });
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Не удалось открепить организацию от пользователя',
          duration: 5000
        });
      } finally {
        this.submitting = false;
        this.isShowSubmitDialog = false;
        this.$emit('close');
      }
    },

    async deleteOrganization () {
      try {
        await deleteOrganization({organization_inn: this.organization.inn, comment: this.declineComment});

        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Организация деактивирована',
          duration: 5000
        });
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Не удалось деактивировать организацию',
          duration: 5000
        });
      } finally {
        this.isShowSubmitDialog = false;
        this.dialogType = '';
        this.$emit('close');
      }
    },

    async declineDeactivation () {
      try {
        await declineDeactivationRequest({organization_inn: this.organization.inn, comment: this.declineComment});

        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Деактивация отклонена',
          duration: 5000
        });
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Не удалось отклонить деактивацию',
          duration: 5000
        });
      } finally {
        this.isShowSubmitDialog = false;
        this.dialogType = '';
        this.$emit('close');
      }
    },

    async applyToTrade () {
      try {
        await approveTradeAdmissionRequest({
          organization_inn: this.organization.inn
        })

        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Организация допущена к торгам',
          duration: 5000
        });
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Не удалось допустить к торгам',
          duration: 5000
        });
      } finally {
        this.isShowSubmitDialog = false;
        this.submitDialogType = '';
        this.dialogType = '';
        this.$emit('close');
      }
    },

    async rejectToTrade () {
      try {
        await rejectTradeAdmissionRequest({
          organization_inn: this.organization.inn
        })

        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Допуск к торгам отклонен',
          duration: 5000
        });
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Не удалось выполнить запрос',
          duration: 5000
        });
      } finally {
        this.isShowSubmitDialog = false;
        this.submitDialogType = '';
        this.dialogType = '';
        this.$emit('close');
      }
    },

    openSubmitDialog(type, tradeAction = '') {
      this.submitDialogType = type;
      this.isShowSubmitDialog = true;
      this.dialogType = tradeAction;
    },

    async acceptSubmitDialog () {
      try {
        this.submitDialogLoading = true;

        if (this.submitDialogType === SUBMIT_TYPE.attach) {
          await this.acceptDetach();
          this.$emit('refresh-required');
        }

        if (this.submitDialogType === SUBMIT_TYPE.trade && this.dialogType === 'reject') {
          await this.rejectToTrade();
          this.$emit('refresh-required');
        }

        if (this.submitDialogType === SUBMIT_TYPE.trade && this.dialogType === 'apply') {
          await this.applyToTrade();
          this.$emit('refresh-required');
        }

        if (this.submitDialogType === SUBMIT_TYPE.delete && this.dialogType === 'apply') {
          await this.deleteOrganization();
        }

        if (this.submitDialogType === SUBMIT_TYPE.delete && this.dialogType === 'cancel') {
          await this.declineDeactivation();
        }
      } finally {
        this.submitDialogLoading = false;
      }
    },

    detach () {
      if (this.organization.is_detached && this.organization.wallet_balance > 0) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Чтобы открепить организацию, на балансе не должно быть денежных средств',
          duration: 5000
        });
      } else {
        this.openSubmitDialog(SUBMIT_TYPE.attach);
      }
    },

    closeOwnersSetup () {
      this.isShowAddOwnerDialog = false;
      this.$emit('close');
    },

    getDaDataStatusText(status) {
      switch (status) {
        case 'ACTIVE':
          return 'Действующая'
        case 'LIQUIDATING':
          return 'Ликвидируется'
        case 'LIQUIDATED':
          return 'Ликвидирована'
        case 'BANKRUPT':
          return 'Банкротство'
        case 'REORGANIZING':
          return 'В процессе присоединения к другому юрлицу, с последующей ликвидацией'
        default:
          return status;
      }
    },
    getDaDataStatusColor(status) {
      switch (status) {
        case 'ACTIVE':
          return 'var(--v-success-base)';
        case 'LIQUIDATING':
          return 'var(--v-error-base)';
        case 'LIQUIDATED':
          return 'var(--v-error-base)';
        case 'BANKRUPT':
          return 'var(--v-error-base)';
        case 'REORGANIZING':
          return 'var(--v-error-base)';
        default:
          return '';
      }
    },
    async updateDaDataStatus() {
      this.updatingDaDataStatus = true;
      try {
        await callAPI('POST', '/api/operator/updateOrganizationStatus', { organization_inn: this.organization.inn });
        this.$emit('refresh-required');
      } finally {
        this.updatingDaDataStatus = false;
      }
    }
  }
}
</script>

<style scoped>
.information-section-title {
  color: gray;
  font-size: 18px;
}

.email {
  color: #1B7DC9;
}

.document {
  color: #1B7DC9;
}
</style>
