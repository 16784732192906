<template>
  <v-container
    fluid
    class="px-10"
  >
    <h1>Финансы</h1>
    <div>Заявки по кошелькам организаций, настройки бонусов и тарифов</div>

    <v-btn-toggle
      v-model="mode"
      mandatory
      class="mt-5"
    >
      <v-btn
        value="deposits"
        color="white"
        @click="$router.push({ path: '/finance', query: {}})"
      >
        Управление заявками на ввод средств
      </v-btn>
      <v-btn
        value="withdrawals"
        color="white"
        @click="$router.push({ path: '/finance', query: { tab: 'withdrawals' }})"
      >
        Управление заявками на вывод средств
      </v-btn>
      <v-btn
          value="invoices"
          color="white"
          disabled
          @click="$router.push({ path: '/finance', query: { tab: 'invoices' }})"
      >
        Управление счетами на оплату
      </v-btn>
      <v-btn
          value="plans"
          color="white"
          @click="$router.push({ path: '/finance', query: { tab: 'plans' }})"
      >
        Тарифные планы
      </v-btn>
      <v-btn
        value="bonuses"
        color="white"
        @click="$router.push({ path: '/finance', query: { tab: 'bonuses' }})"
      >
        Настройки бонусов
      </v-btn>
    </v-btn-toggle>

    <div class="mt-10">
      <v-slide-x-transition mode="out-in">
        <deposits-tab v-if="mode === 'deposits'" />
        <withdrawals-tab v-else-if="mode === 'withdrawals'" />
        <bonuses-tab v-else-if="mode === 'bonuses'" />
        <invoices-tab v-else-if="mode === 'invoices'" />
        <plans-tab v-else-if="mode === 'plans'" />
      </v-slide-x-transition>
    </div>
  </v-container>
</template>

<script>
import WithdrawalsTab from "@/components/Finance/Withdrawals/WithdrawalsTab";
import DepositsTab from "@/components/Finance/Deposits/DepositsTab";
import InvoicesTab from "@/components/Finance/Invoices/InvoicesTab";
import BonusesTab from "@/components/Finance/Bonuses/BonusesTab";
import PlansTab from "@/components/Finance/Plans/PlansTab.vue";

export default {
  name: "FinanceView",
  components: {
    PlansTab,
    BonusesTab,
    WithdrawalsTab,
    DepositsTab,
    InvoicesTab,
  },

  data() {
    return {
      mode: "deposits",

    }
  },

  beforeMount() {
    if (this.$route.query?.tab) {
      this.mode = this.$route.query?.tab;
    }
  }
}
</script>

<style scoped>

</style>
