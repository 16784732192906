<template>
  <div>
    <h2 class="mb-5">
      Заявления на аккредитацию организаций
    </h2>

    <div class="organizations-list__filters">
      <FilterButton
        :applied="filters !== null"
        @click="accreditationRequestsFiltersDialog = true"
        @clear="$refs.filterDialog.resetFilter()"
      />

      <v-spacer />

      <search-input
        v-model="search"
        label="Поиск по названию и ИНН организации"
        placeholder="Введите ИНН или название организации"
        style="max-width: 400px;"
        @search="options.page !== 1 ? (options.page = 1) : loadOrganizations()"
      />
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :server-items-length="serverItemsLength"
      :hide-default-header="loading"
      :hide-default-footer="loading"
      style="border: 1px solid rgba(0, 0, 0, 0.12)"
      :item-class="'activeStatus'"
      loading-text="Организации загружаются..."
      :footer-props="{ 'items-per-page-options': [ 10, 30, 50, 100 ], 'items-per-page-text': 'Организаций на странице' }"
      @update:page="onChangePage"
      @update:items-per-page="onChangeItemsPerPage"
    >
      <template #[`item.status_reason`]="{ item }">
        {{ fallback(item.status_reason) }}
      </template>

      <template #[`item.status`]="{ item }">
        <v-chip
          :color="ACCREDITATION_COLOR[item.status]"
          small
        >
          {{ ACCREDITATION_STATUS[item.status] }}
        </v-chip>
      </template>

      <template #[`item.actions`]="{ item }">
        <v-btn
          text
          @click="showAccreditationRequestDialog(item)"
        >
          <v-icon>
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <accreditation-requests-filters-dialog
      v-model="accreditationRequestsFiltersDialog"
      ref="filterDialog"
      @apply="applyFilters"
      @reset="resetFilters"
    />

    <accreditation-request-dialog
      v-model="accreditationRequestDialog"
      :org-id="openedOrganizationId"
      @close="loadOrganizations"
    />
  </div>
</template>

<script>
import FilterButton from "@/components/Buttons/FilterButton";
import AccreditationRequestsFiltersDialog
  from "@/components/Orgranizations/AccreditationRequests/AccreditationRequestsFiltersDialog";
import AccreditationRequestDialog from "@/components/Orgranizations/AccreditationRequests/AccreditationRequestDialog";
import {listAccreditationRequests} from "@/services/api";
import { ACCREDITATION_STATUS, ACCREDITATION_COLOR } from "@/constants/constants";
import { fallback } from "@/utils/helpers";
import SearchInput from "@/components/SearchInput.vue";

export default {
  name: "AccreditationRequestsTab",

  components: {
    SearchInput,
    AccreditationRequestsFiltersDialog,
    AccreditationRequestDialog,
    FilterButton
  },

  data() {
    return {
      ACCREDITATION_STATUS,
      ACCREDITATION_COLOR,

      openedOrganizationId: null,
      accreditationRequestsFiltersDialog: false,
      accreditationRequestDialog: false,

      loading: false,

      serverItemsLength: 0,

      options: {
        page: 1,
        itemsPerPage: 10
      },

      filters: null,
      search: '',

      headers: [
        {
          text: 'Наименование организации',
          sortable: false,
          value: 'organization_name',
          align: 'start'
        },
        {
          text: 'ИНН',
          sortable: false,
          value: 'organization_inn',
          align: 'start'
        },
        {
          text: 'Статус',
          sortable: false,
          value: 'status',
          align: 'start'
        },
        {
          text: 'Комментарий',
          sortable: false,
          value: 'status_reason',
          align: 'start',
          width: 300
        },
        {
          text: 'Действия',
          sortable: false,
          value: 'actions',
          align: 'end'
        },
      ],
      items: []
    }
  },

  mounted () {
    this.loadOrganizations();
  },

  methods: {
    fallback,
    async loadOrganizations() {
      try {
        this.loading = true;
        this.items.splice(0, this.items.length); // clear items array

        let status;
        if (this.filters) {
          status = this.filters.status;
        }

        this.items = [];

        const { items, total } = await listAccreditationRequests({
          page: this.options.page,
          limit: this.options.itemsPerPage,
          status: status,
          search: this.search
        });

        this.items = items;
        this.serverItemsLength = total;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    showAccreditationRequestDialog(item) {
      this.openedOrganizationId = item.organization_inn;
      this.accreditationRequestDialog = true;
    },

    onChangeItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.loadOrganizations();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },

    onChangePage(page) {
      this.page = page;
      this.loadOrganizations();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },

    applyFilters(filters) {
      this.accreditationRequestsFiltersDialog = false;
      this.filters = filters;
      this.options.page = 1;
      this.loadOrganizations();
    },

    resetFilters() {
      this.accreditationRequestsFiltersDialog = false;
      this.filters = null;
      this.options.page = 1;
      this.loadOrganizations();
    }
  }
}
</script>

<style scoped>

</style>
