<template>
  <div>
    <div style="display: flex;">

      <v-btn color="primary" outlined @click="$refs.customerPlansTextDialog.show()" :disabled="isSortModeEnabled">
        <v-icon class="mr-2">mdi-pencil</v-icon>
        Общее описание
      </v-btn>

      <v-spacer/>

      <div style="margin-right: 1rem;">
        <v-btn v-if="!isSortModeEnabled" color="primary" outlined @click="enableSortMode()">
          <v-icon class="mr-2">mdi-sort</v-icon>
          Редактировать порядок
        </v-btn>
        <v-btn v-else color="primary" outlined @click="disableSortMode()" :loading="sortingSubmitting">
          <v-icon class="mr-2">mdi-content-save</v-icon>
          Сохранить сортировку
        </v-btn>
      </div>

      <v-btn color="primary" outlined @click="createPlan()" :disabled="isSortModeEnabled">
        <v-icon class="mr-2">mdi-plus</v-icon>
        Создать тариф
      </v-btn>
    </div>

    <div style="height: 1rem;"></div>

    <v-data-table
        :headers="table.headers"
        :items="table.items"
        :loading="table.loading"
        style="border: 1px solid rgba(0, 0, 0, 0.12)"
        :item-class="(item) => item.archived_at !== null ? 'grey lighten-4' : ''"
        hide-default-footer
        :items-per-page="-1"
        @update:page="loadPlans"
        @update:items-per-page="loadPlans"
    >
      <template #[`item.name`]="{ item, index }">
        <div style="display: flex; align-items: center;">
          <v-scroll-x-transition>
            <div v-if="isSortModeEnabled" style="margin-right: 1rem; display: flex; flex-direction: row;">
              <v-btn :disabled="index === 0" @click="sortUp(item)" icon><v-icon>mdi-arrow-up-drop-circle</v-icon></v-btn>
              <v-btn :disabled="table.items.length-1 === index" @click="sortDown(item)" icon><v-icon>mdi-arrow-down-drop-circle</v-icon></v-btn>
            </div>
          </v-scroll-x-transition>
          <div>
            <div>{{ item.name }}</div>
            <div class="text-caption" style="opacity: 0.5;">Подключен у <strong>{{ item.active_organization_count }}</strong> организаций</div>
          </div>
        </div>
      </template>
      <template #[`item.limits`]="{ item }">
        <div style="white-space: nowrap;">
          <diV class="text-caption"><span style="opacity: 0.75;">Число закупок:</span> {{ item.procurement_count <= 0 ? '∞' : item.procurement_count }}</diV>
          <div class="text-caption"><span style="opacity: 0.75;">Сумма закупок:</span> {{ item.procurement_sum <= 0 ? '∞' : formatAmount(item.procurement_sum) }}</div>
          <div class="text-caption"><span style="opacity: 0.75;">Срок действия (дней):</span> {{ item.duration_days < 0 ? '∞' : item.duration_days }}</div>
          
        </div>
      </template>
      <template #[`item.price`]="{ item }">
        <div style="white-space: nowrap;">
          <div v-if="item.old_price" style="opacity: 0.5; text-decoration: line-through;">{{ formatAmount(item.old_price) }}</div>
          {{ formatAmount(item.price) }}
        </div>
      </template>
      <template #[`item.group`]="{ item }">
        {{ item.is_group ? 'Да' : 'Нет' }}
        <diV v-if="item.is_group" class="text-caption"><span style="opacity: 0.75;">Макс. организаций:</span> {{ item.is_group ? (item.max_organization_count <= 0 ? '∞' : item.max_organization_count) : '—' }}</diV>
      </template>
      <template #[`item.archived`]="{ item }">
        <span v-if="item.archived_at" style="font-weight: 500;">Да</span>
        <span v-else style="opacity: 0.75;">Нет</span>
      </template>
      <template #[`item.actions`]="{ item }">
        <div style="white-space: nowrap;">
          <v-tooltip top :disabled="isSortModeEnabled">
            <template #activator="{ on, attrs }">
              <v-btn :disabled="isSortModeEnabled" icon v-bind="attrs" v-on="on" @click="editPlan(item.key)"><v-icon>mdi-arrow-right</v-icon></v-btn>
            </template>
            Открыть подробности
          </v-tooltip>
        </div>
      </template>
    </v-data-table>

    <plan-dialog
      ref="planDialog"
      @refresh="loadPlans"
    />

    <customer-plans-text-dialog
      ref="customerPlansTextDialog"
    />
  </div>
</template>

<script>
import { formatAmount } from "@/utils/helpers";
import PlanDialog from "@/components/Finance/Plans/PlanDialog.vue";
import { callAPI } from "@/services/api";
import CustomerPlansTextDialog from "@/components/Finance/Plans/CustomerPlansTextDialog.vue";

export default {

  components: {
    CustomerPlansTextDialog,
    PlanDialog
  },

  data() {
    return {
      table: {
        headers: [
          {
            text: 'Наименование',
            sortable: false,
            value: 'name',
            align: 'start'
          },
          {
            text: 'Стоимость',
            sortable: false,
            value: 'price',
            align: 'end'
          },
          {
            text: 'Лимиты',
            sortable: false,
            value: 'limits',
            align: 'start',
            width: 250
          },
          {
            text: 'Групповой',
            sortable: false,
            value: 'group',
            align: 'center'
          },
          {
            text: 'Архивный',
            sortable: false,
            value: 'archived',
            align: 'center'
          },
          {
            text: 'Действия',
            sortable: false,
            value: 'actions',
            align: 'end'
          }
        ],
        items: [],
        loading: false
      },
      isSortModeEnabled: false,
      sortingSubmitting: false
    }
  },

  mounted() {
    this.loadPlans();
  },

  methods: {
    formatAmount,

    async loadPlans({ doNotClear } = { doNotClear: false }) {
      this.table.loading = true;
      if (!doNotClear) {
        this.table.items = [];
      }
      const items = await callAPI('POST', '/api/operator/listPlans');
      this.table.items = items;
      this.table.loading = false;
    },

    enableSortMode() {
      this.isSortModeEnabled = true;
    },
    async disableSortMode() {
      this.sortingSubmitting = true;
      try {
        await callAPI('POST', '/api/operator/sortPlans', { 'keys': this.table.items.map(item => item.key) });
        this.isSortModeEnabled = false;
        this.$store.dispatch('notify', 'Порядок тарифов сохранен');
        this.loadPlans({ doNotClear: true });
      } catch (error) {
        this.$store.dispatch('notify', error.message);
      } finally {
        this.sortingSubmitting = false;
      }
    },

    sortDown(item) {
      const items = this.table.items;

      const index = items.indexOf(item);
      if (index === -1) {
        return;
      }
      if (index > items.length-1) {
        return;
      }

      // Swap the element with the one below it
      [ items[index], items[index + 1] ] = [ items[index + 1], items[index] ];

      this.table.items = [...items];
    },
    sortUp(item) {
      const items = this.table.items;

      const index = items.indexOf(item);
      if (index === -1) {
        return;
      }
      if (index <= 0) {
        return;
      }

      // Swap the element with the one above it
      [ items[index], items[index - 1] ] = [ items[index - 1], items[index] ];

      this.table.items = [...items];
    },

    createPlan() {
      this.$refs.planDialog.show(null);
    },

    editPlan(plan_key) {
      this.$refs.planDialog.show(plan_key);
    }
  }
}
</script>