<template>
  <v-dialog
    v-model="show"
    max-width="657"
  >
    <v-card>
      <v-card-title />
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <v-icon
              size="72px"
              color="primary"
            >
              mdi-alert
            </v-icon>
          </v-col>
          <v-col
            cols="12"
            class="text-center"
          >
            <h2 style="margin-bottom: 0.5rem;">
              Вы уверены?
            </h2>

            <span v-html="text"></span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <div class="mx-auto">
          <v-btn
            elevation="0"
            color="white"
            class="px-10 mb-5 mr-3"
            @click="onCancel"
          >
            Отмена
          </v-btn>
          <v-btn
            elevation="0"
            color="primary"
            class="px-10 mb-5"
            @click="onOk"
          >
            ОК
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AreYouSureDialog",
  props: {
    value: Boolean,
    text: {
      default: '',
      type: String
    },
    onOkCallback: {
      type: Function,
      default: () => {}
    },
    onCancelCallback: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    onOk() {
      this.show = false
      if (this.onOkCallback) {
        this.onOkCallback()
      }
    },
    onCancel() {
      this.show = false
      if (this.onCancelCallback) {
        this.onCancelCallback()
      }
    }
  }
}
</script>

<style scoped>

</style>