<template>
  <div>
    <h2 class="mb-5 atis-page-subtitle">
      Список организаций
    </h2>

    <div class="organizations-list__filters">
      <FilterButton
        :applied="filters !== null"
        @click="organizationsFiltersDialog = true"
        @clear="$refs.filterDialog.resetFilter()"
      />

      <v-spacer />

      <search-input
        v-model="search"
        label="Поиск по названию и ИНН организации"
        placeholder="Введите ИНН или название организации"
        style="max-width: 400px"
        @search="page !== 1 ? (page = 1) : loadOrganizations()"
      />
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :page="page"
      :item-class="rowClass"
      :server-items-length="serverItemsLength"
      :items-per-page="itemsPerPage"
      loading-text="Организации загружаются..."
      style="border: 1px solid rgba(0, 0, 0, 0.12)"
      :loading="loading"
      :hide-default-header="loading"
      :hide-default-footer="loading"
      :footer-props="{ 'items-per-page-options': [ 10, 30, 50, 100 ], 'items-per-page-text': 'Организаций на странице' }"
      @update:page="onChangePage"
      @update:items-per-page="onChangeItemsPerPage"
    >
      <template #[`item.name`]="{ item }">
        <div class="py-4">
          <div>{{ fallback(item.name) }}</div>

          <div class="opfType mt-1">
            {{ item.type === 'legal' ? 'Юридическое лицо' : '' }}
          </div>

          <div class="opfType mt-1">
            ИНН: {{ fallback(item.inn) }}
          </div>

          <div class="opfType mt-1">
            КПП: {{ fallback(item.kpp) }}
          </div>

          <div class="opfType mt-1">
            ОГРН: {{ fallback(item.ogrn) }}
          </div>
        </div>
      </template>

      <template #[`item.contact`]="{ item }">
        <div v-if="item.owner_user_id">
          <div>
            {{ fallback([item.contact_name, item.contact_last_name].filter(a => a).join(' ')) }}
          </div>

          <div class="email">
            {{ fallback(item.contact_email) }}
          </div>
          <div class="email mt-1">
            {{ fallback(item.contact_phone) }}
          </div>
        </div>
        <div v-else>
          <i class="email text-caption">Организация деактивирована</i>
        </div>
      </template>

      <template #[`item.role`]="{ item }">
        <div>{{ fallback(ROLES[item.role]) }}</div>
      </template>

      <template #[`item.allow`]="{ item }">
        <v-chip
          :color="TRADE_COLOR[item.trade_admission_status]"
          small
        >
          {{ TRADE_STATUS[item.trade_admission_status] }}
        </v-chip>
      </template>


      <template #[`item.status`]="{ item }">
        <span
          style="font-weight: 500; font-size: 14px;"
          :style="`color: ${ACCREDITATION_COLOR[item.accreditation_status]}`"
        >{{ ACCREDITATION_STATUS[item.accreditation_status] }}</span>
      </template>

      <template #[`item.actions`]="{ item }">
        <v-btn
          text
          @click="showOrganizationDialog(item.inn)"
        >
          <v-icon>
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <organizations-filters-dialog
      v-model="organizationsFiltersDialog"
      ref="filterDialog"
      @apply="applyFilters"
    />

    <organization-dialog
      v-model="isOpenOrganization"
      :org-id="openedOrganizationId"
      @input="closeDialog"
      @close="closeDialog(true)"
      @refresh-required="loadOrganizations"
    />
  </div>
</template>

<script>
import OrganizationsFiltersDialog from "@/components/Orgranizations/OrgranizationsList/OrganizationsFiltersDialog";
import OrganizationDialog from "@/components/Orgranizations/OrgranizationsList/OrganizationDialog";
import {getOrganizationsList} from "@/services/api";
import { ROLES, TRADE_COLOR, TRADE_STATUS } from "@/constants/constants";
import FilterButton from "@/components/Buttons/FilterButton";
import SearchInput from "@/components/SearchInput.vue";
import { fallback } from "@/utils/helpers";

const ACCREDITATION_STATUS = {
  'pending': 'На рассмотрении',
  null: 'Без аккредитации',
  'rejected': 'Отклонена',
  'approved': 'Аккредитована',
}

const ACCREDITATION_COLOR = {
  'pending': 'black',
  null: 'black',
  'rejected': 'red',
  'approved': 'green',
}



export default {
  name: "OrganizationsListTab",

  components: {
    SearchInput,
    FilterButton,
    OrganizationsFiltersDialog,
    OrganizationDialog
  },

  data() {
    return {
      ROLES,
      TRADE_STATUS,
      TRADE_COLOR,
      ACCREDITATION_STATUS,
      ACCREDITATION_COLOR,

      loading: false,
      search: null,
      items: [],
      itemsPerPage: 10,
      serverItemsLength: 0,
      page: 1,
      filters: null,

      organizationsFiltersDialog: false,

      isOpenOrganization: false,
      openedOrganizationId: '',

      organizationStatuses: {
        ACTIVE: 'Действующая',
        LIQUIDATING: 'Ликвидируется',
      },

      headers: [
        {
          text: 'Наименование организации',
          sortable: false,
          value: 'name',
          align: 'start'
        },
        {
          text: 'Контактное лицо',
          sortable: false,
          value: 'contact',
          align: 'start'
        },
        {
          text: 'Роль',
          sortable: false,
          value: 'role',
          align: 'start'
        },
        {
          text: 'Статус аккредитации',
          sortable: false,
          value: 'status',
          align: 'start'
        },
        {
          text: 'Допуск к торгам',
          sortable: false,
          value: 'allow',
          align: 'start'
        },
        {
          text: 'Действия',
          sortable: false,
          value: 'actions',
          align: 'end'
        },
        {
          text: 'ОПФ',
          sortable: false,
          value: 'opf-type',
          align: ' d-none'
        },
        {
          text: 'E-mail',
          sortable: false,
          value: 'email',
          align: ' d-none'
        },
      ]
    }
  },

  created () {
    this.loadOrganizations();
  },

  methods: {
    fallback,
    async loadOrganizations () {
      try {
        this.loading = true;

        this.items = [];

        const { items, total } = await getOrganizationsList({
          page: this.page,
          limit: this.itemsPerPage,
          search: this.search,
          ...this.filters
        });

        const pendingItems = items.filter(i => i.trade_admission_status === 'pending');
        const noPendingItems = items.filter(i => i.trade_admission_status !== 'pending');

        this.items = [...pendingItems, ...noPendingItems];
        this.serverItemsLength = total;

      } finally {
        this.loading = false;
      }
    },

    async applyFilters (filters) {
      this.organizationsFiltersDialog = false;

      try {
        this.loading = true;

        this.filters = filters;

        this.page = 1;
        const { items, total } = await getOrganizationsList({
          page: this.page,
          limit: this.itemsPerPage,
          search: this.search,
          ...this.filters
        });

        const pendingItems = items.filter(i => i.trade_admission_status === 'pending');
        const noPendingItems = items.filter(i => i.trade_admission_status !== 'pending');

        this.items = [...pendingItems, ...noPendingItems];
        this.serverItemsLength = total;

      } finally {
        this.loading = false;
      }
    },

    onChangeItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.loadOrganizations();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },

    onChangePage(page) {
      this.page = page;
      this.loadOrganizations();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },

    showOrganizationDialog(id) {
      this.openedOrganizationId = id;
      this.isOpenOrganization = true;
    },

    closeDialog (reloading = false) {
      this.isOpenOrganization = false;

      if (reloading) {
        this.loadOrganizations();
      }
    },

    rowClass (item) {
      return !item.owner_user_id ? 'grey lighten-4' : '';
    },
  },
}
</script>

<style lang="scss">
.organizations-list {
  &__filters {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
}

.opfType, .email {
  font-size: 12px;
  color: #8F8F8F;
}
</style>
