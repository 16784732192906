<template>
  <div>
    <v-divider />

    <div style="height: 1rem;"></div>

    <h2>Общая ставка</h2>

    <div style="height: 1rem;"></div>

    <validation-observer ref="observer" v-slot="{ invalid }">
      <v-card elevation="0" style="border: 1px solid rgba(0, 0, 0, 0.12);" max-width="300">
        <v-card-title>
          <span>Комиссия:
            <v-progress-circular v-if="form.loading" indeterminate size="20"></v-progress-circular>
            <span v-else style="font-weight: 800;">{{ form.value.toFixed(1) }}%</span>
          </span>
          <v-spacer/>
          <v-tooltip bottom>
            <template #activator="{ on, bind }">
              <v-icon v-on="on" v-bind="bind">mdi-information</v-icon>
            </template>
            <div style="max-width: 280px;">Это общая ставка комиссии, которая применяется к тем поставщикам, которые не находятся в группах</div>
          </v-tooltip>
        </v-card-title>
        <v-card-text>
          <div style="height: 0.5rem;"/>

          <div style="display: flex; align-items: center;">
            <validation-provider v-slot="{ errors }" vid="value" name="Процент комиссии" rules="double|min_value:0">
              <v-text-field
                  v-model.number="form.newValue"
                  label="Новое значение"
                  :placeholder="form.value.toFixed(1)"
                  hide-details
                  :disabled="form.loading || form.submitting"
                  :error-messages="errors"
                  class="pt-0"
              />
            </validation-provider>
            %
            <v-btn outlined class="ml-3" :disabled="invalid || form.loading || !Number.isFinite(form.newValue) || form.value === form.newValue" :loading="form.submitting" @click="onApplyNewCommissionClick">Применить</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </validation-observer>

    <div style="height: 1rem;"></div>

    <v-divider />

    <div style="height: 1rem;"></div>

    <h2>Группы поставщиков</h2>

    <div style="display: flex; justify-content: end;">
      <v-btn elevation="0" color="primary" @click="onCreateGroupClick"><v-icon>mdi-plus</v-icon> Новая группа</v-btn>
    </div>

    <div style="height: 1rem;"></div>

    <div v-if="table.loading"><v-progress-circular indeterminate color="primary"/></div>
    <div v-else-if="table.items.length === 0" style="opacity: 0.75;">Список групп пуст</div>
    <v-expansion-panels v-else>
      <v-expansion-panel
        v-for="item in table.items"
        v-bind:key="item.id"
      >
        <v-expansion-panel-header>
          <div>
            <div class="mt-1 mb-1">{{ item.name }}</div>
            <div class="text-caption" style="opacity: 0.75;">Комиссия: {{ item.commission.toFixed(1) }}%</div>
          </div>
          <div style="display: flex; justify-content: end; margin-right: 1rem;">
            <v-tooltip top>
              <template #activator="{ on, bind }">
                <v-btn icon v-on="on" v-bind="bind" style="margin-right: 0.75rem;" @click.stop="addSupplierToGroup(item.id)"><v-icon>mdi-briefcase-plus</v-icon></v-btn>
              </template>
              Добавить организацию
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on, bind }">
                <v-btn icon v-on="on" v-bind="bind" style="margin-right: 0.75rem;" @click.stop="editSupplierGroup(item.id)"><v-icon>mdi-pencil</v-icon></v-btn>
              </template>
              Редактировать группу
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on, bind }">
                <div v-on="on" v-bind="bind">
                  <v-btn icon style="margin-right: 0.0rem;" @click.stop="deleteSupplierGroup(item.id)" :disabled="item.organizations.length > 0"><v-icon color="error">mdi-delete</v-icon></v-btn>
                </div>
              </template>
              <div v-if="item.organizations.length > 0" style="text-align: center;">Нельзя удалить пока в группе есть организации</div>
              <span v-else>Удалить группу</span>
            </v-tooltip>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table>
            <tbody>
<!--            <tr v-if="item.organizations === undefined">
              <td colspan="2"><v-progress-circular indeterminate color="primary"/></td>
            </tr>-->
            <tr v-if="item.organizations.length === 0">
              <td colspan="2"><span style="opacity: 0.75;">В этой группе ещё нет организаций<br>Нажмите на кнопку <v-icon small style="margin-left: 1px; margin-bottom: 3px; margin-right: 2px;">mdi-briefcase-plus</v-icon> для добавления</span></td>
            </tr>
            <tr v-else v-for="organization in item.organizations" :key="organization.inn">
              <td>
                <v-tooltip top>
                  <template #activator="{ on, bind }">
                    <v-btn icon v-on="on" v-bind="bind" @click.stop="removeSupplierFromGroup(organization.inn, item.id)"><v-icon color="error">mdi-close</v-icon></v-btn>
                  </template>
                  Удалить из группы
                </v-tooltip>
              </td>
              <td style="width: 100%;">
                {{ organization.name }}
                <div class="text-caption" style="opacity: 0.75;">ИНН {{ organization.inn }}</div>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <supplier-group-dialog
        ref="supplierGroupDialog"
        @refresh="loadSupplierGroups"
    />

    <add-supplier-to-group-dialog
        ref="addSupplierToGroupDialog"
        @refresh="loadSupplierGroups"
    />
  </div>
</template>

<script>
import SupplierGroupDialog from "@/components/Finance/Plans/SupplierGroupDialog.vue";
import AddSupplierToGroupDialog from "@/components/Finance/Plans/AddSupplierToGroupDialog.vue";
import {ValidationObserver, ValidationProvider} from "vee-validate";

import { callAPI } from "@/services/api";

export default {

  components: {
    AddSupplierToGroupDialog,
    SupplierGroupDialog,
    ValidationObserver,
    ValidationProvider
  },

  inject: [
    'alert'
  ],

  data() {
    return {
      form: {
        value: 0,
        newValue: '',
        loading: false,
        submitting: false
      },
      table: {
        loading: false,
        page: 1,
        limit: 10,
        total: 0,
        items: []
      }
    }
  },

  mounted() {
    this.loadCommission();
    this.loadSupplierGroups();
  },

  methods: {
    async loadCommission() {
      this.form.loading = true;
      try {
        const commission = await callAPI('POST', '/api/operator/getSetting', { key: 'supplier_commission' });
        this.form.value = Number(commission);
      } finally {
        this.form.loading = false;
      }
    },

    async loadSupplierGroups() {
      this.table.loading = true;
      try {
        const { total, items } = await callAPI('POST', '/api/operator/listSupplierGroups', {
          page: this.table.page,
          limit: this.table.limit
        })
        this.table.total = total;
        this.table.items = items;
      } finally {
        this.table.loading = false;
      }
    },

    deleteSupplierGroup(id) {
      this.$root.$confirm({
        text: 'Вы уверены, что хотите удалить выбранную группу организаций?'
      }).then(async (callback) => {
        try {
          await callAPI('POST', '/api/operator/deleteSupplierGroup', { id: id });
          this.alert({ dialogType: 'success', text: 'Группа поставщиков успешно удалена' });
          callback();
          await this.loadSupplierGroups();
        } catch (error) {
          this.alert({ dialogType: 'error', text: error });
        }
      });
    },

    editSupplierGroup(supplierGroupId) {
      this.$refs.supplierGroupDialog.show('edit', supplierGroupId);
    },

    onCreateGroupClick() {
      this.$refs.supplierGroupDialog.show('create');
    },

    addSupplierToGroup(supplierGroupId) {
      this.$refs.addSupplierToGroupDialog.show(supplierGroupId);
    },

    removeSupplierFromGroup(supplierInn, supplierGroupId) {
      this.$root.$confirm({
        text: 'Вы уверены, что хотите удалить выбранную организацию из группы?'
      }).then(async (callback) => {
        await callAPI('POST', '/api/operator/removeSupplierFromGroup', {
          group_id: supplierGroupId,
          organization_inn: supplierInn,
        });
        this.alert({ dialogType: 'success', text: 'Организация успешно исключена из группы поставщиков' });
        callback();
        await this.loadSupplierGroups();
      });
    },

    onApplyNewCommissionClick() {
      this.$root.$confirm({
        text: `Вы уверены, что хотите изменить значение комиссии с <b>${this.form.value.toFixed(1)}%</b> на <b>${this.form.newValue.toFixed(1)}%</b>?`
      }).then(async (callback) => {
        await callAPI('POST', '/api/operator/setSettings', { supplier_commission: this.form.newValue.toFixed(1) });
        this.alert({ dialogType: 'success', text: 'Новая комиссия успешно установлена' });
        callback();
        await this.loadCommission();
      });
    }
  }
}
</script>

<style>
</style>
