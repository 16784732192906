import { render, staticRenderFns } from "./AuthorizationLayout.vue?vue&type=template&id=790e9335&scoped=true&"
import script from "./AuthorizationLayout.vue?vue&type=script&lang=js&"
export * from "./AuthorizationLayout.vue?vue&type=script&lang=js&"
import style0 from "./AuthorizationLayout.vue?vue&type=style&index=0&id=790e9335&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "790e9335",
  null
  
)

export default component.exports