<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.833452 1.74C2.18012 3.46666 4.66679 6.66666 4.66679 6.66666V10.6667C4.66679 11.0333 4.96678 11.3333 5.33345 11.3333H6.66679C7.03345 11.3333 7.33345 11.0333 7.33345 10.6667V6.66666C7.33345 6.66666 9.81345 3.46666 11.1601 1.74C11.5001 1.3 11.1868 0.666664 10.6335 0.666664H1.36012C0.806785 0.666664 0.493452 1.3 0.833452 1.74Z"
      fill="#8C8C8C"
    />
  </svg>
</template>

<script>
export default {
  name: "FilterIcon"
}
</script>

<style scoped>

</style>