<template>
  <v-container>
    <h1>Список операторов</h1>
    <div>Управление учетными записями операторов</div>

    <div class="d-flex justify-end">
      <v-btn
        outlined
        color="primary"
        class="mb-5"
        @click="showCreateOperatorDialog()"
      >
        <v-icon class="mr-2">
          mdi-plus
        </v-icon>
        Добавить нового оператора
      </v-btn>
    </div>

    <div style="position: relative;">
      <v-data-table
          :headers="table.headers"
          :items="table.items"
          :page.sync="table.page"
          :items-per-page.sync="table.limit"
          :server-items-length="table.total"
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [ 10, 30, 50, 100 ] }"
          @update:page="fetchTable()"
          @update:items-per-page="fetchTable()"
          style="border: 1px solid rgba(0, 0, 0, 0.12)"
          :item-class="(item) => item.deactivated_at !== null ? 'grey lighten-4' : ''"
      >
        <template #[`item.id`]="{ item }">
          <v-chip small>{{ item.id }}</v-chip>
        </template>
        <template #[`item.name`]="{ item }">
          <div v-if="item.deactivated_at === null">{{ getFullName(item) }}</div>
          <div v-else style="opacity: 0.5;">{{ getFullName(item) }} (Деактивирован)</div>
        </template>
        <template #[`item.actions`]="{ item }">
          <div class="d-flex justify-end" style="white-space: nowrap;">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-btn @click="onClickChangePassword(item)" icon class="mr-2">
                    <v-icon>mdi-pencil-lock-outline</v-icon>
                  </v-btn>
                </div>
              </template>
              <div>Изменить пароль</div>
            </v-tooltip>
            <v-tooltip top v-if="item.deactivated_at === null">
              <template #activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-btn @click="onClickDelete(item)" icon color="error">
                    <v-icon>mdi-account-off-outline</v-icon>
                  </v-btn>
                </div>
              </template>
              <div>Деактивировать</div>
            </v-tooltip>
            <v-tooltip top v-else>
              <template #activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-btn @click="onClickRestore(item)" icon color="success">
                    <v-icon>mdi-account-check-outline</v-icon>
                  </v-btn>
                </div>
              </template>
              <div>Активировать</div>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
      <v-fade-transition>
        <v-overlay v-if="table.items.length > 0 && table.loading" absolute color="white"/>
      </v-fade-transition>
    </div>

    <v-dialog v-model="createOperatorDialog.shown" max-width="600" :persistent="createOperatorDialog.submitting">
      <v-card>
        <v-card-title>Добавление оператора</v-card-title>
        <v-card-text>
          <v-form @submit="submitCreateOperatorDialogForm()" :disabled="createOperatorDialog.submitting">
            <v-row>
              <v-col><v-text-field v-model="createOperatorDialog.nameValue" outlined dense hide-details label="ФИО" placeholder="Укажите ФИО оператора"/></v-col>
            </v-row>
            <v-row>
              <v-col><v-text-field v-model="createOperatorDialog.emailValue" outlined dense hide-details label="E-mail" placeholder="Укажите E-mail для логина"/></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-model="createOperatorDialog.passwordValue" outlined dense hide-details label="Пароль" placeholder="Придумайте надежный пароль">
                  <template #append-outer>
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <v-btn @click="createOperatorDialog.passwordValue = generateRandomPassword()" v-on="on" v-bind="attrs" icon style="margin-top: -6px;">
                          <v-icon>mdi-dice-multiple-outline</v-icon>
                        </v-btn>
                      </template>
                      <div>Сгенерировать пароль</div>
                    </v-tooltip>
                  </template>
                </v-text-field>
                <div class="text-caption" style="opacity: 0.5;">Запомните или запишите пароль, в дальнейшем не будет возможности посмотреть его</div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col><v-btn elevation="0" block outlined large @click="hideCreateOperatorDialog()" :disabled="createOperatorDialog.submitting">Отмена</v-btn></v-col>
            <v-col><v-btn elevation="0" block large @click="submitCreateOperatorDialogForm()" color="primary" :loading="createOperatorDialog.submitting" :disabled="createOperatorDialog.nameValue === '' || createOperatorDialog.passwordValue === ''">Создать оператора</v-btn></v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { callAPI } from "@/services/api";

export default {

  inject: [
      'alert'
  ],

  data() {
    return {
      operatorDialogMode: 'new',
      operatorDialog: false,
      deleteDialog: false,
      table: {
        loading: false,
        items: [],
        page: 1,
        limit: 10,
        total: 0,
        headers: [
          {
            text: 'ID',
            sortable: false,
            value: 'id',
            align: 'center',
            width: 80
          },
          {
            text: 'ФИО',
            sortable: false,
            value: 'name',
            align: 'start'
          },
          {
            text: 'E-mail',
            sortable: false,
            value: 'email',
            align: 'start'
          },
          {
            text: 'Действия',
            align: 'end',
            sortable: false,
            value: 'actions'
          }
        ]
      },
      createOperatorDialog: {
        shown: false,
        submitting: false,
        nameValue: '',
        emailValue: '',
        passwordValue: ''
      }
    }
  },

  mounted() {
    this.fetchTable();
  },

  methods: {
    async fetchTable() {
      this.table.loading = true;
      const result = await callAPI('POST', '/api/operator/listOperators', { page: this.table.page, limit: this.table.limit });
      this.table.total = result.total;
      this.table.items = result.items;
      this.table.loading = false;
    },

    getFullName(item) {
      return `${item.last_name} ${item.name} ${item.middle_name ?? ''}`.trimEnd()
    },

    onClickDelete(item) {
      this.$root.$confirm({
        title: 'Деактивировать аккаунт?',
        text: 'Оператор потеряет доступ к панели администрирования',
        ok: 'Деактивировать',
        okColor: 'error'
      }).then(async (callback) => {
        try {
          await callAPI('POST', '/api/operator/banUser', { id: item.id });
          this.fetchTable();
        } catch (error) {
          this.alert({ dialogType: 'error', text: error });
        } finally {
          callback();
        }
      });
    },

    onClickRestore(item) {
      this.$root.$confirm({
        title: 'Восстановить аккаунт?',
        text: 'Оператор вновь получит доступ к панели администрирования',
        ok: 'Активировать',
        okColor: 'success'
      }).then(async (callback) => {
        try {
          await callAPI('POST', '/api/operator/unbanUser', { id: item.id });
          this.fetchTable();
        } catch (error) {
          this.alert({ dialogType: 'error', text: error });
        } finally {
          callback();
        }
      });
    },

    onClickChangePassword(item) {
      this.$root.$prompt({
        title: 'Изменить пароль',
        text: 'Укажите новый пароль для доступа в панель администрирования',
        label: 'Пароль',
        value: '',
        ok: 'Сохранить'
      }).then(async ({ value, callback }) => {
        try {
          await callAPI('POST', '/api/operator/changeUserPassword', { user_id: item.id, password: value });
          this.fetchTable();
        } catch (error) {
          this.alert({ dialogType: 'error', text: error });
        } finally {
          callback();
        }
      });
    },

    generateRandomPassword(length = 16, characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789') {
      let result = [];
      for (let i = 0; i < length; i++) {
        result.push(characters[Math.floor(characters.length * Math.random())]);
      }
      return result.join('');
    },

    showCreateOperatorDialog() {
      this.createOperatorDialog.shown = true;
    },

    hideCreateOperatorDialog() {
      this.createOperatorDialog.shown = false;
    },

    async submitCreateOperatorDialogForm() {
      this.createOperatorDialog.submitting = true;
      try {
        const name = this.createOperatorDialog.nameValue.split(' ');
        await callAPI('POST', '/api/operator/createOperator', {
          email: this.createOperatorDialog.emailValue,
          password: this.createOperatorDialog.passwordValue,
          last_name: name[0],
          name: name[1],
          middle_name: name[2]
        });
        this.hideCreateOperatorDialog();
        this.fetchTable();
      } catch (error) {
        this.alert({ dialogType: 'error', text: error });
      } finally {
        this.createOperatorDialog.submitting = false;
      }
    },
  }
}
</script>

<style scoped>

</style>
