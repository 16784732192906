<template>
  <v-dialog
    v-model="show"
    width="820"
    scrollable
  >
    <v-card>
      <v-card-title class="d-flex">
        <span class="text-h5">
          Просмотр счета на оплату
        </span>
        <v-spacer />
        <v-btn
          icon
          @click="show = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="my-0 dialog-body">
        <v-container>
          <form-row label="Номер счета">
            123456
          </form-row>
          <form-row label="Номер процедуры">
            <v-btn
              color="primary"
              outlined
            >
              122345 <v-icon class="ml-2">
                mdi-vector-link
              </v-icon>
            </v-btn>
          </form-row>
          <form-row label="Организация-плательщик">
            <div class="mb-4">
              ООО "Сбербанк"
            </div>
            <v-btn
              color="primary"
              outlined
            >
              Посмотреть организацию <v-icon class="ml-2">
                mdi-briefcase
              </v-icon>
            </v-btn>
          </form-row>
          <form-row label="Статус">
            <v-chip
              color="info"
            >
              Ожидает оплаты
            </v-chip>
          </form-row>
          <form-row label="Дата выставления счета">
            08.11.2022 15:10:20
          </form-row>
          <!--          <form-row label="Дата поступления оплаты">-->
          <!--            08.11.2022 16:10:20-->
          <!--          </form-row>-->
          <!--          <form-row label="Счет был оплачен в течение">-->
          <!--            1 час 10 минут-->
          <!--          </form-row>-->
          <form-row label="Прошло времени с момента выставления счета">
            1 час 10 минут
          </form-row>
          <form-row label="Документ">
            <v-btn
              color="primary"
              outlined
            >
              Скачать .pdf <v-icon class="ml-2">
                mdi-download
              </v-icon>
            </v-btn>
          </form-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col>
              <v-btn
                elevation="0"
                block
                color="success"
              >
                Подтвердить оплату счета
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                elevation="0"
                block
                color="error"
              >
                Ограничить доступ к торгам
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                block
                color="error"
                outlined
              >
                Отменить счет
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormRow from "@/components/FormRow";

export default {
  name: "InvoiceDialog",
  components: {
    FormRow
  },
  props: {
    value: Boolean,
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tab: ''
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped>

</style>