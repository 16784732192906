<template>
  <div>
    <FilterButton
      class="mb-5"
      disabled
      @click="invoiceFiltersDialog = true"
    />

    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1"
    >
      <template #[`item.status`]="{ item }">
        <v-chip
          v-if="item.status === 'Оплачен'"
          color="success"
        >
          {{ item.status }}
        </v-chip>
        <v-chip
          v-else-if="item.status === 'Создан'"
          color="info"
        >
          {{ item.status }}
        </v-chip>
        <v-chip
          v-else-if="item.status === 'Отклонен'"
          color="error"
        >
          {{ item.status }}
        </v-chip>
      </template>

      <template #[`item.hours`]="{ item }">
        <v-chip :color="getHoursColor(item.hours)">
          {{ item.hours }}
        </v-chip>
      </template>

      <template #[`item.procedure`]>
        <v-btn
          color="primary"
          outlined
        >
          123456
        </v-btn>
      </template>

      <template #[`item.actions`]>
        <div class="d-flex justify-end">
          <v-btn
            text
            color=""
            @click="invoiceDialog = true"
          >
            Посмотреть счет
            <v-icon class="ml-2">
              mdi-arrow-right
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <invoice-dialog v-model="invoiceDialog" />
  </div>
</template>

<script>
import FilterButton from "@/components/Buttons/FilterButton";
import InvoiceDialog from "@/components/Finance/Invoices/InvoiceDialog";

export default {
  name: "InvoicesTab",
  components: {
    FilterButton,
    InvoiceDialog
  },
  data() {
    return {
      headers: [
        {
          text: 'Номер счета',
          sortable: false,
          value: 'id',
          align: 'start'
        },
        {
          text: 'Номер процедуры',
          sortable: false,
          value: 'procedure',
          align: 'start'
        },
        {
          text: 'Статус',
          sortable: false,
          value: 'status',
          align: 'start'
        },
        {
          text: 'Часов прошло',
          sortable: false,
          value: 'hours',
          align: 'start'
        },
        {
          text: 'Действия',
          sortable: false,
          value: 'actions',
          align: 'end'
        },
      ],
      items: [
        {
          id: 123456,
          procedure: 123456,
          hours: 20,
          status: "Оплачен",
        },
        {
          id: 123456,
          procedure: 123456,
          hours: 30,
          status: "Создан",
        },

        {
          id: 123456,
          procedure: 123456,
          hours: 50,
          status: "Отклонен",
        },

      ],
      invoiceFiltersDialog: false,
      invoiceDialog: false
    }
  },
  methods: {
    getHoursColor(hours) {
      switch (true) {
        case hours < 24: return 'success'
        case hours >= 24 && hours < 48: return 'warning'
        case hours >=  48: return 'error'
      }
    }
  }
}
</script>

<style scoped>

</style>
