<template>
  <section>
    <v-row dense>
      <v-col
        cols="12"
        lg="4"
      >
        <search-input
          v-model="search"
          label="Поиск по наименованию и производителю"
          placeholder="Поиск по наименованию и производителю"
          @search="applySearch"
        />
      </v-col>
    </v-row>

    <div style="height: 1rem;" />

    <v-row dense>
      <v-col cols="auto">
        <filter-button
          :applied="filterApplied"
          @click="itemsFiltersDialog = true"
          @clear="resetFilter"
        />
      </v-col>
      <v-col />
      <v-col cols="auto">
        <v-btn
          elevation="0"
          outlined
          color="primary"
          @click="showItemDialogInNewMode"
        >
          <v-icon>mdi-plus</v-icon>
          Добавить товар
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn
          class="ml-2"
          elevation="0"
          outlined
          color="primary"
          @click="showItemZipUploadDialog"
        >
          <v-icon>mdi-upload</v-icon>
          Загрузить из ZIP-архива
        </v-btn>
      </v-col>
    </v-row>

    <div style="height: 1.5rem;" />

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="itemsPerPage"
      :loading="loading"
      :item-class="itemRowBackground"
      :server-items-length="serverItemsLength"
      loading-text="Товары загружаются..."
      :hide-default-header="loading"
      :hide-default-footer="loading"
      style="border: 1px solid rgba(0, 0, 0, 0.12)"
      :footer-props="{ 'items-per-page-options': [ 10, 30, 50, 100 ], 'items-per-page-text': 'Товаров на странице' }"
      @update:page="updatePage"
      @update:items-per-page="updateItemsPerPage"
    >
      <template #[`item.name`]="{ item }">
        <template v-if="item.name">
          <span
            v-if="item.status === 'archived'"
            class="strike-through"
          >
            {{ item.name }}
          </span>
          <span v-else>{{ item.name }}</span>
        </template>
        <template v-else>
          <span>&mdash;</span>
        </template>
      </template>

      <template #[`item.photo`]="{ item }">
        <template
          v-if="item.image"
        >
          <a
            :href="getProductURL(item.id)"
            target="_blank"
          >
            <v-img
              width="96"
              height="96"
              :src="item.image.sm"
              :srcset="item.image.md + ' 2x'"
            >
              <template #placeholder>
                <div class="d-flex align-center justify-center fill-height">
                  <v-progress-circular
                    color="grey lighten-1"
                    indeterminate
                  />
                </div>
              </template>
            </v-img>
          </a>
        </template>
        <template v-else>
          <div
            style="height: 96px;"
            class="d-flex align-center justify-center fill-height"
          >
            <v-icon>mdi-image-off-outline</v-icon>
          </div>
        </template>
      </template>
      <template #[`item.sku`]="{ item }">
        <v-chip
          v-if="item.sku"
          color="success"
          small
        >
          <div
            class="chip-sku"
            :title="item.sku"
          >
            {{ item.sku }}
          </div>
        </v-chip>
        <span v-else>&mdash;</span>
      </template>
      <template #[`item.manufacturer_name`]="{ item }">
        <b>{{ fallback(item.manufacturer_name) }}</b>
      </template>
      <template #[`item.unit_short_name`]="{ item }">
        <span>{{ fallback(item.unit_short_name) }}</span>
      </template>
      <template #[`item.category_name`]="{ item }">
        <span>{{ fallback(item.category_name) }}</span>
      </template>
      <template #[`item.id`]="{ item }">
        <v-chip
          color="primary"
          small
          style="cursor: pointer"
          @click="copyId(item.id)"
        >
          {{ item.id }}
        </v-chip>
      </template>
      <template #[`item.accreditation_required`]="{ item }">
        <v-chip
          v-if="item.accreditation_required"
          small
          color="error"
        >
          Нужна
        </v-chip>
        <v-chip
          v-else
          small
          color="success"
        >
          Не нужна
        </v-chip>
      </template>
      <template #[`item.actions`]="{ item }">
        <div class="d-flex justify-end">
          <v-btn
            icon
            @click="showItemDialogInEditMode(item.id)"
          >
            <v-icon size="20">
              mdi-pencil
            </v-icon>
          </v-btn>

          <v-tooltip
            v-if="item.status !== 'archived'"
            top
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="showDeactivationDialog(item.id)"
              >
                <v-icon size="20">
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
            <span>Удалить товар</span>
          </v-tooltip>
          <v-tooltip
            v-else
            top
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="onClickRestoreProduct()"
              >
                <v-icon
                  size="20"
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
            </template>
            <span>Восстановить товар</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <product-filters-dialog
      v-model="itemsFiltersDialog"
      @apply-filter="applyFilter"
      @reset-filter="resetFilter"
    />
    <product-dialog
      v-model="itemDialog"
      :item-id="selectedItemId"
      :mode="itemDialogMode"
      @change="listProducts"
    />
    <delete-dialog
      v-model="deactivationDialog"
      @ok="deactivateItem"
    />
    <product-zip-upload-dialog
      v-model="itemZipUploadDialog"
      @done="listProducts"
    />
    <success-dialog
      v-model="successDialog"
      :text="successDialogText"
    />
  </section>
</template>

<script>
import ProductFiltersDialog from "@/components/Catalog/Products/ProductFiltersDialog";
import ProductDialog from "@/components/Catalog/Products/ProductDialog";
import DeleteDialog from "@/components/Dialogs/DeleteDialog";
import { archiveProduct, FRONTEND_BASE_URL, listProducts } from "@/services/api";
import ProductZipUploadDialog from "@/components/Catalog/Products/ProductZipUploadDialog";
import SuccessDialog from "@/components/Dialogs/SuccessDialog";
import FilterButton from "@/components/Buttons/FilterButton";
import SearchInput from "@/components/SearchInput.vue";
import { fallback } from "@/utils/helpers";

export default {
  name: "ItemsTab",
  components: {
    SearchInput,
    FilterButton,
    ProductZipUploadDialog,
    ProductFiltersDialog,
    ProductDialog,
    DeleteDialog,
    SuccessDialog
  },
  data() {
    return {
      filterApplied: false,
      successDialog: false,
      categories: [],
      successDialogText: '',
      loading: false,
      itemZipUploadDialog: false,
      serverItemsLength: 0,
      page: 1,
      itemsPerPage: 10,
      limit: 0,
      images: [],
      selectedItemId: null,
      deactivationDialog: false,
      search: '',
      itemToDeactivate: 0,
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'photo',
        },
        {
          text: 'Название',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Производитель',
          align: 'start',
          sortable: false,
          value: 'manufacturer_name',
        },
        {
          text: 'Категория товара',
          align: 'start',
          sortable: false,
          value: 'category_name',
        },
        {
          text: 'Ед. измерения',
          align: 'center',
          width: '120px',
          sortable: false,
          value: 'unit_short_name',
        },
        {
          text: 'Артикул производителя',
          align: 'center',
          sortable: false,
          value: 'sku',
        },
        {
          text: 'Код товара',
          align: 'center',
          sortable: false,
          value: 'id',
        },
        {
          text: 'Аккредитация',
          align: 'center',
          sortable: false,
          value: 'accreditation_required',
        },
        {
          text: 'Действия',
          align: 'center',
          sortable: false,
          value: 'actions',
        },
      ],
      items: [],
      itemsFiltersDialog: false,
      itemDialog: false,
      itemDialogMode: 'new'
    }
  },

  async beforeMount() {
    await this.listProducts()
  },

  methods: {
    fallback,

    async updateItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage
      this.page = 1
      await this.listProducts()
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },

    async updatePage(page) {
      this.page = page
      await this.listProducts()
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },

    applySearch() {
      this.listProducts({
        page: this.page,
        limit: this.itemsPerPage,
        query: this.search
      })
    },

    showDeactivationDialog(id) {
      this.deactivationDialog = true
      this.itemToDeactivate = id
    },
    async deactivateItem() {
      try {
        this.loading = true
        this.deactivationDialog = false
        await archiveProduct({
          product_id: this.itemToDeactivate
        })
        this.successDialogText = 'Товар был удален из каталога'
        this.successDialog = true
        await this.listProducts()
      } catch (e) {
        this.errorDialog = true
      } finally {
        this.loading = false
      }
    },
    showItemZipUploadDialog() {
      this.itemZipUploadDialog = true
    },
    showItemDialogInEditMode(selectedItemId) {
      this.itemDialog = true
      this.itemDialogMode = "edit"
      this.selectedItemId = selectedItemId
    },

    showItemDialogInNewMode() {
      this.itemDialog = true
      this.itemDialogMode = "new"
      this.selectedItemId = null
    },

    async applyFilter(categories) {
      this.categories = categories;
      this.filterApplied = true
      this.itemsFiltersDialog = false
      await this.listProducts({ page: 1, limit: this.itemsPerPage, categories })
    },

    async listProducts(payload = { page: this.page, limit: this.itemsPerPage }) {
      payload.query = this.search ?? '';

      if (this.categories.length) {
        payload.categories = this.categories
      }

      this.loading = true
      try {
        this.items = [];
        let { items, total } = await listProducts(payload);
        this.items = items;
        this.serverItemsLength = total;
      } finally {
        this.loading = false
      }
    },

    async resetFilter() {
      this.filterApplied = false
      this.categories = [];
      this.itemsFiltersDialog = false
      await this.listProducts()
    },

    copyId(id) {
      navigator.clipboard.writeText(id)

      this.$notify({
        title: 'Код товара скопирован',
        duration: 1500
      })
    },

    itemRowBackground(item) {
      if(item.status === 'archived') {
        return 'grey lighten-4'
      } else {
        return ''
      }
    },

    getProductURL(product_id) {
      return `${FRONTEND_BASE_URL}catalog/products/${product_id}`;
    },

    onClickRestoreProduct() {
      this.$store.dispatch('notify', 'Функционал в разработке');
    }
  }
}
</script>

<style scoped>
.bg-purple {
    background-color: pink!important;
}

.strike-through {
    text-decoration: line-through;
}

.filter-applied {
    border: 1px solid blue;
}

.chip-sku {
    max-width: 94px;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
