<template>
  <validation-observer v-slot="{ invalid }">
    <v-dialog v-model="visible" :persistent="submitting" max-width="500">
      <div style="text-align: center; height: 40px;" v-if="loading">
        <v-progress-circular indeterminate color="white"/>
      </div>

      <v-card v-else>
        <v-card-title>
          <span>{{ title }}</span>
          <v-spacer/>
          <v-btn icon @click="hide()" :disabled="submitting"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-form :disabled="submitting" @submit="submit()">
            <validation-provider v-slot="{ errors }" vid="name" name="Название группы" rules="required|max:300">
              <v-text-field label="Название группы" placeholder="Укажите название группы" outlined dense v-model.trim="group.name" :error-messages="errors"></v-text-field>
            </validation-provider>
            <validation-provider v-slot="{ errors }" vid="commission" name="Процент комиссии" rules="required|double|min:0">
              <v-text-field label="Процент комиссии" placeholder="0.0" outlined dense v-model.number="group.commission" :error-messages="errors"></v-text-field>
            </validation-provider>
          </v-form>
        </v-card-text>
        <v-card-actions class="grey lighten-5">
          <v-row>
            <v-col cols="6">
              <v-btn elevation="0" color="normal" outlined text large block :disabled="submitting" @click="hide()">Отмена</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn elevation="0" color="primary" large block :loading="submitting" @click="submit()" :disabled="invalid">Сохранить</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </validation-observer>
</template>

<script>
import { callAPI } from "@/services/api";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {

  components: {
    ValidationObserver,
    ValidationProvider
  },

  inject: [
    'alert'
  ],

  data() {
    return {
      visible: false,
      loading: false,
      submitting: false,
      type: '',
      group: {
        id: null,
        name: '',
        commission: ''
      }
    }
  },

  computed: {
    title() {
      if (this.type === 'create') {
        return 'Новая группа поставщиков';
      }
      if (this.type === 'edit') {
        return 'Редактирование группы';
      }
      return 'Группа поставщиков';
    }
  },

  methods: {
    show(dialogType, supplierGroupId) {
      this.reset();
      this.visible = true;
      this.type = dialogType;

      if (dialogType === 'edit' && supplierGroupId !== undefined) {
        this.loadSupplierGroup(supplierGroupId);
      }
    },

    async loadSupplierGroup(supplierGroupId) {
      this.loading = true;
      try {
        const group = await callAPI('POST', '/api/operator/getSupplierGroup', { id: supplierGroupId });
        this.group.id = group.id;
        this.group.name = group.name;
        this.group.commission = group.commission;
      } catch (error) {
        this.alert({ dialogType: 'error', text: error });
        this.hide();
      } finally {
        this.loading = false;
      }
    },

    hide() {
      this.visible = false;
    },

    reset() {
      this.group.id = null;
      this.group.name = '';
      this.group.commission = '';
    },

    async submit() {
      this.submitting = true;
      try {
        await callAPI('POST', '/api/operator/saveSupplierGroup', {
          id: this.group.id,
          name: String(this.group.name),
          commission: Number(this.group.commission),
        })
        this.alert({ dialogType: 'success', text: 'Группа успешно сохранена' });
        this.hide();
        this.$emit('refresh');
      } catch (error) {
        this.alert({ dialogType: 'error', text: error });
      } finally {
        this.submitting = false;
      }
    }
  }
}
</script>