<template>
  <div class="box">
    <h2 class="text-center mb-4">
      Восстановление пароля
    </h2>
    <div class="text-center hint">
      Письмо о восстановлении пароля отправлено на e-mail: <span style="color: lightskyblue">examle@gmail.com</span>
    </div>

    <div class="d-flex justify-center mt-5">
      <svg
        width="78"
        height="78"
        viewBox="0 0 78 78"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M38.9985 0.194763C17.6166 0.194763 0.192383 17.619 0.192383 39.0009C0.192383 60.3828 17.6166 77.8071 38.9985 77.8071C60.381 77.8071 77.8047 60.3828 77.8047 39.0009C77.8047 17.619 60.381 0.194763 38.9985 0.194763ZM57.548 30.0757L35.545 52.0787C35.275 52.3492 34.9544 52.5637 34.6014 52.7102C34.2484 52.8566 33.87 52.932 33.4879 52.9321C32.712 52.9321 31.9745 52.6218 31.4314 52.0787L20.4491 41.0964C19.3239 39.9713 19.3239 38.1086 20.4491 36.9828C21.5749 35.8576 23.4375 35.8576 24.5627 36.9828L33.4879 45.9086L53.4344 25.9621C54.5601 24.8369 56.4222 24.8369 57.548 25.9621C58.6732 27.0873 58.6732 28.9116 57.548 30.0757Z"
          fill="#1B7DC9"
        />
      </svg>
    </div>

    <v-btn
      color="primary"
      rounded
      dense
      block
      elevation="0"
      type="submit"
      class="font-weight-bold mt-5 text-uppercase"
      @click="$emit('login')"
    >
      Войти
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "SuccessResetPasswordBox"
}
</script>

<style scoped>
.box {
  background: #FFFFFF;
  padding: 48px 60px;
  width: 100%;
  max-width: 624px;
}
.hint {
  font-size: 14px;
}
</style>