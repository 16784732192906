<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    :hide-default-header="loading"
    :hide-default-footer="loading"
    :options.sync="options"
    :server-items-length="serverItemsLength"
    loading-text="Заявки загружаются..."
    style="border: 1px solid rgba(0, 0, 0, 0.12)"
    :footer-props="{ 'items-per-page-options': [ 10, 30, 50, 100 ], 'items-per-page-text': 'Заявок на странице' }"
    @update:page="listWalletRequests"
    @update:items-per-page="listWalletRequests(options.page)"
  >
    <template #[`item.status`]="{ item }">
      <v-chip
        v-if="item.status === 'completed'"
        small
        color="success"
      >
        Исполнена
      </v-chip>
      <v-chip
        v-else-if="item.status === 'pending'"
        small
        color="info"
      >
        Новая
      </v-chip>
      <v-chip
        v-else-if="item.status === 'rejected'"
        small
        color="error"
      >
        Отклонена
      </v-chip>
      <v-chip
        v-else-if="item.status === 'canceled'"
        small
        color="error"
        outlined
      >
        Аннулирована
      </v-chip>
    </template>

    <template #[`item.fio`]="{ item }">
      {{ getFio(item) }}
    </template>

    <template #[`item.created_at`]="{ item }">
      {{ formatDate(item.created_at) }}
    </template>

    <template #[`item.updated_at`]="{ item }">
      {{ formatDate(item.updated_at) }}
    </template>

    <template #[`item.amount`]="{ item }">
      {{ formatAmount(item.amount) }}
    </template>

    <template #[`item.organization_name`]="{ item }">
      <span style="font-size: 12px;">{{ item.organization_name }}</span>
    </template>

    <template #[`item.actions`]="{ item }">
      <div class="d-flex justify-end">
        <v-btn
          elevation="0"
          text
          color=""
          @click="$emit('show-request', item)"
        >
          Посмотреть заявку
          <v-icon class="ml-2">
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import {listWalletRequests} from "@/services/api";
import {formatDate, formatAmount} from "@/utils/helpers";

export default {
  name: "RequestsTable",

  props: {
    mode: {
      type: String,
      default: ''
    },
    filters: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      loading: false,
      items: [],
      serverItemsLength: 0,

      options: {
        page: 1,
        itemsPerPage: 10,
      },

      headers: [
        {
          text: 'Номер заявки',
          sortable: false,
          value: 'id',
          align: 'start'
        },
        {
          text: 'Дата создания',
          sortable: false,
          value: 'created_at',
          align: 'start'
        },
        {
          text: 'Дата обновления',
          sortable: false,
          value: 'updated_at',
          align: 'start'
        },
        {
          text: 'Организация',
          sortable: false,
          value: 'organization_name',
          align: 'start'
        },
        {
          text: 'Статус',
          sortable: false,
          value: 'status',
          align: 'start'
        },
        {
          text: 'Сумма',
          sortable: false,
          value: 'amount',
          align: 'end'
        },
        {
          text: 'Действия',
          sortable: false,
          value: 'actions',
          align: 'end'
        },
      ],
    }
  },

  async beforeMount() {
    await this.listWalletRequests()
  },

  methods: {
    formatAmount(amount) {
      return formatAmount(amount)
    },

    formatDate(date) {
      if (!date) {
        return 'Не обновлялась'
      }
      return formatDate(date)
    },

    async listWalletRequests(page = null) {
      if (page) this.options.page = page;
      this.loading = true

      this.items.splice(0, this.items.length);

      let {items, total} = await listWalletRequests({
        page: this.options.page,
        limit: this.options.itemsPerPage,
        type: this.mode,
        ...this.filters
      });

      this.items = items;
      this.serverItemsLength = total;

      this.loading = false

    },
    getFio(item) {
      let lastName = item.user_last_name || ''
      let firstName = item.user_first_name || ''
      let middleName = item.user_middle_name || ''

      return `${lastName} ${firstName} ${middleName}`
    }
  }
}
</script>

<style scoped>

</style>
