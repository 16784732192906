<template>
  <div class="fill-height d-flex justify-center align-center flex-column">
    <img
      alt="logo"
      class="mb-10"
      src="@/assets/logo.svg"
    >
    <v-slide-x-transition mode="out-in">
      <LoginBox
        v-if="mode === 'login'"
        @forgot-password="forgotPassword"
      />
      <ResetPasswordBox
        v-else-if="mode === 'resetPassword'"
        @reset-password="resetPassword"
      />
      <SuccessResetPasswordBox
        v-else-if="mode === 'successResetPassword'"
        @login="mode = 'login'"
      />
    </v-slide-x-transition>
    <div class="mt-5">
      Если возникли проблемы, обратитесь на <a href="mailto:support@domen.ru">support@domen.ru</a>
    </div>
    <footer>
      &copy; Общество с ограниченной ответственностью «Атис Трейд» (ООО «Атис Трейд»).
    </footer>
  </div>
</template>

<script>
import LoginBox from "@/components/Authorization/LoginBox";
import ResetPasswordBox from "@/components/Authorization/ResetPasswordBox";
import SuccessResetPasswordBox from "@/components/Authorization/SuccessResetPasswordBox";

export default {
  name: "LoginView",
  components: {
    SuccessResetPasswordBox,
    LoginBox,
    ResetPasswordBox
  },
  data() {
    return {
      mode: 'login'
    }
  },
  methods: {
    forgotPassword() {
      this.mode = 'resetPassword'
    },
    resetPassword() {
      this.mode = 'successResetPassword'
    }
  }

}
</script>

<style scoped>
footer {
  position: absolute;
  bottom: 40px;
  color: #8D929C;
}
</style>