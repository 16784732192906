<template>
  <v-container>
    <h1>Пользователи</h1>
    <div>Список пользователей</div>

    <div style="height: 1rem" />

    <div style="display: flex; justify-content: end">
      <search-input
        v-model="search"
        label="Поиск по ФИО и телефону"
        placeholder="Введите ФИО или номер телефона"
        style="max-width: 400px"
        @search="onSearch()"
      />
    </div>

    <div style="height: 1rem" />

    <v-data-table
      :headers="table.headers"
      :items="table.items"
      loading-text="Пользователи загружаются..."
      style="border: 1px solid rgba(0, 0, 0, 0.12)"
      :loading="table.loading"
      :hide-default-header="table.loading"
      :hide-default-footer="table.loading"
      :server-items-length="table.total"
      :items-per-page.sync="table.limit"
      :page.sync="table.page"
      :footer-props="{
        'items-per-page-options': [10, 30, 50, 100],
        'items-per-page-text': 'Отображать пользователей',
      }"
      @update:page="loadUsersTable"
      @update:items-per-page="loadUsersTable"
    >
      <template #[`item.name`]="{ item }">
        {{
          fallback(formatFullName(item.name, item.last_name, item.middle_name))
        }}<br />
        <span class="text-caption" style="opacity: 0.5">
          {{ item.occupation }}
        </span>
      </template>
      <template #[`item.role`]="{ item }">
        <v-chip :color="getRoleColor(item.role)" small>
          {{ getRoleText(item.role) }}
        </v-chip>
      </template>
      <template #[`item.registered_at`]="{ item }">
        <div style="white-space: nowrap">
          {{ formatDateTimeHumane(item.registered_at) }}
        </div>
      </template>
      <template #[`item.deactivated_at`]="{ item }">
        <v-chip :color="item.deactivated_at ? 'error' : 'success'">
          {{ item.deactivated_at ? "Деактивирован" : "Активен" }}
        </v-chip>
      </template>
      <template #[`item.email`]="{ item }">
        <div style="white-space: nowrap">
          <a
            :href="`mailto://${item.email}`"
            class="mail-link"
            title="Нажмите, чтобы отправить письмо"
          >
            {{ item.email }}
          </a>

          <v-tooltip v-if="item.is_email_verified" top>
            <template #activator="{ on, attrs }">
              <sup v-bind="attrs" v-on="on">
                <v-icon size="14" color="green">
                  mdi-check-decagram-outline
                </v-icon>
              </sup>
            </template>
            <span>E-mail подтвержден</span>
          </v-tooltip>
        </div>
      </template>
      <template #[`item.phone`]="{ item }">
        <div style="white-space: nowrap">
          {{ formatPhone(item.phone) }}
        </div>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon color="black">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item @click="onClickAuth(item.id)">
              <v-list-item-icon class="mr-3">
                <v-icon>mdi-account-arrow-up-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Войти в аккаунт</v-list-item-title>
            </v-list-item>
            <v-list-item @click="onClickDeactivate(item)">
              <v-list-item-icon class="mr-3">
                <v-icon :color="item.deactivated_at ? 'success' : 'error'">{{ item.deactivated_at ? 'mdi-account-check-outline' : 'mdi-account-off-outline' }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.deactivated_at ? "Активировать" : "Деактивировать" }} пользователя</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { callAPI, FRONTEND_BASE_URL } from "@/services/api";
import {
  fallback,
  formatPhone,
  formatFullName,
  formatDateTimeHumane,
  getRoleText,
  getRoleColor,
} from "@/utils/helpers";
import SearchInput from "@/components/SearchInput.vue";

export default {
  components: { SearchInput },
  data() {
    return {
      table: {
        headers: [
          {
            text: "ID",
            sortable: false,
            value: "id",
            align: "start",
          },
          {
            text: "ФИО",
            sortable: false,
            value: "name",
            align: "start",
          },
          {
            text: "Роль",
            sortable: false,
            value: "role",
            align: "start",
          },
          {
            text: "Телефон",
            sortable: false,
            value: "phone",
            align: "start",
          },
          {
            text: "E-mail",
            sortable: false,
            value: "email",
            align: "start",
          },
          {
            text: "Дата регистрации",
            sortable: false,
            value: "registered_at",
            align: "start",
          },
          {
            text: "Статус",
            sortable: false,
            value: "deactivated_at",
            align: "start",
          },
          {
            text: "Действия",
            align: "end",
            sortable: false,
            value: "actions",
          },
        ],
        items: [],
        loading: false,
        total: 0,
        page: 1,
        limit: 10,
      },
      search: "",
    };
  },
  mounted() {
    this.loadUsersTable();
  },
  methods: {
    formatDateTimeHumane,
    fallback,
    formatFullName,
    formatPhone,
    getRoleText,
    getRoleColor,

    onClickDeactivate(user) {

      const user_full_name = `${user.last_name} ${user.name} ${user.middle_name ?? ''}`.trimEnd();

      if (user.deactivated_at !== null) {
        this.$root.$confirm({
          title: 'Активировать пользователя?',
          text: `<div class="mb-2">Вы уверены, что хотите активировать пользователя <b>${user_full_name}</b>?</div>` +
              '<div class="mb-2">Пользователь получит доступ в свой личный кабинет.</div>' +
              '<div>При необходимости вы сможете вновь деактивировать этого пользователя.</div>',
          width: 580,
          ok: "Активировать",
          okColor: "success"
        }).then(async (callback) => {
          try {
            await callAPI("POST", "/api/operator/unbanUser", { id: user.id });
            this.loadUsersTable();
          } catch (error) {
            this.alert({ dialogType: 'error', text: error });
          } finally {
            callback();
          }
        });
      } else {
        this.$root.$confirm({
          title: 'Деактивировать пользователя?',
          text: `<div class="mb-2">Вы уверены, что хотите деактивировать пользователя <b>${user_full_name}</b>?</div>` +
              '<div class="mb-2">Пользователь потеряет доступ в свой личный кабинет.<br>Все данные сохранятся.</div>' +
              '<div>При необходимости вы сможете вновь активировать этого пользователя.</div>',
          width: 580,
          ok: "Деактивировать",
          okColor: "error"
        }).then(async (callback) => {
          try {
            await callAPI("POST", "/api/operator/banUser", { id: user.id });
            this.loadUsersTable();
          } catch (error) {
            this.alert({ dialogType: 'error', text: error });
          } finally {
            callback();
          }
        });
      }
    },

    async loadUsersTable() {
      this.table.loading = true;
      try {
        this.table.items = [];
        const { items, total } = await callAPI("POST", "/api/operator/listUsers", { page: this.table.page, limit: this.table.limit, query: this.search });
        this.table.items = items;
        this.table.total = total;
      } finally {
        this.table.loading = false;
      }
    },

    async onClickAuth(user_id) {
      this.$store.commit("SET_LOADING", true);
      try {
        const auth_token = await callAPI("POST", "/api/operator/authAsUser", {
          user_id: user_id,
        });
        window.open(
          `${FRONTEND_BASE_URL}login?action=set_auth&token=${auth_token}`,
          "_blank"
        );
      } finally {
        this.$store.commit("SET_LOADING", false);
      }
    },

    onSearch() {
      this.table.page = 1;
      this.loadUsersTable();
    },
  },
};
</script>

<style scoped>
.mail-link {
  text-decoration: none;
}
.mail-link:hover {
  text-decoration: underline;
}
</style>
