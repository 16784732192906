<template>
  <v-dialog
    v-model="show"
    width="520"
  >
    <v-card>
      <v-card-title class="d-flex">
        <span class="text-h5">Фильтры</span>
        <v-spacer />
        <v-btn
          icon
          @click="show = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mb-0 dialog-body">
        <v-select
          v-model="selectedStatus"
          :items="statuses"
          item-text="value"
          item-value="id"
          label="Выберите какие статусы отображать"
          placeholder="Выберите какие статусы отображать"
          outlined
          dense
        />

        <date-range-select
          v-model="selectedDateRange"
        />
      </v-card-text>
      <v-card-actions>
        <v-container class="pa-0">
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                outlined
                block
                @click="resetFilter"
              >
                Сбросить
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                type="submit"
                block
                elevation="0"
                @click="applyFilter"
              >
                Применить
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatDate, dateToISO8601, minMaxDate, getTomorrow } from "@/utils/helpers";
import DateRangeSelect from "@/components/DateRangeSelect.vue";

export default {
  components: { DateRangeSelect },

  props: {
    value: Boolean
  },

  data() {
    return {
      statuses: [
        { id: 'completed', value: 'Исполнена' },
        { id: 'pending', value: 'Новая' },
        { id: 'rejected', value: 'Отклонена' }
      ],
      selectedStatus: null,
      selectedDateRange: []
    }
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    applyFilter() {
      this.$emit('apply', {
        status: this.selectedStatus,
        date_from: dateToISO8601(this.selectedDateRange[0]),
        date_to: dateToISO8601(this.selectedDateRange[1])
      });
    },
    resetFilter() {
      this.selectedStatus = null;
      this.selectedDateRange = [];
      this.$emit('reset');
    }
  }
}
</script>


<style scoped>

</style>
