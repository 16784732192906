<template>
  <v-dialog
    v-model="show"
    width="820"
    scrollable
  >
    <v-card>
      <v-card-title class="d-flex">
        <span class="text-h5">
          Просмотр заявки на аккредитацию
        </span>
        <v-spacer />
        <v-btn
          icon
          @click="show = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="my-0 dialog-body">
        <v-container>
          <template v-if="accreditationData.attachments?.length">
            <div class="mb-2 information-section-title">
              Прикрепленные документы
            </div>
            <v-divider class="mb-5" />

            <div
              v-for="(document, index) in accreditationData.attachments"
              :key="`document-${index}`"
              class="document mb-4"
            >
              <a
                :href="document.url"
                target="_blank"
              >
                <v-icon color="primary">
                  mdi-file
                </v-icon> {{ document.original_name }}
              </a>
            </div>
          </template>

          <v-textarea
            v-model="accredidationText"
            class="mt-7"
            outlined
            readonly
            label="Текст заявления"
          />

          <div class="mb-2 information-section-title">
            Основные сведения
          </div>
          <v-divider class="mb-5" />
          <form-row label="Полное наименование организации">
            {{ organization.name }}
          </form-row>
          <form-row label="Организационно-правовая форма">
            {{ ORGANIZATION_TYPE[organization.type] }}
          </form-row>
          <form-row label="ИНН">
            {{ organization.inn }}
          </form-row>
          <form-row label="КПП">
            {{ organization.kpp }}
          </form-row>
          <form-row label="ОГРН">
            {{ organization.ogrn }}
          </form-row>
          <form-row label="Юридический адрес">
            {{ legalAddress }}
          </form-row>
          <form-row label="Почтовый адрес">
            {{ postalAddress }}
          </form-row>
          <form-row label="Роль на площадке">
            {{ ROLES[organization.role] }}
          </form-row>

          <div class="mb-2 mt-10 information-section-title">
            Контактная информация
          </div>
          <v-divider class="mb-5" />
          <form-row label="ФИО">
            {{ organization.contact_last_name }} {{ organization.contact_name }} {{ organization.contact_middle_name }}
          </form-row>
          <form-row label="Служебный e-mail">
            <span class="email">{{ organization.contact_email }}</span>
          </form-row>
          <form-row label="Служебный номер телефона">
            <span>{{ organization.contact_phone }}</span>
          </form-row>

          <template v-if="organization.categories?.length">
            <div class="mb-2 mt-10 information-section-title">
              Сферы деятельности
            </div>

            <v-divider class="mb-5" />

            <form-row
              v-for="(item, index) in organization.categories"
              :key="`category-${index}`"
              :label="`Сфера ${index + 1}`"
            >
              {{ item }}
            </form-row>
          </template>

          <template v-if="organization.delivery_addresses?.length">
            <div class="mb-2 mt-10 information-section-title">
              Адреса доставки
            </div>

            <v-divider class="mb-5" />

            <form-row
              v-for="(address, index) in organization.delivery_addresses"
              :key="`address-${index}`"
              :label="`Адрес ${index + 1}`"
            >
              <span>{{ address.city }}, {{ address.street }} {{ address.house }}</span>
            </form-row>
          </template>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container class="pa-0">
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="reason"
                class="mt-7"
                outlined
                rows="2"
                label="Причина"
              />
            </v-col>

            <v-col cols="6">
              <v-btn
                block
                elevation="0"
                color="success"
                :disabled="accreditationData.status === 'approved'"
                @click="openAcceptSubmit"
              >
                Аккредитовать
              </v-btn>
            </v-col>

            <v-col cols="6">
              <v-btn
                block
                elevation="0"
                color="error"
                :disabled="accreditationData.status === 'rejected'"
                @click="openRejectSubmit"
              >
                Отклонить
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>

    <v-dialog
      v-model="isShowSubmitDialog"
      width="500"
    >
      <v-card>
        <v-card-text>
          Вы уверены, что хотите {{ submitDialogType === 'accept' ? 'одобрить' : 'отклонить' }} заявление организации на аккредитацию?
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="accept"
          >
            Подтвердить
          </v-btn>
          <v-btn
            color="normal"
            text
            @click="isShowSubmitDialog = false"
          >
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import FormRow from "@/components/FormRow";
import {
  approveAccreditationRequest,
  getAccreditationRequest,
  getOrganization,
  rejectAccreditationRequest
} from "@/services/api";
import { ROLES, ORGANIZATION_TYPE } from "@/constants/constants";

export default {
  name: "AccreditationRequestDialog",

  components: {
    FormRow
  },

  props: {
    value: Boolean,

    orgId: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      ROLES,
      ORGANIZATION_TYPE,

      reason: '',
      submitDialogType: '',
      organization: {},
      accreditationData: {},
      isShowSubmitDialog: false,
      accredidationText: 'Прошу аккредитовать меня (организацию, которую я представляю) на электронной тендерной площадке Atis Trade, размещенной по адресу: http://atistrade.ru, в качестве Заказчика.'
    }
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
        this.$emit('close');
      }
    },

    legalAddress () {
      const address = this.organization.legal_address;
      if (address) {
        return `${address.city ?? ''} ${address.street ?? ''} ${address.house ?? ''}`;
      }

      return '';
    },

    postalAddress () {
      const address = this.organization.postal_address;
      if (address) {
        return `${address.city} ${address.street} ${address.house}`;
      }

      return '';
    },
  },

  watch: {
    orgId: {
      immediate: true,
      handler (id) {
        if (id) {
          this.loadOrganization(id);
        } else {
          this.organization = {};
        }
      }
    }
  },

  methods: {
    async loadOrganization (id) {
      const data = await getOrganization({organization_inn: id});
      const accreditationData = await getAccreditationRequest({organization_inn: id});

      this.organization = data;
      this.accreditationData = accreditationData.items;
      this.accredidationText = accreditationData.items?.text ?? '';
    },

    async applyAccreditation () {
      try {
        await approveAccreditationRequest({
          organization_inn: this.organization.inn
        })

        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Аккредитация выдана',
          duration: 5000
        });
      } catch (error) {
        console.error(error);

        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Не удалось выполнить запрос',
          duration: 5000
        });
      }  finally {
        this.show = false;
      }
    },

    async rejectAccreditation () {
      try {
        await rejectAccreditationRequest({
          organization_inn: this.organization.inn,
          reason: this.reason
        })

        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Аккредитация отклонена',
          duration: 5000
        });
      } catch (error) {
        console.error(error);

        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Не удалось выполнить запрос',
          duration: 5000
        });
      } finally {
        this.show = false;
      }
    },

    openAcceptSubmit () {
      this.isShowSubmitDialog = true;
      this.submitDialogType = 'accept';
    },

    openRejectSubmit () {
      this.isShowSubmitDialog = true;
      this.submitDialogType = 'reject';
    },

    accept () {
      if (this.submitDialogType === 'accept') {
        this.applyAccreditation();
      }
      if (this.submitDialogType === 'reject') {
        this.rejectAccreditation();
      }
    }
  }
}
</script>

<style scoped>

</style>
