<template>
  <v-menu
    ref="menu"
    v-model="dateRangeMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="label"
        item-text="value"
        item-value="id"
        label="Фильтр по дате"
        placeholder="Фильтр по дате"
        append-icon="mdi-calendar"
        readonly
        outlined
        dense
        v-bind="attrs"
        v-on="on"
        @click:append="dateRangeMenu=true"
      />
    </template>
    <v-date-picker
      v-model="dateRange"
      range
      @change="dateRangeMenu=false"
    />
  </v-menu>
</template>

<script>
import { formatDate, getTomorrow, minMaxDate } from "@/utils/helpers";

export default {

  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      dateRangeMenu: false,
      dateRange: []
    }
  },

  computed: {
    label() {
      const [ date_from, date_to ] = minMaxDate(...this.dateRange);

      let result = '';
      if (date_from) {
        result += formatDate(new Date(date_from), false);
      }
      if (date_to && date_from !== date_to) {
        result += ' - '
        result += formatDate(new Date(date_to), false);
      }

      return result;
    },
  },

  watch: {
    dateRange([ from, to ]) {
      if (!from) return; 
      [ from, to ] = minMaxDate(from, to);
      if (to === undefined) {
        to = from;
      }
      this.$emit('input', [ from, getTomorrow(to) ]);
    },
    value: {
      handler(val) {
        if (Array.isArray(val) && !val.length) {
          this.clear();
        }
      },
      deep: true,
    }
  },

  methods: {
   clear() {
     this.dateRange = [];
   }
  }
}
</script>