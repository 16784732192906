<template>
  <v-dialog
    v-model="show"
    width="520"
  >
    <v-card>
      <v-card-title class="d-flex">
        <span class="text-h5">Фильтры</span>
        <v-spacer />
        <v-btn
          icon
          @click="show = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mb-0 dialog-body">
        <v-select
          v-model="selectedStatus"
          :items="ORGANIZATIONS_FILTERS"
          item-text="value"
          item-value="id"
          label="Допуск к торгам"
          outlined
          dense
        />

        <v-select
          v-model="selectedRole"
          :items="ORGANIZATIONS_ROLES"
          item-text="value"
          item-value="id"
          label="Роль"
          outlined
          dense
        />

        <v-checkbox
          v-model="without_owner"
          label="Неприкрепленные к пользователю"
        />
      </v-card-text>
      <v-card-actions>
        <v-container class="pa-0">
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                outlined
                block
                @click="resetFilter"
              >
                Сбросить
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                type="submit"
                block
                elevation="0"
                @click="applyFilter"
              >
                Применить
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ORGANIZATIONS_FILTERS, ORGANIZATIONS_ROLES } from "@/constants/constants";

export default {
  name: "OrganizationsFilterDialog",

  props: {
    value: Boolean
  },

  data() {
    return {
      ORGANIZATIONS_FILTERS,
      ORGANIZATIONS_ROLES,

      selectedStatus: null,
      without_owner: false,
      selectedRole: null
    }
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    applyFilter() {
      const filters = {};

      if (this.selectedStatus) {
        filters.trade_admission_status = this.selectedStatus;
      }
      if (this.selectedRole) {
        filters.role = this.selectedRole;
      }
      if (this.without_owner === true) {
        filters.with_owner = false;
      }

      this.$emit('apply', filters)
    },

    resetFilter() {
      this.selectedStatus = null;
      this.without_owner = false;
      this.selectedRole = false;

      this.$emit('apply', null)
    }
  }
}
</script>

<style scoped>

</style>
