<template>
  <v-container>
    <h1 class="atis-page-title">
      Деплои
    </h1>

    <h2 class="atis-page-subtitle">
      Конфигурации
    </h2>

    <div style="display: flex; justify-content: end;">
      <v-btn
        outlined
        color="primary"
      >
        <v-icon style="margin-right: 0.25em;">mdi-plus</v-icon>

        Новая конфигурация
      </v-btn>
    </div>

    <div style="height: 1rem;" />

    <v-simple-table style="border: 1px solid rgba(0, 0, 0, 0.12);">
      <thead>
      <tr>
        <th style="text-align: center;">Название</th>
        <th style="text-align: center;">Окружение</th>
        <th style="text-align: center;">Репозиторий</th>
        <th style="text-align: center;">Дата последнего проведения</th>
        <th style="text-align: center;">Комментарий</th>
        <th style="text-align: center;">Действия</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td style="text-align: center;">test/backend</td>
        <td style="text-align: center;"><v-chip color="primary" small>Тестовое</v-chip></td>
        <td>
          <div>https://gitlab.com/AtisTrade/etp-cms.git</div>
          <div class="text-caption grey--text">master</div>
        </td>
        <td>
          <div>Понедельник, 15 Мая 2023 в 14:38</div>
          <a href="#" class="atis-link-without-underline text-caption grey--text" @click.prevent="">Release note</a>
        </td>
        <td style="text-align: center;">&mdash;</td>
        <td style="text-align: center;">
          <v-tooltip top>
            <template #activator="{ on, bind }">
              <v-btn v-on="on" v-bind="bind" icon><v-icon>mdi-play-outline</v-icon></v-btn>
            </template>
            <span>Начать деплой</span>
          </v-tooltip>
        </td>
      </tr>
      </tbody>
    </v-simple-table>

<!--    <v-dialog :value="true" max-width="480">
      <v-card>
        <v-card-title>Подтвердите действие</v-card-title>
        <v-card-text>Вы уверены, что хотите начать деплой?</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn text color="accent">Подтвердить</v-btn>
          <v-btn text color="normal">Отмена</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->

    <v-dialog :value="true" max-width="480">
      <v-card>
        <v-card-title>Авторизация действия</v-card-title>
        <v-card-text>
          <div>В технический чат отправлен код подтверждения<br>Введите его в поле ниже:</div>
          <br>
          <div style="display: flex; justify-content: center;">
            <v-otp-input style="max-width: 240px;"
                         length="4"
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn elevation="0" block color="primary" large>Продолжить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      :value="false"
      max-width="800"
    >
      <v-card>
        <v-card-title>Конфигурации деплоев</v-card-title>
        <v-card-text>
          <v-simple-table>
            <thead>
            <tr>
              <th style="text-align: center;">Название</th>
              <th style="text-align: center;">Окружение</th>
              <th style="text-align: center;">Режим тех. работ</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td style="text-align: center;">etp/backend</td>
              <td style="text-align: center;">prod</td>
              <td style="text-align: center;"><v-simple-checkbox></v-simple-checkbox></td>
              <td></td>
            </tr>
            </tbody>
          </v-simple-table>

        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style scoped>
.atis-link-without-underline {
    text-decoration: none;
}
.atis-link-without-underline:hover {
    text-decoration: underline;
}
</style>