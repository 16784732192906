<template>
  <div class="box">
    <h2 class="text-center mb-4">
      Восстановление пароля
    </h2>
    <div class="text-center hint">
      Укажите Ваш e-mail, на него будет выслан новый пароль.
    </div>
    <v-form class="mt-10">
      <v-text-field
        outlined
        dense
        rounded
        color="primary"
        placeholder="E-mail"
        label="E-mail"
      />

      <v-btn
        color="primary"
        rounded
        dense
        block
        elevation="0"
        class="font-weight-bold text-uppercase"
        @click="$emit('reset-password')"
      >
        Восстановить пароль
      </v-btn>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "ResetPasswordBox"
}
</script>

<style scoped>
.box {
  background: #FFFFFF;
  padding: 48px 60px;
  width: 100%;
  max-width: 624px;
}

.hint {
  font-size: 14px;
}
</style>