<template>
  <div id="app">
    <LayoutBroker
      :layouts="$options.LAYOUTS"
      :current="$route.meta.layout"
    />

    <notifications />
  </div>
</template>

<script>
import LayoutBroker from '@/components/LayoutBroker'

import DefaultLayout from '@/layouts/DefaultLayout'
import AuthorizationLayout from "@/layouts/AuthorizationLayout";

const LAYOUTS = {
  DefaultLayout,
  AuthorizationLayout
}

export default {

  name: 'App',

  components: {LayoutBroker},

  beforeCreate() {
    this.$store.dispatch('init')
  },

  LAYOUTS,
}

</script>

<style lang="scss">
.vue-notification {
  padding: 12px 16px;
  margin: 0 5px 5px;

  font-size: 16px !important;
  font-family: 'Formular', sans-serif;

  color: #ffffff;
  background: #44A4FC;
  border-left: 5px solid #187FE7;

  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }

  &.error {
    background: #E54D42;
    border-left-color: #B82E24;
  }

  &.success {
    background: #68CD86;
    border-left-color: #42A85F;
  }
}
</style>
