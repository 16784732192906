<template>
  <validation-observer ref="observer">
    <v-dialog
      v-model="visible"
      max-width="640"
      :persistent="submitting"
    >
      <div style="text-align: center; height: 40px;" v-if="loading">
        <v-progress-circular indeterminate color="white"/>
      </div>

      <v-card v-else>
        <v-card-title>
            Тарифный план
          <v-spacer />
          <v-btn icon @click="hide()"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-form :disabled="submitting">

            <form-row v-if="plan.original_name" label="Название">{{ plan.original_name }}</form-row>

            <v-row v-if="type === 'edit'" class="mb-5">
              <v-col cols="6"></v-col>
              <v-col cols="3">
                <v-btn v-if="plan.archived_at === null" color="warning" elevation="0" block @click="archivePlan(plan.key)"><v-icon class="mr-2">mdi-archive-outline</v-icon> В архив</v-btn>
                <v-btn v-else color="warning" elevation="0" block @click="restorePlan(plan.key)"><v-icon class="mr-2">mdi-archive-outline</v-icon> Вернуть</v-btn>
              </v-col>
              <v-col cols="3">
                <v-tooltip bottom :disabled="plan.active_organization_count <= 0">
                  <template #activator="{ on, attrs }">
                    <div v-on="on" v-bind="attrs">
                      <v-btn color="error" elevation="0" block @click="deletePlan(plan.key)" :disabled="plan.active_organization_count > 0"><v-icon class="mr-2">mdi-delete-outline</v-icon> Удалить</v-btn>
                    </div>
                  </template>
                  <div style="text-align: center;">Нельзя удалить тариф, пока к нему<br>подключена хотя бы одна организация</div>
                </v-tooltip>
              </v-col>
            </v-row>

            <h4 class="mb-2">Основное</h4>

            <validation-provider
                v-slot="{ errors }"
                vid="name"
                name="Название тарифного плана"
                rules="required|max:120"
            >
              <v-text-field
                v-model="plan.name"
                outlined
                dense
                label="Название тарифного плана"
                placeholder="Укажите название тарифного плана"
                :error-messages="errors"
              />
            </validation-provider>

            <validation-provider
                v-slot="{ errors }"
                vid="description"
                name="Описание"
                rules="max:8000"
            >
              <atis-rich-text-editor
                  v-model="plan.description"
                  label="Описание"
                  hide-details
                  :error-messages="errors"
              />
              <div class="text-caption" :class="{ 'error--text': errors.length !== 0 }" style="text-align: right;">{{ plan.description?.length ?? 0 }} / 8000</div>
            </validation-provider>

            <div style="height: 10px;"></div>

            <v-checkbox v-model="plan.is_group" label="Групповой" class="mt-0 pt-0" />

            <h4 class="mb-2">Лимиты</h4>

            <v-row align="start" dense>
              <v-col>
                <validation-provider
                    v-slot="{ errors }"
                    vid="procurement_count"
                    name="Количество закупок"
                    rules="integer"
                >
                  <v-text-field v-model.number="plan.procurement_count" outlined dense :disabled="unlimited_procurement_count" label="Количество закупок" placeholder="Укажите лимит количества закупок" :error-messages="errors"/>
                </validation-provider>
              </v-col>
              <v-col cols="auto">
                <v-checkbox v-model="unlimited_procurement_count" label="Неограниченно" hide-details class="mt-1 ml-3"/>
              </v-col>
            </v-row>

            <v-row align="start" dense>
              <v-col>
                <validation-provider
                    v-slot="{ errors }"
                    vid="procurement_sum"
                    name="Количество закупок"
                    rules="integer"
                >
                  <v-text-field v-model.number="plan.procurement_sum" outlined dense :disabled="unlimited_procurement_sum" label="Сумма закупок" placeholder="Укажите лимит суммы закупок" :error-messages="errors"/>
                </validation-provider>
              </v-col>
              <v-col cols="auto">
                <v-checkbox v-model="unlimited_procurement_sum" label="Неограниченно" hide-details class="mt-1 ml-3"/>
              </v-col>
            </v-row>

            <v-row align="start" dense v-if="plan.is_group">
              <v-col>
                <validation-provider
                    v-slot="{ errors }"
                    vid="max_organization_count"
                    name="Количество организаций"
                    rules="integer"
                >
                  <v-text-field v-model.number="plan.max_organization_count" outlined dense :disabled="unlimited_organization_count" label="Количество организаций" placeholder="Укажите лимит количества организаций в группе" :error-messages="errors"/>
                </validation-provider>
              </v-col>
              <v-col cols="auto">
                <v-checkbox v-model="unlimited_organization_count" label="Неограниченно" hide-details class="mt-1 ml-3"/>
              </v-col>
            </v-row>

            <h4 class="mb-2">Стоимость</h4>
            <v-row align="start" dense>
              <v-col>
                <validation-provider
                    v-slot="{ errors }"
                    vid="price"
                    name="Цена"
                    rules="required|numeric|min_value:0"
                >
                  <v-text-field v-model.number="plan.price" outlined dense label="Стоимость тарифа" placeholder="Укажите стоимость подключения тарифа" :error-messages="errors"/>
                </validation-provider>
              </v-col>
              <v-col cols="auto">
                <v-checkbox v-model="plan_has_discount" label="Скидка" hide-details class="mt-1 ml-3"/>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <validation-provider
                    v-slot="{ errors }"
                    vid="old_price"
                    name="Цена без скидки"
                    rules="numeric|min_value:0"
                >
                  <v-text-field v-if="plan_has_discount" v-model.number="plan.old_price" outlined dense label="Стоимость без скидки" placeholder="Укажите стоимость тарифного плана без скидки" :error-messages="errors"/>
                </validation-provider>
              </v-col>
            </v-row>

            <h4 class="mb-2">Срок действия</h4>
            <v-row align="start" dense>
              <v-col>
                <validation-provider
                    v-slot="{ errors }"
                    vid="duration_days"
                    name="Количество дней"
                    rules="required|min_value:1"
                >
                  <v-text-field v-model.number="plan.duration_days" outlined dense label="Количество дней" placeholder="Укажите срок действия тарифного плана в днях" :error-messages="errors"/>
                </validation-provider>
              </v-col>
            </v-row>
          </v-form>

        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn color="normal" elevation="0" block large text outlined :disabled="submitting" @click="hide()">Отменить</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn color="primary" elevation="0" block large :loading="submitting" @click="savePlan()">Сохранить</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </validation-observer>
</template>

<script>
import { callAPI } from "@/services/api";
import AtisRichTextEditor from "@/components/RichTextEditor.vue";
import FormRow from "@/components/FormRow.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  components: { FormRow, ValidationObserver, ValidationProvider, AtisRichTextEditor },

  data() {
    return {
      type: '',
      visible: false,
      loading: false,
      submitting: false,
      plan_has_discount: false,
      unlimited_procurement_sum: false,
      unlimited_procurement_count: false,
      unlimited_organization_count: false,
      plan: {},
    }
  },

  inject: [ 'alert' ],

  watch: {
    unlimited_procurement_sum(value) { if (value) this.plan.procurement_sum = -1; },
    unlimited_procurement_count(value) { if (value) this.plan.procurement_count = -1; },
    unlimited_organization_count(value) { if (value) this.plan.max_organization_count = -1; }
  },

  // computed: {
  //   unlimited_procurement_count: {
  //     get() {
  //       return this.plan.procurement_count !== '' && this.plan.procurement_count <= 0;
  //     },
  //     set(value) {
  //       return this.plan.procurement_count = value ? null : '';
  //     }
  //   },
  //   unlimited_procurement_sum: {
  //     get() {
  //       return this.plan.procurement_sum !== '' && this.plan.procurement_sum <= 0;
  //     },
  //     set(value) {
  //       return this.plan.procurement_sum = value ? null : '';
  //     }
  //   }
  // },

  methods: {

    show(plan_key) {
      if (plan_key === null) {
        this.type = 'create';
        this.reset();
      } else {
        this.type = 'edit';
        this.loadPlan(plan_key);
      }
      this.visible = true;
    },

    hide() {
      this.visible = false;
    },

    reset() {
      this.plan = {
        original_name: null,
        name: null,
        description: null,
        price: null,
        old_price: null,
        is_group: false,
        procurement_count: null,
        procurement_sum: null,
        max_organization_count: null,
        duration_days: null,
        created_at: null,
        archived_at: null
      };
      this.plan_has_discount = false;
      this.unlimited_procurement_sum = false;
      this.unlimited_procurement_count = false;
      this.unlimited_organization_count = false;
    },

    async loadPlan(plan_key) {
      this.loading = true;
      const plan = await callAPI('POST', '/api/operator/getPlan', { key: plan_key });
      this.plan = plan;
      this.plan.original_name = plan.name;
      this.plan.unlimited_procurement_sum = plan.procurement_sum > 0;
      this.plan.unlimited_procurement_count = plan.procurement_count > 0;
      this.plan.unlimited_organization_count = plan.max_organization_count > 0;
      this.plan_has_discount = Number.isFinite(plan.old_price);
      this.loading = false;
    },

    async savePlan() {
      this.submitting = true;
      try {
        await callAPI('POST', '/api/operator/savePlan', {
          key: this.plan.key,
          name: this.plan.name,
          description: this.plan.description,
          price: this.plan.price ? Number(this.plan.price) : null,
          old_price: this.plan.old_price ? Number(this.plan.old_price) : null,
          procurement_count: this.unlimited_procurement_count? -1 : Number(this.plan.procurement_count),
          procurement_sum: this.unlimited_procurement_sum ? -1 : Number(this.plan.procurement_sum),
          is_group: this.plan.is_group,
          duration_days: this.plan.duration_days,
          max_organization_count: this.plan.is_group ? (this.unlimited_organization_count ? -1 : Number(this.plan.max_organization_count)) : null
        });
        this.alert({ dialogType: 'success', text: 'Тарифный план успешно сохранен' });
        this.hide();
        this.$emit('refresh');
      } catch (error) {
        if (error.fields) {
          this.$refs.observer.setErrors(error.fields);
        } else {
          this.alert({ dialogType: 'error', text: error });
        }
      } finally {
        this.submitting = false;
      }
    },

    deletePlan(play_key) {
      this.$root.$confirm({
        text: 'Удалить выбранный тарифный план?'
      }).then(async (callback) => {
        try {
          await callAPI('POST', '/api/operator/deletePlan', { key: play_key });
          this.$emit('refresh');
          this.hide();
        } catch (error) {
          this.alert({ dialogType: 'error', text: error });
        } finally {
          callback();
        }
      });
    },

    archivePlan(play_key) {
      this.$root.$confirm({
        title: 'Архивировать выбранный тариф?',
        text: 'Тариф будет перемещен в архив.<br><br>Организации, у которых тарифный план активирован в данный момент, продолжат им пользоваться'
      }).then(async (callback) => {
        try {
          await callAPI('POST', '/api/operator/archivePlan', { key: play_key });
          this.$emit('refresh');
          this.hide();
        } catch (error) {
          this.alert({ dialogType: 'error', text: error });
        } finally {
          callback();
        }
      });
    },

    restorePlan(play_key) {
      this.$root.$confirm({
        title: 'Восстановить выбранный тариф?',
        text: 'Тариф будет возвращен из архива'
      }).then(async (callback) => {
        try {
          await callAPI('POST', '/api/operator/restorePlan', { key: play_key });
          this.$emit('refresh');
          this.hide();
        } catch (error) {
          this.alert({ dialogType: 'error', text: error });
        } finally {
          callback();
        }
      });
    }
  }
}
</script>

<style scoped>
</style>