import Vue from "vue";
import VueRouter from "vue-router";
import CatalogView from "@/views/CatalogView.vue";
import OrganizationsView from "@/views/OrganizationsView.vue";
import FinanceView from "@/views/FinanceView.vue";
import OperatorsView from "@/views/OperatorsView.vue";
import LoginView from "@/views/LoginView";
import ProfileView from "@/views/ProfileView";
import KnowledgeBaseView from "@/views/KnowledgeBaseView";
import ProceduresView from "@/views/ProceduresView";
import AboutView from "@/views/AboutView";

import { store } from "@/store"
import UsersView from "@/views/UsersView.vue";
import DeploymentView from "@/views/DeploymentView.vue";
import SchedulerView from "@/views/SchedulerView.vue";
import TagsView from "@/views/TagsView.vue";

Vue.use(VueRouter);


const routes = [
    {
        path: "/catalog",
        name: "catalog",
        component: CatalogView,
        meta: {
            layout: 'DefaultLayout',
            authRequired: true
        },
        props: true
    },
    {
        path: "/organizations",
        name: "organizations",
        component: OrganizationsView,
        meta: {
            layout: 'DefaultLayout',
            authRequired: true
        },
        props: true
    },
    {
        path: "/tags",
        name: "tags",
        component: TagsView,
        meta: {
            layout: 'DefaultLayout',
            authRequired: true
        },
        props: true
    },
    {
        path: "/finance",
        name: "finance",
        component: FinanceView,
        meta: {
            layout: 'DefaultLayout',
            authRequired: true
        },
        props: true
    },
    {
        path: "/operators",
        name: "operators",
        component: OperatorsView,
        meta: {
            layout: 'DefaultLayout',
            authRequired: true
        },
        props: true
    },
    {
        path: "/",
        name: "users",
        component: UsersView,
        meta: {
            layout: 'DefaultLayout',
            authRequired: true
        },
        props: true
    },
    {
        path: "/login",
        name: "login",
        component: LoginView,
        meta: {
            layout: 'AuthorizationLayout',
            authRequired: false
        },
        props: true
    },
    {
        path: "/profile",
        name: "profile",
        component: ProfileView,
        meta: {
            layout: 'DefaultLayout',
            authRequired: true
        },
        props: true
    },
    {
        path: "/knowledge_base",
        name: "knowledge_base",
        component: KnowledgeBaseView,
        meta: {
            layout: 'DefaultLayout',
            authRequired: true
        },
        props: true
    },
    {
        path: "/about",
        name: "about",
        component: AboutView,
        meta: {
            layout: 'DefaultLayout',
            authRequired: true
        },
        props: true
    },
    {
        path: "/procedures",
        name: "procedures",
        component: ProceduresView,
        meta: {
            layout: 'DefaultLayout',
            authRequired: true
        },
        props: true
    },
    {
        path: "/deployment",
        name: "deployment",
        component: DeploymentView,
        meta: {
            layout: 'DefaultLayout',
            authRequired: true
        },
        props: true
    },
    {
        path: "/scheduler",
        name: "scheduler",
        component: SchedulerView,
        meta: {
            layout: 'DefaultLayout',
            authRequired: true
        },
        props: true
    },
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
    if (to.meta.authRequired) {
        if(!store.state.auth.token) {
            next({
                name: 'login'
            })
        } else {
            next()
        }
    }
    next()
    // return false
})

export default router;
