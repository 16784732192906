<template>
  <v-container>
    <h1>Каталог</h1>
    <div>Управление товарами и категориями</div>

    <div style="height: 1rem;"></div>

    <v-btn-toggle
      v-model="tab"
      mandatory
    >
      <v-btn
        value="items"
        color="white"
        @click="$router.push({ path: '/catalog', query: {}})"
      >
        Управление товарами
      </v-btn>
      <v-btn
        value="categories"
        color="white"
        @click="$router.push({ path: '/catalog', query: { tab: 'categories' }})"
      >
        Управление категориями
      </v-btn>
      <v-btn
        value="ownPosition"
        color="white"
        @click="$router.push({ path: '/catalog', query: { tab: 'ownPosition' }})"
      >
        Управление пользовательскими товарами
        <v-badge
          v-if="userProductCount > 0"
          inline
          class="ml-1"
          color="#FF0000"
          :content="userProductCount"
        />
      </v-btn>
    </v-btn-toggle>

    <div style="height: 1rem;" />

    <div>
      <v-slide-x-transition mode="out-in">
        <products-tab v-if="tab === 'items'" />
        <categories-tab v-else-if="tab === 'categories'" />
        <own-position-tab
          v-else-if="tab === 'ownPosition'"
          @update="getProductCount"
        />
      </v-slide-x-transition>
    </div>
  </v-container>
</template>

<script>
import ProductsTab from "@/components/Catalog/Products/ProductsTab.vue";
import CategoriesTab from "@/components/Catalog/Categories/CategoriesTab";
import OwnPositionTab from "@/components/Catalog/OwnPostion/OwnPositionTab";
import {listProducts} from "@/services/api";

export default {
  name: "CatalogView",

  components: {
    OwnPositionTab,
    ProductsTab,
    CategoriesTab
  },

  data() {
    return {
      tab: "items",
      userProductCount: 0
    }
  },

  async beforeMount() {
    if (this.$route.query?.tab) {
      this.tab = this.$route.query.tab;
    }

    if (this.tab === 'items') {
      await this.getProductCount()
    }
  },
  methods: {
    async getProductCount() {
      let result = await listProducts({ status: 'pending', page: 1,  limit: 1 })
      this.userProductCount = result.total
    }
  }
}
</script>

<style scoped>

</style>
