<template>
  <v-dialog
    v-model="show"
    width="820"
    scrollable
  >
    <v-card height="90vh">
      <v-card-title class="d-flex">
        <span class="text-h5">
          Просмотр организации
        </span>
        <v-spacer />
        <v-btn
          icon
          @click="show = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="my-0 dialog-body">
        <v-btn-toggle
          v-model="tab"
          elevation="0"
          color="primary"
          mandatory
          class="d-flex flex-row"
        >
          <v-btn
            elevation="0"
            value="organizationInformation"
            color="white"
            class="flex-grow-1"
          >
            Информация об организации
          </v-btn>
          <v-btn
            elevation="0"
            value="organizationEmployees"
            color="white"
            class="flex-grow-1"
          >
            Сотрудники организации
          </v-btn>
        </v-btn-toggle>

        <div class="mb-5 mt-10">
          <v-fade-transition mode="out-in">
            <template v-if="tab === 'organizationInformation'">
              <v-progress-linear
                v-if="loading"
                indeterminate
              />
              <organization-information
                v-else
                :organization="organization"
                :type="type"
                :deactivation-status="deactivationStatus"
                :hide-actions="hideActions"
                @refresh-required="refresh()"
                @close="closeOrganizationDialog"
              />
            </template>

            <organization-employees
              v-else-if="tab === 'organizationEmployees'"
              :organization-inn="organization.inn"
            />
          </v-fade-transition>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import OrganizationInformation from "@/components/Orgranizations/OrgranizationsList/OrganizationDialogTabs/OrganizationInformation";
import OrganizationEmployees from "@/components/Orgranizations/OrgranizationsList/OrganizationDialogTabs/OrganizationEmployees.vue";
import { getOrganization } from "@/services/api";

export default {
  name: "OrganizationDialog",

  components: { OrganizationEmployees, OrganizationInformation},

  props: {
    value: Boolean,

    orgId: {
      type: String,
      default: '',
      required: true
    },

    type: {
      type: String,
      default: ''
    },

    deactivationStatus: {
      type: String,
      default: ''
    },

    hideActions: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      tab: '',
      loading: false,
      organization: {}
    }
  },

  computed: {
    show: {
      get () {
        return this.value
      },

      set (value) {
        if (value === false) {
          this.tab = 'organizationInformation';
        }
        this.$emit('input', value)
      }
    }
  },

  watch: {
    orgId: {
      immediate: true,
      handler (id) {
        if (id) {
          this.loadOrganization(id);
        } else {
          this.organization = {};
          this.tab = 'organizationInformation';
        }
      }
    }
  },

  methods: {
    async loadOrganization (id) {
      if (id === '') {
        return;
      }
      try {
        this.loading = true;
        this.organization = await getOrganization({ organization_inn: id });
      } finally {
        this.loading = false;
      }
    },

    refresh() {
      this.$emit('refresh-required');
      this.loadOrganization(this.orgId);
    },

    closeOrganizationDialog() {
      this.tab = 'organizationInformation';
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
