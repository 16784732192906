<template>
  <div>
    <v-dialog
      v-model="show"
      width="520"
    >
      <v-card>
        <v-card-title class="d-flex">
          <span class="text-h6 title">Признание пользовательского товара дублем</span>
          <v-spacer />
          <v-btn
            icon
            @click="show = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mb-0 dialog-body pb-0">
          <v-form @submit.prevent>
            <v-text-field
              v-model.number="itemId"
              type="number"
              outlined
              dense
              placeholder="Введите код существующего товара"
              label="Код товара"
              class="mb-2"
              @input="debounceInput"
            />
            <div
              class="d-flex justify-center"
            >
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
                class="mb-4"
              />

              <div
                v-else-if="error"
                class="mb-4"
              >
                Товар с данным кодом не найден
              </div>
            </div>

            <v-card
              v-if="item && Object.keys(item).length !== 0"
              class="mb-3"
            >
              <v-card-text style="display: flex; flex-direction: row;">
                <div style="margin-right: 10px;">
                  <v-img
                    width="100"
                    height="100"
                    :src="item.images[0].sm"
                    :srcset="item.images[0].md + ' 2x'"
                  />
                </div>
                <div>
                  <span style="font-weight: bold;">Название: </span>
                  {{ item.name }}
                  <br>
                  <span style="font-weight: bold;">Производитель: </span>
                  {{ item.manufacturer_name }}
                </div>
              </v-card-text>
            </v-card>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-container class="pa-0">
            <v-row>
              <v-col>
                <v-btn
                  color="primary"
                  outlined
                  block
                  @click="close"
                >
                  Отменить
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  color="primary"
                  type="submit"
                  block
                  elevation="0"
                  :disabled="loadingButton || error || !item"
                  :loading="loadingButton"
                  @click="rejectUserProduct"
                >
                  Признать дублем
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <success-dialog
      v-model="successDialog"
      :text="successDialogText"
    />
    <error-dialog v-model="errorDialog" />
  </div>
</template>

<script>
import SuccessDialog from "@/components/Dialogs/SuccessDialog";
import ErrorDialog from "@/components/Dialogs/ErrorDialog";
import {getProduct, rejectUserProduct} from "@/services/api";

export default {
  components: {
    SuccessDialog,
    ErrorDialog
  },

  props: {
    value: Boolean,
    productId: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      errorDialog: false,
      successDialog: false,
      successDialogText: 'Пользовательский товар был отклонен!',
      uploadUrl: null,
      itemId: null,
      item: false,
      loading: false,
      error: false,
      loadingButton: false,
      inputDebounceTimer: null
    }
  },

  computed: {
    show: {
      get () {
        return this.value;
      },
      set (value) {
        this.reset();
        this.$emit('input', value);
      }
    }
  },

  methods: {
    async rejectUserProduct() {
      try {
        this.loadingButton = true
        await rejectUserProduct({
          product_id: this.productId,
          reference_product_id: this.itemId
        }, false)
        this.show = false
        this.$emit('done')
        this.successDialog = true
      } catch (e) {
        this.errorDialog = true
      } finally {
        this.loadingButton = false
      }
    },

    async findItem() {
      try {
        this.loading = true
        this.error = false
        this.item = false
        if (this.itemId) {
          this.item = await getProduct({
            product_id: this.itemId
          })
        }
      } catch (e) {
        this.error = true
      } finally {
        this.loading = false
      }
    },

    debounceInput() {
      // cancel pending call
      clearTimeout(this.inputDebounceTimer)

      // delay new call 500ms
      this.inputDebounceTimer = setTimeout(() => this.findItem(), 500);
    },

    close() {
      this.show = false;
      this.reset();
    },

    reset() {
      this.error = false;
      this.itemId = null;
      this.item = false;
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  font-size: 18px !important;
}
</style>

