<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      style="border: 1px solid rgba(0, 0, 0, 0.12)"
      :loading="loading"
      loading-text="Пользовательские товары загружаются..."
      :hide-default-header="loading"
      :hide-default-footer="loading"
      :server-items-length="total"
    >
      <template #[`item.sku`]="{ item }">
        <v-chip
          color="success"
          small
        >
          <div
            class="chip-sku"
            :title="item.sku"
          >
            {{ item.sku || '-' }}
          </div>
        </v-chip>
      </template>
      <template #[`item.manufacturer_name`]="{ item }">
        <b>{{ item.manufacturer_name }}</b>
      </template>
      <template #[`item.id`]="{ item }">
        <v-chip
          color="primary"
          small
        >
          {{ item.id }}
        </v-chip>
      </template>
      <template #[`item.accreditation_required`]="{ item }">
        <v-chip
          v-if="item.accreditation_required"
          small
          color="error"
        >
          Нужна
        </v-chip>
        <v-chip
          v-else
          small
          color="success"
        >
          Не нужна
        </v-chip>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-btn
          elevation="0"
          text
          @click="openOwnPositionDialog(item.id)"
        >
          Открыть товар
          <v-icon class="ml-2">
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <product-dialog
      v-model="ownPositionDialog"
      mode="ownPosition"
      :item-id="selectedItemId"
      @done="onProductRejectOrApprove"
      @change="listProducts"
    />
  </div>
</template>

<script>
import {listProducts} from "@/services/api";
import ProductDialog from "@/components/Catalog/Products/ProductDialog.vue";

export default {
  components: {
    ProductDialog,
  },
  data() {
    return {
      loading: false,
      ownPositionDialog: false,
      selectedItemId: null,
      total: 0,
      headers: [
        {
          text: 'Название',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Производитель',
          align: 'start',
          sortable: false,
          value: 'manufacturer_name',
        },
        {
          text: 'Ед. измерения',
          align: 'start',
          sortable: false,
          value: 'unit_short_name',
        },
        {
          text: 'Артикул производителя',
          align: 'start',
          sortable: false,
          value: 'sku',
        },
        {
          text: 'Код товара',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        {
          text: 'Аккредитация',
          align: 'start',
          sortable: false,
          value: 'accreditation_required',
        },
        {
          text: 'Действия',
          align: 'end',
          sortable: false,
          value: 'actions',
        },
      ],
      items: [],
      ownPositionFiltersDialog: false
    }
  },

  async beforeMount() {
    await this.listProducts()
  },

  methods: {
    async listProducts(payload = { page: 1,  limit: 100, status: 'pending' }) {
      this.loading = true
      let result = await listProducts(payload)
      this.items = result.items
      this.total = result.total
      this.loading = false
    },
    openOwnPositionDialog(id) {
      this.selectedItemId = id
      this.ownPositionDialog = true
    },
    async onProductRejectOrApprove() {
      this.$emit('update')
      await this.listProducts()
    }
  }
}
</script>

<style scoped>

</style>
