<template>
  <div>
    <h2 class="mb-5">
      Заявления на деактивацию организаций
    </h2>

    <div class="organizations-list__filters">
      <FilterButton
        :applied="filters !== null"
        @click="deactivationRequestsFiltersDialog = true"
        @clear="$refs.filterDialog.resetFilter()"
      />

      <v-spacer />

      <search-input
        v-model="search"
        label="Поиск по названию и ИНН организации"
        placeholder="Введите ИНН или название организации"
        style="max-width: 400px;"
        @search="options.page !== 1 ? (options.page = 1) : loadDeactivationItems()"
      />
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :hide-default-header="loading"
      :hide-default-footer="loading"
      :options.sync="options"
      :server-items-length="serverItemsLength"
      style="border: 1px solid rgba(0, 0, 0, 0.12)"
      :item-class="'activeStatus'"
      loading-text="Организации загружаются..."
      :footer-props="{
        'items-per-page-options': [
          30,
          50,
          100
        ],
        'items-per-page-text': 'Организаций на странице'
      }"
      @update:page="onChangePage"
      @update:items-per-page="onChangeItemsPerPage"
    >
      <template #[`item.organization_name`]="{ item }">
        {{ item.organization_name }}
      </template>

      <template #[`item.organization_inn`]="{ item }">
        {{ item.organization_inn }}
      </template>

      <template #[`item.contact`]="{ item }">
        <div>{{ item.contact }}</div>
        <div class="email mt-1">
          {{ item.email }}
        </div>
      </template>

      <template #[`item.status`]="{ item }">
        <v-chip
          :color="DECLINE_COLOR[item.status]"
          small
        >
          {{ DECLINE_STATUS[item.status] }}
        </v-chip>
      </template>

      <template #[`item.status_reason`]="{ item }">
        <div
          v-if="item.status_reason"
          class="d-inline-block text-truncate"
          style="max-width: 250px; opacity: 0.8; cursor: pointer;"
          @click="openDeactivationCommentDialog(item.status_reason)"
        >
          <span>{{ item.status_reason }}</span>
        </div>
        <div v-else>
          &mdash;
        </div>
      </template>

      <template #[`item.actions`]="{ item }">
        <v-btn
          text
          @click="showOrganizationDialog(item)"
        >
          <v-icon>
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <deactivation-requests-filters-dialog
      v-model="deactivationRequestsFiltersDialog"
      ref="filterDialog"
      @apply="applyFilters"
      @reset="resetFilters"
    />

    <deactivation-comment-dialog
      v-model="deactivationCommentDialog"
      :comment="comment"
    />

    <organization-dialog
      v-model="isOpenOrganization"
      :org-id="openedOrganizationId"
      :deactivation-status="deactivationStatus"
      type="deactivation"
      @input="closeDialog"
      @close="closeDialog(true)"
    />
  </div>
</template>

<script>
import OrganizationDialog from "@/components/Orgranizations/OrgranizationsList/OrganizationDialog";
import DeactivationRequestsFiltersDialog from "@/components/Orgranizations/DeactivationRequests/DeactivationRequestsFiltersDialog";
import DeactivationCommentDialog from "@/components/Orgranizations/DeactivationRequests/DeactivationCommentDialog";
import {listDeactivationRequests} from "@/services/api";
import { DECLINE_STATUS, DECLINE_COLOR } from "@/constants/constants";
import FilterButton from "@/components/Buttons/FilterButton";
import SearchInput from "@/components/SearchInput.vue";

export default {
  name: "DeactivationRequestsTab",

  components: {
    SearchInput,
    FilterButton,
    DeactivationRequestsFiltersDialog,
    DeactivationCommentDialog,
    OrganizationDialog
  },

  data() {
    return {
      DECLINE_STATUS,
      DECLINE_COLOR,

      deactivateOrganizationDialog: false,
      deactivationRequestsFiltersDialog: false,
      deactivationCommentDialog: false,
      deactivationStatus: '',

      comment: '',

      loading: false,

      serverItemsLength: 0,

      options: {
        page: 1,
        itemsPerPage: 30
      },

      filters: null,
      search: '',

      headers: [
        {
          text: 'Наименование организации',
          sortable: false,
          value: 'organization_name',
          align: 'start'
        },
        {
          text: 'ИНН',
          sortable: false,
          value: 'organization_inn',
          align: 'start'
        },
        {
          text: 'Статус',
          sortable: false,
          value: 'status',
          align: 'start'
        },
        {
          text: 'Причина',
          sortable: false,
          value: 'status_reason',
          align: 'start',
        },
        {
          text: 'Действия',
          sortable: false,
          value: 'actions',
          align: 'end'
        },
      ],

      items: [],
      openedOrganizationId: null,
      isOpenOrganization: false
    }
  },

  mounted() {
    this.loadDeactivationItems();
  },

  methods: {
    async loadDeactivationItems() {
      try {
        this.loading = true;
        this.items.splice(0, this.items.length); // clear items array

        let status;
        if (this.filters) {
          status = this.filters.status;
        }

        const { items, total } = await listDeactivationRequests({
          page: this.options.page,
          limit: this.options.itemsPerPage,
          search: this.search,
          status: status
        });
        this.items = items;
        this.serverItemsLength = total;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    openDeactivationCommentDialog(comment) {
      this.deactivationCommentDialog = true
      this.comment = comment
    },

    showOrganizationDialog(organization) {
      this.openedOrganizationId = organization.organization_inn;
      this.deactivationStatus = organization.status;
      this.isOpenOrganization = true;
    },

    closeDialog(reloading = false) {
      this.openedOrganizationId = null;
      this.deactivationStatus = '';
      this.isOpenOrganization = false;

      if (reloading) {
        this.loadDeactivationItems();
      }

      this.loadDeactivationItems();
    },

    onChangeItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.loadDeactivationItems();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },

    onChangePage(page) {
      this.page = page;
      this.loadDeactivationItems();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },

    applyFilters(filters) {
      this.deactivationRequestsFiltersDialog = false;
      this.filters = filters;
      this.options.page = 1;
      this.loadDeactivationItems();
    },

    resetFilters() {
      this.deactivationRequestsFiltersDialog = false;
      this.filters = null;
      this.options.page = 1;
      this.loadDeactivationItems();
    }
  }
}
</script>

<style scoped>
.email {
  font-size: 12px;
  color: #8F8F8F;
}
</style>
