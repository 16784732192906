export const ROLES = {
    'supplier': 'Поставщик',
    'customer': 'Покупатель',
    'operator': 'Оператор',
}

export const TRADE_STATUS = {
    'pending': 'Ждет подтверждения',
    'rejected': 'Отклонен',
    'suspended': 'Приостановлен',
    'approved': 'Допущена к торгам',
}

export const TRADE_COLOR = {
    'pending': 'info',
    'rejected': 'error',
    'suspended': 'warning',
    'approved': 'success',
}

export const DECLINE_STATUS = {
    'pending': 'Ждет подтверждения',
    'declined': 'Заявка отклонена',
    'completed': 'Заявка исполнена',
}

export const DECLINE_COLOR = {
    'pending': 'info',
    'declined': 'error',
    'completed': 'success',
}

export const ACCREDITATION_STATUS = {
    'approved': 'Одобрена',
    'pending': 'На рассмотрении',
    'draft': 'Черновик',
    'rejected': 'Отклонена'
}

export const ACCREDITATION_COLOR = {
    'approved': 'success',
    'pending': 'info',
    'draft': 'default',
    'rejected': 'error'
}

export const ORGNIAZTION_GROUP_COLOR = {
  'approved': 'success',
  'pending': 'info',
  'rejected': 'error'
}

export const ORGNIAZTION_GROUP_STATUS = {
  'approved': 'Заявка исполнена',
  'pending': ' Ждёт подтверждения',
  'rejected': 'Заявка отклонена'
}

export const ORGANIZATIONS_FILTERS = [
    {id: 'approved', value: 'Допущенные к торгам'},
    {id: 'rejected', value: 'Не допущенные к торгам'},
    {id: 'suspended', value: 'Приостановлен'},
    {id: 'pending', value: 'На рассмотрении'},
]

export const ORGANIZATIONS_ROLES = [
    {id: 'supplier', value: 'Поставщик'},
    {id: 'customer', value: 'Покупатель'}
]

export const ORGANIZATION_TYPE = {
    'legal': 'ООО',
    'private': 'Физическое лицо',
    'individual': 'ИП',
}
