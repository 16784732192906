<template>
  <v-container>
    <h1>База знаний</h1>
    <div>Раздел находится в разработке 🏗️</div>
  </v-container>
</template>

<script>
export default {
  name: "KnowledgeBaseView"
}
</script>

<style scoped>

</style>