export function formatDate(date, withTime = true) {
    if (date === null || date === undefined) {
        return null;
    }
    if (typeof date === "string") {
        date = new Date(date);
    }
    let dd = (date.getDate()).toString().padStart(2, '0');
    let mm = (date.getMonth()+1).toString().padStart(2, '0');
    let yyyy = date.getFullYear();
    let result = `${dd}.${mm}.${yyyy}`;
    if (withTime) {
        let hh = date.getHours().toString().padStart(2, '0');
        let mm = date.getMinutes().toString().padStart(2, '0');
        result += ` в ${hh}:${mm}`;
    }
    return result;
}

const WEEKDAY_NAMES = [ 'Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота' ];
const MONTH_NAMES = [ 'Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря' ];

/**
 * @return {Date}
 */
function parseDate(date) {
    if (date === null || date === undefined) {
        return null;
    }
    if (typeof date === "string") {
        date = new Date(date);
    }
    return date;
}

export function formatDateHumaneNoWeekday(date) {
    date = parseDate(date);
    if (date === null) {
        return null;
    }

    const dd = date.getDate();
    const month_name = MONTH_NAMES[date.getUTCMonth()];
    const yyyy = date.getFullYear();
    return `${dd} ${month_name} ${yyyy}`;
}

export function formatDateHumane(date) {
    date = parseDate(date);
    if (date === null) {
        return null;
    }

    const day_name = WEEKDAY_NAMES[date.getUTCDay()];
    const dd = date.getDate();
    const month_name = MONTH_NAMES[date.getUTCMonth()];
    const yyyy = date.getFullYear();
    return `${day_name}, ${dd} ${month_name} ${yyyy}`;
}

export function formatDateTimeHumane(date) {
    date = parseDate(date);
    if (date === null) {
        return null;
    }

    const day_name = WEEKDAY_NAMES[date.getUTCDay()];
    const dd = date.getDate();
    const month_name = MONTH_NAMES[date.getUTCMonth()];
    const yyyy = date.getFullYear();
    const hour = date.getHours().toString().padStart(2, '0');
    const min = date.getMinutes().toString().padStart(2, '0');
    return `${day_name}, ${dd} ${month_name} ${yyyy} в ${hour}:${min}`;
}

export function formatPhone(phone) {
    if (phone === null || phone === undefined) {
        return null;
    }

    if (phone.startsWith('+7')) {
        let formatted = '';
        for (let i = 0; i < 12; i++) {
            if (i === 2) {
                formatted += ` (${phone[i]}`;
            } else if (i === 4) {
                formatted += `${phone[i]}) `;
            } else if (i === 8) {
                formatted += ` ${phone[i]}`;
            } else if (i === 10) {
                formatted += ` ${phone[i]}`;
            } else {
                formatted += phone[i];
            }
        }
        phone = formatted;
    }
    return phone;
}

/** @return string | null */
export function dateToISO8601(date) {
    if (date === null || date === undefined) {
        return null;
    }
    if (typeof date === "string") {
        date = new Date(date);
    }
    return date.toISOString();
}

export function fio(lastName = '', firstName = '', middleName = '') {

    return `${lastName} ${firstName} ${middleName}`
}


export function formatAmount(amount, fractionDigits = 2, noCurrency = false) {
    const options = {
        currency: "RUB",
        minimumFractionDigits: fractionDigits,
        // This option is responsible to show number of digits, after amount, example: if 2, it will show 2 000,00 if 0, it will show 2 000
        maximumFractionDigits: fractionDigits,
    };

    if (!noCurrency) {
        options.style = "currency";
    }

    const formatter = new Intl.NumberFormat("ru-RU", options);

    return formatter.format(amount);
}

export function debounce(func, timeout = 300){
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

export function fallback(value, fallback = "—") {
    if (value === null || value === undefined) {
        return fallback;
    }
    if (typeof value === "string" && value.trim() === '') {
        return fallback;
    }

    return value;
}

export function formatFullName(name, last_name, middle_name) {
    return [ last_name, name, middle_name ].filter(a => a).join(' ').trim();
}

export function buildCategoriesTree (payload) {

    const buildCategoriesTreeRecursive = (categories, parentCategoryId, depth) => {
        return categories
            .filter((item) => item.parent_category_id === parentCategoryId)
            .map((item) => {
                item.depth = depth
                item.children = buildCategoriesTreeRecursive(categories, item.id, depth + 1)
                return item
            })
    }
    return buildCategoriesTreeRecursive(payload, 0, 0)
}

export function getCategoriesByDepth(items, depth) {
    return items.filter(item => item.depth === depth)
}

export function rebuildCategoriesTree(payload) {
    let items = []
    let rootCategories = getCategoriesByDepth(payload, 0).sort((a, b) => {
        return a.index - b.index
    })

    let addCategories = (category) => {
        items.push(category)
        if (category.children) {
            let children = category.children.sort((a, b) => {
                return a.index - b.index
            })
            for (let child of children) {
                addCategories(child)
            }
        }
    }

    for (let rootCategory of rootCategories) {
        addCategories(rootCategory)
    }

    for (let i = 0; i < items.length; i++) {
        items[i].index = i
    }

    return items;
}

export function minMaxDate(a, b) {
    if (a instanceof String) { a = new Date(a); }
    if (b instanceof String) { b = new Date(b); }

    if (a === undefined) {
        return [ b, undefined ];
    }
    if (b === undefined) {
        return [ a, undefined ];
    }

    if (a < b) {
        return [ a, b ];
    } else {
        return [ b, a ];
    }
}

export function getTomorrow(date) {
    const tomorrow = new Date(date);
    tomorrow.setDate(tomorrow.getDate()+1);
    return tomorrow;
}

export function getRoleColor(role) {
    switch (role) {
        case 'admin':
            return 'accent'
        case 'operator':
            return 'primary'
        case 'supplier':
            return '#d7c4b0';
        case 'customer':
            return '#f7e438';
    }
    return 'gray';
}

export function getRoleText(role) {
    switch (role) {
        case 'admin':
            return 'Администратор'
        case 'operator':
            return 'Оператор'
        case 'supplier':
            return 'Поставщик'
        case 'customer':
            return 'Покупатель'
    }
    return role;
}
