<template>
  <v-card height="90vh" class="card">
    <v-card-title>
      <span>Поиск пользователей</span>

      <v-spacer />

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Поиск по ФИО"
        single-line
        hide-details
        @input="debounceSearch"
      />
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loadingUsers"
        :options.sync="options"
        :server-items-length="serverItemsLength"
        loading-text="Загрузка"
        single-select
        item-key="id"
        :show-select="true"
        :hide-default-footer="loadingUsers"
        :footer-props="{
          'items-per-page-options': [10, 20, 30],
          'items-per-page-text': 'Пользователей на странице',
        }"
        @item-selected="onUserSelect"
      >
        <template #[`item.is_organization_owner`]="{ item }">
          <div class="py-5">
            {{ item.is_organization_owner ? "👑" : "" }}
          </div>
        </template>
        <template #[`item.name`]="{ item }">
          <div class="py-5">
            {{ item.last_name }} {{ item.name }} {{ item.middle_name }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions>
      <v-row>
        <v-col cols="6">
          <v-btn
            elevation="0"
            block
            color="success"
            :disabled="!selectedUser.value || submitting"
            :loading="submitting"
            @click="isModalOpen = true"
          >
            Подтвердить выбор
          </v-btn>
        </v-col>

        <v-col cols="6">
          <v-btn
            elevation="0"
            block
            :disabled="submitting"
            @click="$emit('back')"
          >
            Назад
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <CompanyGroupModal v-model="isModalOpen" :user="selectedUser" @submit="setOwner" />
  </v-card>
</template>

<script>
import { callAPI, listUsers, setOrganizationOwner } from "@/services/api";
import CompanyGroupModal from "./CompanyGroupModal.vue";

export default {
  components: { CompanyGroupModal },
  name: "OrganizationUsers",

  props: {
    organization: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      isModalOpen: false,
      search: "",
      searchDebounceTimer: null,

      items: [],
      serverItemsLength: 0,

      loadingUsers: false,
      selectedUser: {
        item: {},
        value: false,
      },

      submitting: false,

      options: {
        page: 1,
        itemsPerPage: 10,
      },

      headers: [
        {
          text: "ФИО",
          sortable: false,
          value: "name",
          align: "start",
        },
        {
          text: "Служебный e-mail",
          sortable: false,
          value: "email",
          align: "start",
        },
        {
          text: "Служебный номер телефона",
          sortable: false,
          value: "phone",
          align: "start",
        },
        {
          text: "Должность",
          sortable: false,
          value: "occupation",
          align: "start",
        },
        {
          text: "",
          sortable: false,
          value: "is_organization_owner",
          align: "start",
        },
      ],
    };
  },

  watch: {
    options: {
      handler() {
        this.findUsers();
      },
      deep: true,
    },
  },

  methods: {
    debounceSearch() {
      // cancel pending call
      clearTimeout(this.searchDebounceTimer);

      // delay new call 500ms
      this.searchDebounceTimer = setTimeout(() => this.findUsers(true), 500);
    },

    async findUsers(resetPage = false) {
      if (resetPage) {
        this.options.page = 1;
      }

      try {
        this.loadingUsers = true;

        this.items.splice(0, this.items.length); // clear array


        const { items, total } = await callAPI("POST", "/api/operator/listUsers", {
          organization_inn: this.organization.inn,
          query: this.search,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          role: this.organization.role
        });

        this.serverItemsLength = total;
        this.items = items;
      } finally {
        this.loadingUsers = false;
      }
    },

    async setOwner(group_id) {
      try {
        this.submitting = true;

        await setOrganizationOwner({
          organization_inn: this.organization.inn,
          user_id: this.selectedUser.item.id,
          group_id,
        });

        this.$notify({
          type: "success",
          title: "Успешно",
          text: "Организация прикреплена к пользователю",
          duration: 5000,
        });

        this.$emit("close");
        this.$emit('refresh-required');
      } catch (error) {
        this.$store.dispatch(
          "notify",
          "Не удалось прикрепить организацию к пользователю"
        );
      } finally {
        this.submitting = false;
      }
    },

    onUserSelect(data) {
      this.selectedUser = data;
    },
  },
};
</script>

<style scoped lang="scss"></style>
