<template>
  <v-dialog
    v-model="show"
    width="520"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Фильтры</span>

        <v-spacer />

        <v-btn
          icon
          @click="show = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="mb-0 dialog-body">
        <v-select
          v-model="selectedCategories"
          :items="categoryTree"
          item-text="name"
          item-value="id"
          label="Категория"
          placeholder="Выберите категорию"
          outlined
          dense
          multiple
          class="tree-filter"
        >
          <template #item="{item}">
            <v-list-item tag="span">
              <div style="display: flex; align-items: center">
                <span
                  class="tree-vertical"
                  :class="{
                    'tree-vertical_beginner': item.depth === 0,
                    'tree-vertical_end': !categoryTree[item.index + 1] || categoryTree[item.index + 1]?.depth === 0,
                  }"
                />

                <span
                  class="tree-horizontal"
                  :style="`width: ${item.depth * 40}px;`"
                />

                <span style="padding-left: 10px">
                  {{ item.name }}
                </span>
              </div>
            </v-list-item>
            <v-divider class="mt-2" />
          </template>
        </v-select>
      </v-card-text>

      <v-card-actions>
        <v-container class="pa-0">
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                outlined
                block
                @click="resetFilter"
              >
                Сбросить
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                type="submit"
                block
                elevation="0"
                @click="applyFilter"
              >
                Применить
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import {listCategories} from "@/services/api";
import {buildCategoriesTree, rebuildCategoriesTree} from "@/utils/helpers";

export default {
  name: "ItemsFiltersModal",
  props: {
    value: Boolean
  },
  data() {
    return {
      categories: [],
      categoryTree: [],
      selectedCategories: []
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  async beforeMount() {
    await this.listCategories()
  },

  methods: {
    async listCategories() {
      this.categories = await listCategories({role: 'supplier'});
      this.categoryTree = rebuildCategoriesTree(buildCategoriesTree(this.categories));
    },

    applyFilter() {
      if (this.selectedCategories.length) {
        this.$emit('apply-filter', this.selectedCategories)
      }
    },

    resetFilter() {
      this.selectedCategories = []
      this.$emit('reset-filter')
    }
  }
}
</script>

<style scoped lang="scss">
.tree {
  &-vertical {
    display: block;
    height: 40px;
    width: 2px;

    background: #1b7dc9;

    &_beginner {
      background: linear-gradient(to bottom, transparent 0, transparent 19px, #1b7dc9 19px, #1b7dc9 100%);
    }

    &_end {
      background: linear-gradient(to bottom, #1b7dc9 0, #1b7dc9 21px, transparent 21px, transparent 100%);
    }
  }

  &-horizontal {
    display: block;
    height: 2px;
    background: #1b7dc9;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
}
</style>
