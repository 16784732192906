<template>
  <v-dialog v-model="visible" :max-width="width" :persistent="loading" @click:outside="hide()">
    <v-card>
      <v-card-title>
        {{ title }}
        <v-spacer/>
        <v-btn icon @click="hide()" :disabled="loading"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text v-html="text"></v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn color="normal" elevation="0" large block outlined text @click="cancel()" :disabled="loading">Отмена</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn :color="okColor" elevation="0" large block @click="confirm()" :loading="loading">{{ ok }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {

  name: 'ConfirmationDialog',

  data() {
    return {
      title: '',
      text: '',
      ok: 'Подтвердить',
      okColor: 'primary',
      width: 560,
      loading: false,
      visible: false,
      resolve: null,
      reject: null
    }
  },

  mounted() {
    this.$root.$confirm = this.show;
  },

  methods: {
    show(params) {
      this.title = params.title ?? 'Подтвердите действие';
      this.text = params.text;
      this.ok = params.ok ?? 'Подтвердить';
      this.okColor = params.okColor ?? 'primary';
      this.width = params.width ?? 560;
      this.loading = false;
      this.visible = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    hide() {
      this.visible = false;
    },

    cancel() {
      this.reject();
      this.hide();
    },

    confirm() {
      this.loading = true;
      this.resolve(() => {
        this.loading = false;
        this.hide();
      });
    }
  }
}
</script>
