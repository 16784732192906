<template>
  <div>
    <v-dialog
      v-model="show"
      width="820"
      @close="parentCategoryId = 0"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6 title">
            <template v-if="mode === 'new'">Новая категория</template>
            <template v-if="mode === 'edit'">{{ model.name }}</template>
          </span>

          <v-spacer />

          <v-btn
            icon
            @click="show = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mb-0 dialog-body">
          <v-form ref="form">
            <v-text-field
              v-model="model.name"
              label="Название"
              outlined
              dense
              placeholder="Название категории"
              maxlength="120"
            />

            <v-autocomplete
              v-model="parentCategoryId"
              :items="filteredCategories"
              item-text="name"
              item-value="id"
              label="Родительская категория"
              placeholder="Выберите родительскую категорию"
              outlined
              dense
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-container>
            <v-row>
              <v-col>
                <v-btn
                  color="primary"
                  outlined
                  block
                  @click="show = false"
                >
                  Отменить
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  color="primary"
                  type="submit"
                  block
                  elevation="0"
                  :loading="loading"
                  :disabled="loading"
                  @click="submit"
                >
                  <template v-if="mode === 'new'">
                    Создать категорию
                  </template>
                  <template v-if="mode === 'edit'">
                    Редактировать категорию
                  </template>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <success-dialog v-model="successDialog" />
    <error-dialog v-model="errorDialog" />
  </div>
</template>

<script>
import SuccessDialog from "@/components/Dialogs/SuccessDialog";
import {listCategories, createCategory, editCategory } from "@/services/api";
import ErrorDialog from "@/components/Dialogs/ErrorDialog";

export default {
  name: "EditCategoryDialog",
  components: {
    SuccessDialog,
    ErrorDialog
  },
  props: {
    value: Boolean,
    mode: {
      type: String,
      default: ''
    },
    categoryId: {
      type: Number,
      default: 0
    },
    category: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      // dialog: false
      model: {
        name: "",
      },
      errorDialog: false,
      selectedCategoryId: null,
      categories: [],
      parentCategoryId: 0,
      successDialog: false,
      loading: false
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    categoryObject: {
      get () {
        return this.category
      }
    },
    filteredCategories() {
      if (this.categoryId) {
        const subcategories = this.getCategoryChildIdsRecursive(this.categoryId);
        return this.categories.filter(category => !subcategories.includes(category.id));
      }
      return this.categories;
    }
  },
  watch: {
    mode() {
      this.parentCategoryId = 0
      if(this.mode === 'new') {
        this.model.name = ''
        this.model.parent_category_id = 0
      } else {
        this.loadCategory(this.categoryId)
      }
    },

    categoryId(newCategoryId) {
      this.parentCategoryId = 0
      this.loadCategory(newCategoryId)
    }
  },

  async beforeMount() {
    await this.listCategories()
  },

  methods: {
    async listCategories() {
      this.categories = await listCategories({ role: 'supplier' });
      this.categories.unshift({ id: 0, name: "Нет родительской категории" })
    },

    getCategoryChildIds(categoryId) {
      let result = [];
      for (const category of this.categories) {
        if (categoryId !== undefined && category.parent_category_id === categoryId) {
          result.push(category.id);
        }
      }
      return result;
    },

    getCategoryChildIdsRecursive(categoryId) {
      let result = [categoryId];

      const f = (id) => {
        let childs = this.getCategoryChildIds(id);
        result.push(...childs);
        for (const child of childs) {
          f(child);
        }
      };
      f(categoryId);

      return result;
    },

    async submit() {
      if (this.mode === 'new') {
        await this.createCategory()
      } else if (this.mode === 'edit') {
        await this.editCategory()
      }
    },

    async createCategory() {
      try {
        this.loading = true
        await createCategory({
          name: this.model.name,
          parent_category_id: this.parentCategoryId
        })
        await this.listCategories()
        this.successDialog = true
        this.show = false
        this.parentCategoryId = 0
        this.model.name = ''
        this.$emit('done')
      } catch (e) {
        this.errorDialog = true
      } finally {
        this.loading = false
      }

    },

    async editCategory() {
      try {
        this.loading = true

        await editCategory({
          id: this.selectedCategoryId,
          name: this.model.name,
          parent_category_id: this.parentCategoryId
        })

        await this.listCategories()

        this.successDialog = true
        this.show = false
        this.$emit('done')
      } catch (e) {
        this.errorDialog = true
      } finally {
        this.loading = false
      }
    },

    async loadCategory(categoryId) {
      let category = this.categories.filter(val => val.id === categoryId)[0]
      this.model.name = category.name
      this.model.parent_category_id = category.parent_category_id
      this.parentCategoryId = category.parent_category_id
      this.selectedCategoryId = categoryId
    }

  }
}
</script>

<style scoped lang="scss">
.title {
  max-width: calc(100% - 80px);
  word-wrap: break-word;
}
</style>
