<template>
  <div>
    <FilterButton
      disabled
      class="mb-5"
      :applied="filters !== null"
      @click="filtersDialog = true"
      @clear="$refs.filterDialog.resetFilter()"
    />
    <transactions-table
      ref="table"
      mode="withdrawal"
      :filters="filters"
      @show-request="showRequest"
    />
    <request-dialog
      v-model="requestDialog"
      mode="withdrawal"
      :item="item"
      @done="reload"
    />

    <withdrawals-filters-dialog
      ref="filterDialog"
      v-model="filtersDialog"
      @apply="applyFilters"
      @reset="resetFilters"
    />
  </div>
</template>

<script>
import FilterButton from "@/components/Buttons/FilterButton";
import TransactionsTable from "@/components/Finance/CommonComponents/RequestsTable";
import RequestDialog from "@/components/Finance/CommonComponents/RequestDialog";
import WithdrawalsFiltersDialog from "@/components/Finance/Withdrawals/WithdrawalsFiltersDialog.vue";

export default {
  name: "WithdrawalsTab",
  components: {
    WithdrawalsFiltersDialog,
    RequestDialog,
    FilterButton,
    TransactionsTable
  },
  data() {
    return {
      item: {},
      invoiceFiltersDialog: false,
      requestDialog: false,
      filters: null,
      filtersDialog: false
    }
  },
  methods: {
    showRequest(item) {
      this.requestDialog = true
      this.item = item
    },
    reload() {
      this.$refs.table.type = 'withdrawal'
      this.$nextTick(() => this.$refs.table.listWalletRequests(1))
    },
    applyFilters(filters) {
      this.filtersDialog = false;
      this.filters = filters;
      this.reload();
    },
    resetFilters() {
      this.filtersDialog = false;
      this.filters = null;
      this.reload();
    }
  }
}
</script>

<style scoped>

</style>