<template>
  <validation-observer
      ref="observer"
      v-slot="{ invalid }"
  >
    <v-dialog v-model="visible" max-width="700" :persistent="submitting">
      <div v-if="loading" style="text-align: center; height: 40px;">
        <v-progress-circular indeterminate color="white"/>
      </div>
      <v-card v-else>
        <v-card-title>
          Редактирование общего описания
          <v-spacer/>
          <v-btn icon @click="hide()" :disabled="submitting"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-subtitle>Этот текст будет отображаться под списком тарифных планов</v-card-subtitle>
        <v-card-text>
          <validation-provider
              v-slot="{ errors }"
              vid="description"
              name="Описание"
              rules="max:4000"
          >
            <atis-rich-text-editor
                v-model="text"
                label="Общее описание"
                hide-details
                :disabled="submitting"
                height="300"
                :error-messages="errors"
            />
            <div class="text-caption" :class="{ 'error--text': errors.length !== 0 }" style="text-align: right;">{{ text.length }} / 4000</div>
          </validation-provider>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn color="normal" elevation="0" block large text outlined :disabled="submitting" @click="cancel()">Отменить</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn color="primary" elevation="0" block large :loading="submitting" @click="submit()" :disabled="invalid">Сохранить</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </validation-observer>
</template>

<script>
import AtisRichTextEditor from "@/components/RichTextEditor.vue";
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { callAPI } from "@/services/api";

export default {
  components: {
    AtisRichTextEditor,
    ValidationObserver,
    ValidationProvider
  },

  inject: [
    'alert'
  ],

  data() {
    return {
      visible: false,
      loading: false,
      submitting: false,
      text: ''
    }
  },

  methods: {

    async show() {
      this.visible = true;

      this.loading = true;
      this.text = await callAPI('POST', '/api/operator/getSetting', { key: 'customer_plans_text' });
      this.loading = false;
    },

    hide() {
      this.visible = false;
    },

    cancel() {
      this.hide();
    },

    async submit() {
      this.submitting = true;

      try {
        await callAPI('POST', '/api/operator/setSettings', { customer_plans_text: this.text })
        this.alert({ dialogType: 'success', text: 'Описание сохранено' });
        this.hide();
      } catch (error) {
        this.alert({ dialogType: 'error', text: error });
      } finally {
        this.submitting = false;
      }
    }
  }
}
</script>