<template>
  <v-row>
    <v-col cols="6">
      <v-text-field
        v-model="bonuses"
        placeholder="Количество бонусов для начисления"
        dense
        type="number"
        :disabled="submitting"
      />
    </v-col>
    <v-col cols="6">
      <v-btn
        color="error"
        :disabled="invalid"
        :loading="submitting"
        outlined
        @click="confirmationDialog = true"
      >
        Начислить бонусы
      </v-btn>
      <are-you-sure-dialog
        v-model="confirmationDialog"
        :on-ok-callback="submit"
        :text="`Вы уверены, что хотите начислить <strong>${bonuses} бонусов</strong> выбранной организации?`"
      />
    </v-col>
  </v-row>
</template>

<script>
import { addOrganizationBonuses } from "@/services/api";
import AreYouSureDialog from "@/components/Dialogs/AreYouSureDialog.vue";

export default {
  components: { AreYouSureDialog },

  props: {
    organizationInn: {
      type: String,
      default: '',
      required: true
    }
  },

  data() {
    return {
      confirmationDialog: false,
      bonuses: null,
      submitting: false
    }
  },

  computed: {
    invalid() {
      const value = Number(this.bonuses);
      return !Number.isFinite(value) || value <= 0;
    }
  },

  methods: {
    clear() {
      this.bonuses = null;
    },

    async submit() {
      try {
        this.submitting = true;

        await addOrganizationBonuses({
          organization_inn: this.organizationInn,
          bonuses: this.bonuses
        });

        this.clear();

        this.$notify({
            type: 'success',
            title: 'Успешно',
            text: 'Бонусы успешно начислены',
            duration: 5000
        });

        this.$emit('success');

      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: error.message,
          duration: 5000
        });
      } finally {
        this.submitting = false;
      }
    }
  }
}
</script>