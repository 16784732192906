<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-row>
      <v-col>
        <h2>Приветственные бонусы</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="mb-2">
          Размер приветственного бонуса для поставщика
        </div>
        <v-text-field
          v-model="helloBonusForSupplier"
          outlined
          hide-spin-buttons
          hide-details
          :disabled="helloBonusForSupplierDisabled"
          @keypress="isInteger($event)"
        />
        <v-btn
          v-if="helloBonusForSupplierDisabled"
          color="primary"
          block
          outlined
          class="mt-3"
          @click="helloBonusForSupplierDisabled = false"
        >
          Редактировать <v-icon class="ml-2">
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          color="primary"
          outlined
          block
          class="mt-3"
          @click="showSaveWarning('helloBonusForSupplier')"
        >
          Сохранить <v-icon class="ml-2">
            mdi-floppy
          </v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <div class="mb-2">
          Размер приветственного бонуса для покупателя
        </div>
        <v-text-field
          v-model="helloBonusForCustomer"
          outlined
          hide-spin-buttons
          hide-details
          :disabled="helloBonusForCustomerDisabled"
          @keypress="isInteger($event)"
        />
        <v-btn
          v-if="helloBonusForCustomerDisabled"
          color="primary"
          block
          outlined
          class="mt-3"
          @click="helloBonusForCustomerDisabled = false"
        >
          Редактировать <v-icon class="ml-2">
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          color="primary"
          outlined
          block
          class="mt-3"
          @click="showSaveWarning('helloBonusForCustomer')"
        >
          Сохранить <v-icon class="ml-2">
            mdi-floppy
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h2 class="mt-5">
          Реферальные бонусы
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="mb-2">
          Размер реферального бонуса для поставщика
        </div>
        <v-text-field
          v-model="referralBonusForSupplier"
          outlined
          hide-spin-buttons
          hide-details
          :disabled="referralBonusForSupplierDisabled"
          @keypress="isInteger($event)"
        />
        <v-btn
          v-if="referralBonusForSupplierDisabled"
          color="primary"
          block
          outlined
          class="mt-3"
          @click="referralBonusForSupplierDisabled = false"
        >
          Редактировать <v-icon class="ml-2">
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          color="primary"
          outlined
          block
          class="mt-3"
          @click="showSaveWarning('referralBonusForSupplier')"
        >
          Сохранить <v-icon class="ml-2">
            mdi-floppy
          </v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <div class="mb-2">
          Размер реферального бонуса для покупателя
        </div>
        <v-text-field
          v-model="referralBonusForCustomer"
          outlined
          hide-spin-buttons
          hide-details
          :disabled="referralBonusForCustomerDisabled"
          @keypress="isInteger($event)"
        />
        <v-btn
          v-if="referralBonusForCustomerDisabled"
          color="primary"
          block
          outlined
          class="mt-3"
          @click="referralBonusForCustomerDisabled = false"
        >
          Редактировать <v-icon class="ml-2">
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          color="primary"
          outlined
          block
          class="mt-3"
          @click="showSaveWarning('referralBonusForCustomer')"
        >
          Сохранить <v-icon class="ml-2">
            mdi-floppy
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <are-you-sure-dialog
      v-model="areYouSureDialog"
      :on-cancel-callback="cancelCallback"
      :on-ok-callback="okCallback"
      :text="areYouSureDialogText"
    />
    <success-dialog v-model="successDialog" />
    <error-dialog v-model="errorDialog" />
  </div>
</template>

<script>
import AreYouSureDialog from "@/components/Dialogs/AreYouSureDialog";
import SuccessDialog from "@/components/Dialogs/SuccessDialog";
import ErrorDialog from "@/components/Dialogs/ErrorDialog";

import {listSettings, setSettings} from "@/services/api"

export default {
  name: "BonusesTab",
  components: {
    AreYouSureDialog,
    SuccessDialog,
    ErrorDialog
  },
  data() {
    return {
      helloBonusForSupplier: 0,
      helloBonusForCustomer: 0,
      referralBonusForSupplier: 0,
      referralBonusForCustomer: 0,

      helloBonusForSupplierDisabled: true,
      helloBonusForCustomerDisabled: true,
      referralBonusForSupplierDisabled: true,
      referralBonusForCustomerDisabled: true,

      areYouSureDialog: false,
      areYouSureDialogText: '',

      cancelCallback: () => {},
      okCallback: () => {},

      successDialog: false,
      errorDialog: false,

      loading: false
    }
  },
  async beforeMount() {
    await this.loadBonusValues()
  },
  methods: {
    async loadBonusValues() {

      try {
        this.loading = true
        const parameters = await listSettings()
        this.helloBonusForSupplier = parameters.supplier_welcome_bonuses
        this.helloBonusForCustomer = parameters.customer_welcome_bonuses
        this.referralBonusForSupplier = parameters.supplier_referral_bonuses
        this.referralBonusForCustomer = parameters.customer_referral_bonuses
      } catch (e) {
        this.errorDialog = true
      } finally {
        this.loading = false
      }
      // helloBonusForCustomer: 500,
      // referralBonusForSupplier: 500,
      // referralBonusForCustomer: 500,

    },
    showSaveWarning(parameter) {
      switch (parameter) {
        case "helloBonusForSupplier":
          this.areYouSureDialogText = "Вы уверены что хотите изменить размер приветственного бонуса для поставщика?"
          this.cancelCallback = () => {
            this.helloBonusForSupplierDisabled = true
          }
          this.okCallback = async () => {
            try {
              await setSettings({
                supplier_welcome_bonuses: Number(this.helloBonusForSupplier)
              })
              await this.loadBonusValues()

              this.successDialog = true
              this.helloBonusForSupplierDisabled = true
            } catch (e) {
              this.errorDialog = true
            }
          }
          break

        case "helloBonusForCustomer":
          this.areYouSureDialogText = "Вы уверены что хотите изменить размер приветственного бонуса для покупателя?"
          this.cancelCallback = () => {
            this.helloBonusForCustomerDisabled = true
          }
          this.okCallback = async () => {
            try {
              await setSettings({
                customer_welcome_bonuses: Number(this.helloBonusForCustomer)
              })
              await this.loadBonusValues()
              this.successDialog = true
              this.helloBonusForCustomerDisabled = true
            } catch (e) {
              this.errorDialog = true
            }
          }
          break

        case "referralBonusForSupplier":
          this.areYouSureDialogText = "Вы уверены что хотите изменить размер реферального бонуса для поставщика?"
          this.cancelCallback = () => {
            this.referralBonusForSupplierDisabled = true
          }
          this.okCallback = async () => {
            try {
              await setSettings({
                supplier_referral_bonuses: Number(this.referralBonusForSupplier)
              })
              await this.loadBonusValues()
              this.successDialog = true
              this.referralBonusForSupplierDisabled = true
            } catch (e) {
              this.errorDialog = true
            }
          }
          break

        case "referralBonusForCustomer":
          this.areYouSureDialogText = "Вы уверены что хотите изменить размер реферального бонуса для покупателя?"
          this.cancelCallback = () => {
            this.referralBonusForCustomerDisabled = true
          }
          this.okCallback = async () => {
            try {
              await setSettings({
                customer_referral_bonuses: Number(this.referralBonusForCustomer)
              })
              await this.loadBonusValues()
              this.successDialog = true
              this.referralBonusForCustomerDisabled = true
            } catch (e) {
              this.errorDialog = true
            }
          }
          break
      }
      this.areYouSureDialog = true
    },

    isInteger(event) {
      const code = (event.which) ? event.which : event.keyCode;
      if ((code > 31 && (code < 48 || code > 57))) {
        event.preventDefault();
      } else {
        return true;
      }
    }
  }
}
</script>

<style scoped>
.v-input {
}
</style>