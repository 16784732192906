<template>
  <v-container>
    <h1>Раздел "О нас"</h1>
    <div>Раздел находится в разработке 🏗️</div>
  </v-container>
</template>

<script>
export default {
  name: "AboutView"
}
</script>

<style scoped>

</style>