import { render, staticRenderFns } from "./OrganizationInformation.vue?vue&type=template&id=5d6d9e52&scoped=true&"
import script from "./OrganizationInformation.vue?vue&type=script&lang=js&"
export * from "./OrganizationInformation.vue?vue&type=script&lang=js&"
import style0 from "./OrganizationInformation.vue?vue&type=style&index=0&id=5d6d9e52&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d6d9e52",
  null
  
)

export default component.exports