<template>
  <v-dialog
    v-model="show"
    max-width="657"
  >
    <v-card>
      <v-card-title />
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <v-icon
              size="72px"
              color="primary"
            >
              mdi-check-bold
            </v-icon>
          </v-col>
          <v-col
            cols="12"
            class="text-center"
          >
            <h2 style="margin-bottom: 0.5rem;">
              Успех!
            </h2>

            {{ text }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <div class="mx-auto">
          <v-btn
            elevation="0"
            color="primary"
            class="px-10 mb-5"
            @click="onClickOk()"
          >
            ОК
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SuccessDialog",
  props: {
    value: Boolean,
    mode: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: 'Импорт товаров успешно завершен'
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    onClickOk() {
      this.$emit('callback');
      this.show = false;
    }
  }
}
</script>

<style scoped>

</style>
