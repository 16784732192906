<template>
  <div>
    <h2 class="mb-5">Заявления на перемещение организаций</h2>

    <div class="organizations-list__filters">
      <SearchInput
        v-model="search"
        label="Поиск по названию и ИНН организации"
        placeholder="Введите ИНН или название организации"
        style="max-width: 400px"
        @search="options.page !== 1 ? (options.page = 1) : loadOrganizations()"
      />
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :server-items-length="serverItemsLength"
      :hide-default-header="loading"
      :hide-default-footer="loading"
      style="border: 1px solid rgba(0, 0, 0, 0.12)"
      :item-class="'activeStatus'"
      loading-text="Организации загружаются..."
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 50, 100],
      }"
      @update:page="onChangePage"
      @update:items-per-page="onChangeItemsPerPage"
    >
      <template #[`item.requested_at`]="{ item }">
        {{ formatDate(item.requested_at) }}
      </template>
      <template #[`item.status`]="{ item }">
        <v-chip :color="ORGNIAZTION_GROUP_COLOR[item.status]" small>
          {{ ORGNIAZTION_GROUP_STATUS[item.status] }}
        </v-chip>
      </template>

      <template #[`item.actions`]="{ item }">
        <v-btn v-if="item.status === 'pending'" text @click="showDialog(item)">
          <v-icon> mdi-arrow-right </v-icon>
        </v-btn>
        <div v-else class="text-center">-</div>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" width="650" persistent>
      <v-card :loading="dialogLoading">
        <v-card-title class="justify-center"> Переместить организацию в новую группу? </v-card-title>

        <v-divider />

        <v-card-actions class="flex-wrap">
          <v-btn
            class="ml-0 mt-2"
            color="primary"
            block
            elevation="0"
            @click="accept"
          >
            Переместить
          </v-btn>
          <v-btn
            class="ml-0 mt-2"
            color="error"
            block
            elevation="0"
            @click="reject"
          >
            Отклонить
          </v-btn>
          <v-btn
            class="ml-0 mt-2"
            color="normal"
            block
            elevation="0"
            @click="dialog = false"
          >
            Отменить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  approveOrganizationGroupChangeRequest,
  listOrganizationGroupChangeRequests,
  rejectOrganizationGroupChangeRequest,
} from "@/services/api";
import {
  ORGNIAZTION_GROUP_COLOR,
  ORGNIAZTION_GROUP_STATUS,
} from "@/constants/constants";
import { fallback, formatDate } from "@/utils/helpers";
import SearchInput from "@/components/SearchInput.vue";

export default {
  name: "AccreditationRequestsTab",

  components: {
    SearchInput,
  },

  data() {
    return {
      ORGNIAZTION_GROUP_COLOR,
      ORGNIAZTION_GROUP_STATUS,

      openedOrganizationId: null,
      accreditationRequestsFiltersDialog: false,
      accreditationRequestDialog: false,

      loading: false,
      dialogLoading: false,

      serverItemsLength: 0,
      dialog: false,
      selectedItem: null,

      options: {
        page: 1,
        itemsPerPage: 20,
      },

      search: "",

      headers: [
        {
          text: "Пользователь",
          sortable: false,
          value: "actor_full_name",
          align: "start",
        },
        {
          text: "Организация",
          sortable: false,
          value: "organization_name",
          align: "start",
        },
        {
          text: "ИНН организации",
          sortable: false,
          value: "organization_inn",
          align: "start",
        },
        {
          text: "Дата заявки",
          sortable: false,
          value: "requested_at",
          align: "start",
        },
        {
          text: "Группа, из которой перемещаем организацию",
          sortable: false,
          value: "from_group_name",
          align: "start",
        },
        {
          text: "Группа, в которую перемещаем организацию",
          sortable: false,
          value: "to_group_name",
          align: "start",
        },
        {
          text: "Статус",
          sortable: false,
          value: "status",
          align: "start",
        },
        {
          text: "Действия",
          sortable: false,
          value: "actions",
          align: "end",
        },
      ],
      items: [],
    };
  },

  watch: {
    dialog(isOpen) {
      if (!isOpen) {
        this.loadOrganizations();
      }
    },
  },

  mounted() {
    this.loadOrganizations();
  },

  methods: {
    formatDate,
    fallback,
    showDialog(data) {
      this.selectedItem = data;
      this.dialog = true;
    },
    async accept() {
      this.dialogLoading = true;
      try {
        await approveOrganizationGroupChangeRequest({
          id: this.selectedItem.id,
        });
        this.$emit("updateCount");
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Ошибка",
          text: err.message,
          duration: 5000,
        });
      } finally {
        this.dialog = false;
        this.dialogLoading = false;
      }
    },
    async reject() {
      this.dialogLoading = true;

      try {
        await rejectOrganizationGroupChangeRequest({
          id: this.selectedItem.id,
        });
        this.$emit("updateCount");
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Ошибка",
          text: err.message,
          duration: 5000,
        });
      } finally {
        this.dialog = false;
        this.dialogLoading = false;
      }
    },
    async loadOrganizations() {
      try {
        this.loading = true;
        this.items.splice(0, this.items.length); // clear items array

        this.items = [];

        const { items, total } = await listOrganizationGroupChangeRequests({
          page: this.options.page,
          limit: this.options.itemsPerPage,
          search: this.search,
        });

        this.items = items;
        this.serverItemsLength = total;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    showAccreditationRequestDialog(item) {
      this.openedOrganizationId = item.organization_inn;
      this.accreditationRequestDialog = true;
    },

    onChangeItemsPerPage(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.loadOrganizations();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },

    onChangePage(page) {
      this.page = page;
      this.loadOrganizations();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  },
};
</script>
