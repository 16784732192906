<template>
  <v-card class="card card-integrated">
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :loading="loadingUsers"
        :options.sync="options"
        :server-items-length="serverItemsLength"
        loading-text="Загрузка"
        single-select
        item-key="id"
        :footer-props="{ 'items-per-page-options': [ 10, 20, 30 ], 'items-per-page-text': 'Пользователей на странице' }"
        @item-selected="onUserSelect"
      >
        <template #[`item.is_organization_owner`]="{ item }">
          <div class="py-5">
            {{ item.is_organization_owner ? '👑' : '' }}
          </div>
        </template>
        <template #[`item.name`]="{ item }">
          <div class="py-5">
            {{ item.last_name }} {{ item.name }} {{ item.middle_name }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { getOrganizationEmployees, setOrganizationOwner } from "@/services/api";

export default {

  props: {
    organizationInn: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      search: '',
      searchDebounceTimer: null,

      items: [],
      serverItemsLength: 0,

      loadingUsers: false,
      selectedUser: {
        item: {},
        value: false
      },

      options: {
        page: 1,
        itemsPerPage: 10
      },

      headers: [
        {
          text: 'ФИО',
          sortable: false,
          value: 'name',
          align: 'start'
        },
        {
          text: 'Служебный e-mail',
          sortable: false,
          value: 'email',
          align: 'start'
        },
        {
          text: 'Служебный номер телефона',
          sortable: false,
          value: 'phone',
          align: 'start'
        },
        {
          text: 'Должность',
          sortable: false,
          value: 'occupation',
          align: 'start'
        },
        {
          text: '',
          sortable: false,
          value: 'is_organization_owner',
          align: 'start'
        },
      ]
    }
  },

  watch: {
    options: {
      handler () {
        this.findUsers();
      },
      deep: true
    },
  },

  methods: {
    debounceSearch() {
      // cancel pending call
      clearTimeout(this.searchDebounceTimer)

      // delay new call 500ms
      this.searchDebounceTimer = setTimeout(() => this.findUsers(), 500);
    },

    async findUsers () {
      try {
        this.loadingUsers = true;

        this.items.splice(0, this.items.length); // clear array

        const { items, total } = await getOrganizationEmployees({
          organization_inn: this.organizationInn,
          query: this.search,
          page: this.options.page,
          limit: this.options.itemsPerPage
        });

        this.serverItemsLength = total;
        this.items = items;
      } finally {
        this.loadingUsers = false;
      }
    },

    async setOwner () {
      try {
        await setOrganizationOwner({
          organization_inn: this.organizationInn,
          user_id: this.selectedUser.item.id
        })

        this.$notify({
          type: 'success',
          title: 'Успешно',
          text: 'Организация прикреплена к пользователю',
          duration: 5000
        });
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Не удалось прикрепить организацию к пользователю',
          duration: 5000
        });
      } finally {
        this.$emit('close');
      }
    },

    onUserSelect (data) {
      this.selectedUser = data;
    }
  }
}
</script>

<style scoped lang="scss">
.card {

  .container {
    background: #fff;
  }
}
.card-integrated {
  box-shadow: none !important;
}
</style>
