<template>
  <v-container fluid class="px-10">
    <h1>Планировщик</h1>
    <div>Управление расписанием запуска скриптов</div>
    <br>

    <v-data-table
      :headers="table.headers"
      :loading="table.loading"
      :items="table.items"
      :hide-default-footer="true"
      disable-pagination
      style="border: 1px solid rgba(0, 0, 0, 0.12)"
    >
      <template #[`item.comment`]="{ item }">
        <div>
          {{ item.comment }}
        </div>
        <div style="font-size: smaller;">
          <a class="text-decoration-none" href="#">{{ item.url }}</a>
        </div>
      </template>
      <template #[`item.last_run_at`]="{ item }">
        <template v-if="item.last_run_at">
          <div style="white-space: nowrap;">
            {{ formatDateTimeHumane(item.last_run_at) }}
          </div>
        </template>
        <template v-else>
          <div style="opacity: 0.5">Никогда</div>
        </template>
      </template>
      <template #[`item.interval`]="{ item }">
        <template v-if="item.interval">
          <div style="white-space: nowrap;">
          {{ parseCronInterval(item.interval) }}
          </div>
        </template>
        <template v-else>
          <div style="opacity: 0.5">Только вручную</div>
        </template>
      </template>
      <template #[`item.actions`]="{ item }">
        <div style="white-space: nowrap;">
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                  icon
                  class="mr-2"
                  @click="onClickLogs(item)"
                  v-on="on"
                  v-bind="attrs"
              >
                <v-icon>mdi-text-box-search-outline</v-icon>
              </v-btn>
            </template>
            <div>Показать историю и результаты</div>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                  icon
                  @click="onClickRun(item)"
                  v-on="on"
                  v-bind="attrs"
              >
                <v-icon>mdi-play</v-icon>
              </v-btn>
            </template>
            <div>Запустить принудительно</div>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog.shown" max-width="1200" ref="scheduler-logs-dialog">
      <v-card>
        <v-card-title>
          Журнал запусков
          <v-spacer />
          <v-btn
              icon
              @click="dialog.shown = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
              :headers="dialog.table.headers"
              :loading="dialog.table.loading"
              :items="dialog.table.items"
              :hide-default-footer="true"
              disable-pagination
          >
            <template #[`item.number`]="{ item }">
              <div style="white-space: nowrap;">
                {{ dialog.table.items.length - dialog.table.items.indexOf(item) }}
              </div>
            </template>
            <template #[`item.started_at`]="{ item }">
              <div style="white-space: nowrap;">
                {{ formatDateTimeHumane(item.started_at) }}
              </div>
            </template>
            <template #[`item.runtime_seconds`]="{ item }">
              <div style="white-space: nowrap;">
                <span style="opacity: 0.75;">({{ formatRuntime(item.runtime_seconds) }})</span>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn
              elevation="0"
              outlined
              color="primary"
              @click="dialog.shown = false"
              class="ml-auto"
              style="width: 320px;"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { callAPI } from "@/services/api";
import { formatDate, formatDateHumane, formatDateTimeHumane } from "@/utils/helpers";

export default {

  data() {
    return {
      table: {
        loading: true,
        headers: [
          {
            text: 'Время запуска',
            sortable: false,
            value: 'interval'
          },
          {
            text: 'Действие',
            sortable: false,
            value: 'comment'
          },
          {
            text: 'Последний запуск',
            sortable: false,
            value: 'last_run_at'
          },
          {
            text: '',
            sortable: false,
            value: 'actions',
            width: '0'
          }
        ],
        items: []
      },
      dialog: {
        shown: false,
        table: {
          loading: true,
          headers: [
            {
              text: '#',
              value: 'number',
              sortable: false
            },
            {
              text: 'Запущено',
              value: 'started_at',
              sortable: false
            },
            {
              text: 'Время работы скрипта',
              value: 'runtime_seconds',
              sortable: false
            }
          ],
          items: [],
        }
      }
    }
  },

  async beforeMount() {
    this.table.loading = true;
    this.table.items = await callAPI('GET', '/api/scheduler/getCrontab');
    this.table.loading = false;
  },

  methods: {
    formatDateTimeHumane,

    onClickRun(item) {
      this.$root.$confirm({
        text: 'Запустить выбранный скрипт?',
        ok: 'Запустить'
      }).then(async (callback) => {
        try {
          await callAPI('GET', item.url);
          await this.$store.dispatch("notify", `Скрипт запущен ${formatDate(new Date())}`);
        } catch (error) {
          this.alert({ dialogType: 'error', text: error });
        } finally {
          callback();
        }
      });
    },

    onClickLogs(item) {
      this.dialog.table.loading = true;
      this.dialog.table.items = [];
      this.dialog.shown = true;
      callAPI('POST', '/api/scheduler/getSchedulerTaskLogs', { key: item.key, page: 1, limit: 100 })
          .then((logs) => {
            this.dialog.table.items = logs.items;
          })
          .finally(() => {
            this.dialog.table.loading = false;
          })
    },

    parseCronInterval(value) {
      if (!value) {
        return 'Только вручную';
      }

      const [ min, hour, day, month, weekday ] = value.split(' ');

      if (min === '*' && hour === '*' && day === '*' && month === '*' && weekday === '*') {
        return 'Каждую минуту';
      }
      if (min === '0' && hour === '*' && day === '*' && month === '*' && weekday === '*') {
        return 'Каждый час';
      }
      if (day === '*' && month === '*' && weekday === '*') {
        let result = `Каждый день в ${((Number(hour)+3)%24).toString().padStart(2, 0)}:${min.padStart(2, 0)} (МСК)`;
        return result;
      }

      return value;
    },

    formatRuntime(seconds) {
      if (seconds < 60.0) {
        // seconds
        return `${seconds.toFixed(1)} сек`;
      } else {
        // minutes
        return `${Math.floor(seconds / 60.0)}:${Math.floor(seconds % 60.0).toString().padStart(2, '0')} мин`
      }
    }
  }
}
</script>

<style scoped>
</style>
