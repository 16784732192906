<template>
  <v-text-field
    outlined
    :label="$attrs.label || 'Поиск'"
    dense
    hide-details
    clearable
    append-icon="mdi-magnify"
    v-bind="$attrs"
    v-on="$listeners"
    v-model="search"
    @click:clear="clear"
    @click:append="doSearch"
    @input="debounceInput"
    @keydown.enter="doSearch"
  />
</template>

<script>
export default {

  props: [ 'value' ],

  data() {
    return {
      inputDebounceTimer: null,
      prevSearch: '',
      search: this.value
    }
  },

  methods: {
    clear() {
      this.search = '';
    },
    doSearch() {
      // cancel pending call
      clearTimeout(this.inputDebounceTimer);

      // search only if query is changed
      if (this.search !== this.prevSearch) {
        this.prevSearch = this.search;
        this.$emit('search', this.search);
        this.$emit('input', this.search);
      }
    },
    debounceInput() {
      // cancel pending call
      clearTimeout(this.inputDebounceTimer)

      // delay new call 500ms
      this.inputDebounceTimer = setTimeout(() => this.doSearch(), 500);
    }
  }
}
</script>