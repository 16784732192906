<template>
  <div>
    <v-overlay v-if="show && overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>

    <v-dialog
      v-if="!overlay"
      v-model="show"
      width="820"
      scrollable
      :persistent="loading"
    >
      <ValidationObserver
        ref="observer"
        v-slot="{ invalid }"
      >
        <v-card>
          <v-card-title>
            <v-row
              no-gutters
            >
              <v-col
                class="flex-grow-1 flex-shrink-0"
              >
                <span class="text-h6">
                  <template v-if="mode === 'edit'">{{ model.name || 'Редактирование товара' }}</template>
                  <template v-if="mode === 'new'">Новый товар</template>
                  <template v-if="mode === 'ownPosition'">Пользовательский товар</template>
                </span>
              </v-col>
              <v-col
                class="flex-grow-0 flex-shrink-1 pl-3"
              >
                <v-btn
                  icon
                  @click="show = false"
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="mb-0 dialog-body">
            <v-form
              ref="form"
              enctype="multipart/form-data"
            >
              <ValidationProvider
                v-slot="{ errors }"
                vid="name"
                name="Название товара"
                rules="required|max:300"
              >
                <v-text-field
                  v-model="model.name"
                  :error-messages="errors"
                  outlined
                  dense
                  placeholder="Название товара"
                  label="Название товара"
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                name="Производитель"
                vid="manufacturer_name"
                rules="required|max:300"
              >
                <v-text-field
                  v-model="model.manufacturer_name"
                  :error-messages="errors"
                  outlined
                  dense
                  placeholder="Производитель"
                  label="Производитель"
                  maxlength="100"
                  minlength="2"
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                name="Страна-производитель"
                vid="manufacturer_country"
                rules="required|max:40"
              >
                <v-text-field
                  v-model="model.manufacturer_country"
                  :error-messages="errors"
                  outlined
                  dense
                  placeholder="Страна-производитель"
                  label="Страна-производитель"
                  maxlength="100"
                  minlength="2"
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                name="Категория"
                vid="category_id"
                rules="required"
              >
                <category-select
                  :error-messages="errors"
                  :chain="categoriesPath"
                  :categories="categories"
                  :selected-category="model.category_id"
                  @change="changeCategory"
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                name="Единица измерения"
                vid="unit_id"
                rules="required"
              >
                <v-select
                  v-model="model.unit_id"
                  :error-messages="errors"
                  outlined
                  dense
                  placeholder="Единица измерения"
                  label="Единица измерения"
                  :items="units"
                  item-value="id"
                  item-text="full_name"
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                name="Артикул производителя"
                vid="sku"
                rules="required|max:40"
              >
                <v-text-field
                  v-model="model.sku"
                  :error-messages="errors"
                  outlined
                  dense
                  placeholder="Артикул производителя"
                  label="Артикул производителя"
                  maxlength="100"
                  minlength="2"
                />
              </ValidationProvider>

              <template
                v-if="mode === 'edit'"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Код товара"
                  vid="id"
                >
                  <v-text-field
                    v-model="model.id"
                    :error-messages="errors"
                    outlined
                    dense
                    placeholder="Код товара"
                    label="Код товара"
                    disabled
                  />
                </ValidationProvider>
              </template>

              <v-switch
                v-model="model.accreditation_required"
                :label=" model.accreditation_required ? 'Требуется аккредитация' : 'Аккредитация не требуется' "
              />

              <ValidationProvider
                v-slot="{ errors }"
                name="Краткое описание"
                vid="description_short"
                rules="required|max:300"
              >
                <v-textarea
                  v-model="model.description_short"
                  :error-messages="errors"
                  outlined
                  auto-grow
                  no-resize
                  label="Краткое описание"
                  placeholder="Краткое описание"
                  rows="3"
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                name="Описание"
                vid="description"
                rules="required|max:8000"
              >
                <v-textarea
                  v-model="model.description"
                  :error-messages="errors"
                  outlined
                  auto-grow
                  no-resize
                  label="Описание"
                  placeholder="Описание"
                />
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                name="Фотографии"
                rules="required"
                vid="images"
              >
                <v-file-input
                  :error-messages="errors"
                  outlined
                  dense
                  label="Фотографии"
                  multiple
                  prepend-icon="mdi-camera"
                  accept="image/png, image/jpeg, image/webp"
                  placeholder="Фотографии"
                  counter="8"
                  :counter-string="'' + images.length"
                  @change="onFileChange"
                />

                <div class="text-caption">
                  Допустимые форматы: .jpg, .png, .webp<br>
                  Максимальный размер одного файла: 8 мб
                </div>

                <v-row>
                  <v-col
                    v-for="image in images"
                    :key="image.key"
                    cols="3"
                  >
                    <v-hover v-slot="{ hover }">
                      <div>
                        <v-img
                          contain
                          aspect-ratio="1"
                          :src="image.preview"
                          alt="Картинка"
                          :style="{ 'opacity': image.loading ? 0.5 : 1.0 }"
                        >
                          <div
                            v-if="image.loading"
                            class="d-flex justify-center flex-column align-center fill-height"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            />
                          </div>
                          <div
                            v-if="!image.loading && hover"
                            class="d-flex justify-center flex-column align-center fill-height"
                          >
                            <v-btn
                              color="primary"
                              class="mb-3"
                              @click="showImage(image)"
                            >
                              Показать
                            </v-btn>

                            <v-btn
                              color="error"
                              @click="removeImage(image)"
                            >
                              Удалить
                            </v-btn>
                          </div>
                        </v-img>
                      </div>
                    </v-hover>
                  </v-col>
                </v-row>
                <div
                  v-if="images.length === 0"
                  class="no-images"
                >
                  Изображения не выбраны
                </div>
              </ValidationProvider>
            </v-form>
          </v-card-text>

          <v-card-actions v-if="mode !== 'ownPosition'">
            <v-container>
              <v-row>
                <v-col>
                  <v-btn
                    color="primary"
                    outlined
                    block
                    @click="show = false"
                  >
                    Закрыть
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    color="primary"
                    type="submit"
                    block
                    elevation="0"
                    :loading="loading"
                    :disabled="invalid || images.length <= 0"
                    @click="submit"
                  >
                    <template v-if="mode === 'new'">
                      Добавить
                    </template>

                    <template v-if="mode === 'edit'">
                      Применить
                    </template>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
          <v-card-actions v-else>
            <v-container>
              <v-row>
                <v-col>
                  <v-btn
                    color="error"
                    block
                    elevation="0"
                    @click="setDoubleDialog = true"
                  >
                    Признать дублем
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn
                    color="success"
                    type="submit"
                    block
                    elevation="0"
                    :loading="loading"
                    :disabled="invalid || model.unit_id === null || model.category_id === null || images.length <= 0"
                    @click="submit"
                  >
                    Добавить товар в каталог
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>

    <image-dialog
      v-model="imageDialog"
      :image="currentImage"
    />

    <error-dialog v-model="errorDialog" />

    <success-dialog v-model="successDialog" />

    <product-duplicate-dialog
      v-model="setDoubleDialog"
      :product-id="itemId"
      @done="show = false"
    />
  </div>
</template>

<script>
import {
  getProduct,
  listCategories,
  getUnits,
  createProduct,
  uploadProductImage,
  editProduct,
  approveUserProduct
} from "@/services/api";

import CategorySelect from "@/components/CategorySelect";
import ImageDialog from "@/components/Catalog/Products/ImageDialog";
import ErrorDialog from "@/components/Dialogs/ErrorDialog";
import SuccessDialog from "@/components/Dialogs/SuccessDialog";
import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import { required, max, min } from "vee-validate/dist/rules";
import ProductDuplicateDialog from "@/components/Catalog/OwnPostion/ProductDuplicateDialog.vue";

extend('required', required);
extend('max', max);
extend('min', min);

export default {
  name: "EditItemDialog",
  components: {
    ProductDuplicateDialog,
    ImageDialog,
    CategorySelect,
    ErrorDialog,
    SuccessDialog,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    value: Boolean,
    itemId: {
      type: Number,
      default: 0
    },
    mode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // dialog: false
      model: {
        unit_id: null,
        category_id: null,
        manufacturer_name: null,
        manufacturer_country: null,
        unit_quantity: null,
        id: null,
        sku: null,
        images: [],
        accreditation_required: false
      },
      setDoubleDialog: false,
      currentImage: '',
      imageDialog: false,
      item: {},
      carousel: "",
      categories: [],
      units: [],
      images: [],
      loading: false,
      categoriesPath: [],
      errorDialog: false,
      successDialog: false,
      categoriesLoading: false,
      initialLoading: false,
      productLoading: false
    }
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.clear();
        this.$emit('input', value)
        this.$emit('change');
      }
    },

    overlay() {
      return this.initialLoading || this.productLoading;
    }
  },

  watch: {
    async value(isOpen) {
      if (!isOpen) {
        this.clear();
      } else {
        this.productLoading = true;
        if (this.itemId) {
          await this.loadItem(this.itemId);
        }
        this.units = await getUnits()
        this.categories = await listCategories({ tag: 'operator' });
        this.productLoading = false;
      }
    }
  },

  methods: {

    changeCategory(id) {
      this.model.category_id = id
    },

    /**
     * Remove item from the array
     *
     * @param {Array} array The array to remove the item from
     * @param item An item to be removed
     */
    deleteElementFromArray(array, item) {
      if (!Array.isArray(array)) {
        return;
      }

      const index = array.findIndex((a) => a === item);
      if (index !== -1) {
        array.splice(index, 1);
      }
    },

    removeImage(image) {
      this.deleteElementFromArray(this.images, image);
    },

    addImage(key, original, preview, loading) {
      const image = { key, original, preview, loading };
      this.images.push(image);
      return image;
    },

    async submit() {
      try {
        this.loading = true;

        if (this.mode === 'new') {
          await createProduct({
            ...this.model,
            images: this.images.map(image => image.key),
            documents: [],
          })
        } else if (this.mode === 'edit') {
          await editProduct({
            id: this.itemId,
            ...this.model,
            images: this.images.map(image => image.key),
            documents: [],
          })

        } else {
          await approveUserProduct(
            {
              "product_id": this.itemId,
              "category_id": this.model.category_id,
              "sku": this.model.sku,
              "name": this.model.name,
              "description": this.model.description,
              "unit_id": this.model.unit_id,
              "manufacturer_name": this.model.manufacturer_name,
              "manufacturer_country": this.model.manufacturer_country,
              "images": this.images.map(image => image.key),
              "accreditation_required": this.model.accreditation_required
            }
          )
        }

        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Товар сохранен',
          duration: 5000
        });

        this.show = false;
      } catch (error) {
        if (error.fields) {
          this.$refs.observer.setErrors(error.fields);
        }
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Не удалось сохранить товар',
          duration: 5000
        });
      } finally {
        this.loading = false
      }
    },

    showImage(image) {
      this.currentImage = image.original
      this.imageDialog = true
    },

    async onFileChange(files) {
      // check if no files selected
      if (files.length === 0) {
        return;
      }

      // check limits
      if ((this.images.length + files.length) > 8) {
        this.$notify({
          type: 'warn',
          title: 'Ошибка',
          text: 'Превышен лимит изображений для товара',
          duration: 5000
        });
        return;
      }

      for (const file of files) {
        const randomStorageKey = (10000 + (Math.random() * 80000)).toString();
        const url = URL.createObjectURL(file);
        const image = this.addImage(randomStorageKey, url, url, true);

        uploadProductImage(file)
            .then((uploadKey) => {
              image.key = uploadKey;
              image.loading = false;
            })
            .catch(() => {
              this.removeImage(image);
              this.$notify({
                type: 'error',
                title: 'Ошибка',
                text: 'Не удалось загрузить изображение',
                duration: 5000
              });
            })
      }
    },

    async loadItem(productId) {

      this.model = await getProduct({
        product_id: productId
      });
      this.categoriesPath = this.model.category_path.map(item => Number(item.id))
      this.model.category_id = Number(this.model.category_id)
      this.model.unit_id = Number(this.model.unit_id)
      if (!this.model.accreditation_required) {
        this.model.accreditation_required = false
      }

      this.model.images.map(image => this.addImage(image.key, image.original, image.sm, false));
    },

    clear() {
      this.model.name = null
      this.model.category_id = null
      this.model.sku = null
      this.model.description = null
      this.model.description_short = null
      this.model.manufacturer_name = null
      this.model.manufacturer_country = null
      this.model.unit_id = null
      this.model.images = []
      this.model.accreditation_required = false

      this.categoriesPath = [];

      this.images = [];
    }
  }
}
</script>

<style scoped>
.image-preview-hover {
  background-color: #000000;
  opacity: 0.5;
}

.no-images {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
