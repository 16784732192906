<template>
  <v-dialog
    v-model="show"
    max-width="657"
  >
    <v-card>
      <v-card-title>
        <v-spacer />

        <v-btn
          icon
          @click="show = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <v-icon
              size="72px"
              color="primary"
            >
              mdi-minus-circle-outline
            </v-icon>
          </v-col>
          <v-col
            cols="12"
            class="text-center"
          >
            <h2 style="margin-bottom: 0.5rem;">
              Вы уверены?
            </h2>
            Вы действительно хотите деактивировать товар?
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <div class="mx-auto">
          <v-btn
            outlined
            color="primary"
            class=" px-10 mb-5 mr-2"
            @click="show = false"
          >
            Отмена
          </v-btn>
          <v-btn
            elevation="0"
            color="primary"
            class="px-10 mb-5 ml-2"
            @click="$emit('ok')"
          >
            Да
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteDialog",
  props: {
    value: Boolean,
    mode: {
      type: String,
      default: ''
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped>

</style>
