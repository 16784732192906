<template>
  <v-dialog
    v-model="show"
    max-width="657"
  >
    <v-card>
      <v-card-title />
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <v-icon
              size="72px"
              color="primary"
            >
              mdi-{{ currentIcon }}
            </v-icon>
          </v-col>
          <v-col
            cols="12"
            class="text-center"
          >
            <h2 style="margin-bottom: 0.5rem;">
              <template v-if="title">
                {{ title }}
              </template>
              <template v-else>
                <template v-if="type === 'confirmation'">
                  Вы уверены?
                </template>
                <template v-if="type === 'error'">
                  Ошибка
                </template>
                <template v-if="type === 'success'">
                  Успех
                </template>
              </template>
            </h2>
            {{ text }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <div class="mx-auto mb-5">
          <v-btn
            v-if="!okOnly"
            color="primary"
            outlined
            class="px-10 mr-3"
            @click="onCancel"
          >
            Отмена
          </v-btn>
          <v-btn
            elevation="0"
            color="primary"
            class="px-10"
            @click="onOk"
          >
            ОК
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CommonAlertDialog",
  props: {
    // Возможные типы диалогового окна:
    // error
    // confirmation
    // success
    type: {
      type: String,
      default: ''
    },
    value: Boolean,
    okOnly: Boolean,
    text: {
      default: '',
      type: String
    },
    title: {
      default: '',
      type: String
    },
    onOkCallback: {
      type: Function,
      default: () => {}
    },
    onCancelCallback: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    currentIcon() {
      switch (this.type) {
        case 'error': return 'alert-circle'
        case 'alert': return 'alert'
        case 'success': return 'check-bold'
        default: return 'alert'
      }
    }
  },
  methods: {
    onOk() {
      this.show = false
      if (this.onOkCallback) {
        this.onOkCallback()
      }
    },
    onCancel() {
      this.show = false
      if (this.onCancelCallback) {
        this.onCancelCallback()
      }
    }
  }
}
</script>

<style scoped>

</style>