<template>
  <v-dialog v-model="visible" :persistent="submitting" max-width="500">
    <div style="text-align: center; height: 40px;" v-if="loading">
      <v-progress-circular indeterminate color="white"/>
    </div>

    <v-card v-else>
      <v-card-title>
        <span>Добавление организации</span>
        <v-spacer/>
        <v-btn icon @click="hide()" :disabled="submitting"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text>
        <v-form :disabled="submitting" @submit.prevent>
<!--          <v-text-field label="Название или ИНН организации" outlined dense v-model.trim="search"></v-text-field>-->

          <v-autocomplete
              v-model="search.value"
              :items="search.items"
              :loading="search.loading"
              :search-input.sync="search.input"
              item-text="name"
              item-value="inn"
              label="Название или ИНН организации"
              placeholder="Название или ИНН организации"
              prepend-inner-icon="mdi-database-search"
              outlined
              dense
              return-object
              hide-no-data
              no-filter
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  Ничего не найдено
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
                <v-list-item-subtitle v-text="item.inn"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-form>

        <v-list v-if="search.value" outlined>
          <v-list-item dense>
            <v-list-item-content>
              <v-list-item-title>{{ search.value.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ search.value.inn }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="grey lighten-5">
        <v-row>
          <v-col cols="6">
            <v-btn elevation="0" color="normal" outlined text large block :disabled="submitting" @click="hide()">Отмена</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn elevation="0" color="primary" large block :loading="submitting" @click="submit()" :disabled="!search.value">Добавить</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { callAPI } from "@/services/api";

export default {

  inject: [
    'alert'
  ],

  data() {
    return {
      search: {
        value: null,
        input: '',
        items: [],
        loading: false
      },
      visible: false,
      loading: false,
      submitting: false,
      supplierGroupId: null
    }
  },

  watch: {
    async 'search.input'(value) {
      // cancel pending call
      clearTimeout(this.inputDebounceTimer)
      // delay new call 500ms
      this.inputDebounceTimer = setTimeout(() => this.doSearch(), 500);
    }
  },

  methods: {
    show(supplierGroupId) {
      this.reset();
      this.supplierGroupId = supplierGroupId;
      this.visible = true;
    },

    hide() {
      this.visible = false;
    },

    reset() {
      this.search.value = null;
      this.search.input = '';
      this.search.items = [];
      this.search.supplierGroupId = null;
    },

    async doSearch() {
      this.search.loading = true;
      try {
        this.search.items = await callAPI('POST', '/api/operator/findOrganization', { search: this.search.input });
      } catch (error) {
        console.log(error);
      } finally {
        this.search.loading = false;
      }
    },

    async submit() {
      this.submitting = true;
      try {
        await callAPI('POST', '/api/operator/addSupplierToGroup', {
          group_id: this.supplierGroupId,
          organization_inn: this.search.value.inn
        })
        this.alert({ dialogType: 'success', text: 'Поставщик добавлен в группу' });
        this.hide();
        this.$emit('refresh');
      } catch (error) {
        this.alert({ dialogType: 'error', text: error });
      } finally {
        this.submitting = false;
      }
    }
  }
}
</script>