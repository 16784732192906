<template>
  <v-dialog
    v-model="show"
    width="520"
  >
    <v-card>
      <v-card-title class="d-flex">
        <span class="text-h5">Фильтры</span>
        <v-spacer />
        <v-btn
          icon
          @click="show = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mb-0 dialog-body">
        <v-select
          v-model="selectedStatus"
          :items="statuses"
          item-text="value"
          item-value="id"
          label="Выберите какие статусы отображать:"
          placeholder="Выберите какие статусы отображать:"
          outlined
          dense
        />
      </v-card-text>
      <v-card-actions>
        <v-container class="pa-0">
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                outlined
                block
                @click="resetFilter"
              >
                Сбросить
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                type="submit"
                block
                elevation="0"
                @click="applyFilter"
              >
                Применить
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: "AccreditationRequestsFiltersDialog",

  props: {
    value: Boolean
  },

  data() {
    return {
      // dialog: false,
      statuses: [
        { id: 'draft', value: 'Черновик' },
        { id: 'pending', value: 'На рассмотрении' },
        { id: 'approved', value: 'Одобрена' },
        { id: 'rejected', value: 'Отклонена' }
      ],
      selectedStatus: null
    }
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {

    applyFilter() {
      const filters = {};
      if (this.selectedStatus) {
        filters.status = this.selectedStatus;
      }
      this.$emit('apply', filters);
    },
    resetFilter() {
      this.selectedStatus = null;
      this.$emit('reset');
    }
  }
}
</script>


<style scoped>

</style>
