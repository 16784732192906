<template>
  <div>
    <div class="d-flex justify-end">
      <v-btn
        elevation="0"
        color="primary"
        class="mb-5"
        outlined
        @click="addCategoryClick"
      >
        <v-icon>mdi-plus</v-icon>
        Добавить категорию
      </v-btn>

      <v-btn
        v-if="!editCategoryModeOn"
        elevation="0"
        color="primary"
        class="mb-5 ml-2"
        outlined
        @click="editCategoriesOrderClick"
      >
        <v-icon>mdi-pencil</v-icon>
        Редактировать порядок категорий
      </v-btn>

      <v-btn
        v-else
        elevation="0"
        color="primary"
        class="mb-5 ml-2"
        outlined
        :disabled="saveCategoriesOrderLoading"
        :loading="saveCategoriesOrderLoading"
        @click="saveCategoriesOrderClick"
      >
        <v-icon>mdi-floppy</v-icon>
        Сохранить порядок категорий
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="1000"
      loading-text="Категории загружаются..."
      :loading="loading"
      style="border: 1px solid rgba(0, 0, 0, 0.12)"
      :item-class="rowClass"
      :hide-default-header="loading"
      hide-default-footer
    >
      <template #[`item.id`]="{ item }">
        <span style="font-weight: 500; font-size: 1em;">{{ item.id }}</span>
      </template>
      <template #[`item.name`]="{ item }">
        <span
          :style="{ marginLeft: getDepthPadding(item.depth), opacity: getDepthOpacityLevel(item.depth) }"
          :class="'fw-' + item.depth"
        >{{ item.name }}</span>
      </template>
      <template #[`item.actions`]="{ item }">
        <div class="d-flex justify-end">
          <template v-if="editCategoryModeOn">
            <v-btn
              icon
              @click="moveCategoryDown(item)"
            >
              <v-icon>
                mdi-arrow-down
              </v-icon>
            </v-btn>
            <v-btn
              icon
              @click="moveCategoryUp(item)"
            >
              <v-icon>
                mdi-arrow-up
              </v-icon>
            </v-btn>
          </template>

          <v-btn
            icon
            @click="showCategoryDialogInEditMode(item.id, item)"
          >
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>
        </div>
      </template>

      <template #[`item.icon_url`]="{ item }">
        <v-img
          v-if="item.icon_url"
          width="48"
          :src="item.icon_url"
          alt="#"
        />
      </template>
    </v-data-table>
    <category-dialog
      v-model="categoryDialog"
      :category="selectedCategory"
      :category-id="selectedCategoryId"
      :mode="categoryDialogMode"
      @done="listCategories"
    />
    <success-dialog v-model="successDialog" />
    <delete-dialog v-model="deleteDialog" />
  </div>
</template>

<script>
import CategoryDialog from "@/components/Catalog/Categories/CategoryDialog";
import DeleteDialog from "@/components/Dialogs/DeleteDialog";
import SuccessDialog from "@/components/Dialogs/SuccessDialog";
import {deleteCategory, listCategories, orderCategories} from "@/services/api";
import {buildCategoriesTree} from "@/utils/helpers";

export default {
  name: "CategoriesTab",

  components: {
    CategoryDialog,
    DeleteDialog,
    SuccessDialog
  },

  data() {
    return {
      loading: false,
      saveCategoriesOrderLoading: false,
      editCategoryModeOn: false,
      successDialog: false,
      headers: [
        {
          text: 'ID',
          align: 'center',
          sortable: false,
          value: 'id',
          width: 85
        },
        {
          text: 'Иконка',
          align: 'center',
          sortable: false,
          value: 'icon_url',
          width: 85
        },
        {
          text: 'Название категории',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Действия',
          align: 'end',
          sortable: false,
          value: 'actions',
        },
      ],
      items: [],
      categoryDialog: false,
      categoryDialogMode: "new",
      deleteDialog: false,
      selectedCategoryId: null,
      selectedCategory: {}
    }
  },

  async beforeMount() {
    await this.listCategories()
  },

  methods: {
    async deleteCategory (id) {
      try {
        await deleteCategory({id})
        this.items = this.items.filter(i => i.id !== id);

        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Категория успешно удалена',
          duration: 5000
        });
      } catch (error) {
        console.error(error)

        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Не удалось удалить категорию',
          duration: 5000
        });
      }
    },

    async saveCategoriesOrderClick() {

      let categories = []

      for (const item of this.items) {
        categories.push(item.id)
      }

      this.saveCategoriesOrderLoading = true
      await orderCategories({ categories })
      this.saveCategoriesOrderLoading = false

      this.editCategoryModeOn = false

      this.$notify({
        type: 'success',
        title: 'Успех',
        text: 'Порядок категорий успешно сохранен',
        duration: 5000
      });
    },

    editCategoriesOrderClick() {
      this.editCategoryModeOn = true
    },

    moveCategoryDown(item) {
      // let itemIndex = this.items.indexOf(this.items.filter(item => item.id === id));

      // [this.items[itemIndex], this.items[itemIndex + 1]] = [this.items[itemIndex + 1], this.items[itemIndex]]

      let itemIndex = this.items.findIndex(val => val === item)
      let tmp = this.items[itemIndex]
      let nextItemIndex;

      for (let i = itemIndex + 1; i < this.items.length; i++) {
        let currentItem = this.items[i]
        if (tmp.depth === currentItem.depth) {
          // eslint-disable-next-line no-unused-vars
          nextItemIndex = i
          break
        }
      }
      if (nextItemIndex !== undefined) {
        // this.items[itemIndex] = this.items[nextItemIndex]
        // this.items[nextItemIndex] = tmp


        let tmpIndex = tmp.index

        this.items[itemIndex].index = this.items[nextItemIndex].index
        this.items[nextItemIndex].index = tmpIndex

        this.rebuildCategoriesTree(this.items);

      }
    },

    moveCategoryUp(item) {
      // let itemIndex = this.items.indexOf(this.items.filter(item => item.id === id));

      // [this.items[itemIndex], this.items[itemIndex + 1]] = [this.items[itemIndex + 1], this.items[itemIndex]]

      let itemIndex = this.items.findIndex(val => val === item)
      let tmp = this.items[itemIndex]
      let nextItemIndex;

      for (let i = itemIndex - 1; i >= 0; i--) {
        let currentItem = this.items[i]
        if (tmp.depth === currentItem.depth) {
          // eslint-disable-next-line no-unused-vars
          nextItemIndex = i
          break
        }
      }
      if (nextItemIndex !== undefined) {
        // this.items[itemIndex] = this.items[nextItemIndex]
        // this.items[nextItemIndex] = tmp


        let tmpIndex = tmp.index

        this.items[itemIndex].index = this.items[nextItemIndex].index
        this.items[nextItemIndex].index = tmpIndex

        this.rebuildCategoriesTree(this.items)

      }
    },

    addCategoryClick() {
      this.categoryDialog = true
      this.categoryDialogMode = 'new'

    },
    rowClass(item) {
      const depth = item.depth + 4;
      if (item.depth <= 1) {
        return `grey lighten-${depth}`
      } else {
        return 'white'
      }
    },

    showCategoryDialogInEditMode(selectedCategoryId, category) {
      this.categoryDialog = true
      this.categoryDialogMode = "edit"
      this.selectedCategoryId = selectedCategoryId
      this.selectedCategory = category
    },

    getCategoriesByDepth(depth) {
      return this.items.filter(item => item.depth === depth)
    },

    rebuildCategoriesTree() {
      let items = []
      let rootCategories = this.getCategoriesByDepth(0).sort((a, b) => {
        return a.index - b.index
      })

      let addCategories = (category) => {
        items.push(category)
        if (category.children) {
          let childrens = category.children.sort((a, b) => {
            return a.index - b.index
          })
          for (let children of childrens) {
            addCategories(children)
          }
        }
      }

      for (let rootCategory of rootCategories) {
        addCategories(rootCategory)
      }
      this.items = items

      for (let i = 0; i < this.items.length; i++) {
        this.items[i].index = i
      }
    },

    async listCategories() {
      this.loading = true
      // this.items = await listCategories({ role: 'supplier' });
      this.items = await listCategories()
      this.items = this.buildCategoriesTree(this.items)

      this.rebuildCategoriesTree()

      this.loading = false

    },

    getDepthPadding(depth) {
      return `${depth * 1.75}em`;
    },

    getDepthOpacityLevel(depth) {
      switch (depth) {
        case 0:
          return 1;
        case 1:
          return 1;
        case 2:
          return 0.8;
        case 3:
          return 0.6;
      }
      return 0.6;
    },

    buildCategoriesTree
  }
}
</script>

<style scoped lang="scss">
table tr:hover {
  background: #eeeeee !important;
}

.fw-0 {
  font-weight: 500;
}

.fw-1 {
  font-weight: normal;
}
</style>
