<template>
  <v-snackbar
    v-model="show"
    light
    color="grey lighten-4"
  >
    <span>{{ text }}</span>

    <template #action="{ attrs }">
      <v-btn
        text
        icon
        v-bind="attrs"
        @click="show = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: this.value,
      text: ''
    }
  },
  created() {
    this.$store.subscribe((mutation) => {
      if (mutation.type === "SET_TOAST") {
        this.text = mutation.payload;
        this.show = true;
      }
    });
  }
}
</script>