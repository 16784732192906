<template>
  <v-dialog
    v-model="show"
    width="520"
    :persistent="loading"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">Загрузка из ZIP-архива</span>

        <v-spacer />

        <v-btn
          icon
          @click="show = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mb-0 dialog-body pb-0">
        <v-form>
          <v-file-input
            v-model="file"
            outlined
            dense
            label="ZIP-архив"
            prepend-icon="mdi-folder"
            :disabled="loading"
            placeholder="ZIP-архив"
            accept=".zip"
          />
        </v-form>
        <v-progress-linear
          v-if="loading"
          :value="progress"
          :indeterminate="progress === 0"
        />
        <span
          v-if="parsing"
          class="mt-4"
        >
          Происходит выгрузка позиций, это может занять несколько минут
        </span>
      </v-card-text>
      <v-card-actions>
        <v-container class="pa-0">
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                outlined
                block
                @click="show = false"
              >
                Отменить
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                type="submit"
                block
                elevation="0"
                :disabled="loading"
                :loading="loading"
                @click="upload"
              >
                Загрузить
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>

    <success-dialog
      v-model="successDialog"
      @callback="$emit('done')"
    />

    <error-dialog
      v-model="errorDialog"
      :title="errorTitle"
      :text="errorText"
      :no-icon="errorDialogNoIcon"
    />
  </v-dialog>
</template>

<script>
import { callAPI, getUploadUrl } from "@/services/api";
import axios from "axios";

import SuccessDialog from "@/components/Dialogs/SuccessDialog";
import ErrorDialog from "@/components/Dialogs/ErrorDialog";

export default {
  name: "ItemZipUploadDialog",

  components: {
    SuccessDialog,
    ErrorDialog
  },

  props: {
    value: Boolean
  },

  data() {
    return {
      uploadUrl: null,
      file: null,
      progress: 0,
      loading: false,
      parsing: false,
      errorDialog: false,
      errorDialogNoIcon: false,
      successDialog: false,
      errorText: '',
      errorTitle: ''
    }
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    async upload() {
      try {
        this.loading = true

        let data = await getUploadUrl()
        let presignedUrl = data.presigned_url
        let storagePath = data.storage_path

        await axios.put(presignedUrl, this.file, {
          onUploadProgress: progressEvent => {
            this.progress = (progressEvent.loaded / progressEvent.total) * 100
          }
        })

        const result = await callAPI('POST', '/api/operator/importProductsFromZIP', { storage_path: storagePath });

        const job_id = result.job_id;

        this.parsing = true;
        this.progress = 0;

        try {
          const { result: job_result } = await this.watchJob(job_id, (progress) => this.progress = progress);
          this.show = false;
          if (job_result) {
            this.errorText = '<div style="margin-top: 2rem; text-align: left;">';
            Object.keys(job_result).map(i => {
              this.errorText += `<div>• ${job_result[i]}</div>`;
            })
            this.errorText += `</div>`;
            this.errorText += `<br><div style="color: #6dbb6d; font-weight: 600;">Все уникальные товары добавлены в каталог</div>`;
            this.errorDialogNoIcon = true;
            this.errorTitle = 'Результат импорта';
            this.errorDialog = true;
          } else {
            this.successDialog = true;
          }
          this.$emit('done');
        } catch (error) {
          this.errorText = error.message;
          this.errorDialogNoIcon = false;
          this.errorTitle = 'Ошибка';
          this.errorDialog = true;
        } finally {
          this.parsing = false;
          this.show = false;
        }
      } catch (error) {
        this.errorText = error.message;
        this.errorDialogNoIcon = false;
        this.errorTitle = 'Ошибка';
        this.errorDialog = true;
      } finally {
        this.loading = false
        this.parsing = false
      }
    },

    async watchJob(job_id, on_progress) {
      return new Promise((resolve, reject) => {
        const update = async () => {
          const result = await callAPI('POST', '/api/user/getJobStatus', { 'job_id': job_id });
          switch (result.status) {
            case 'running':
              on_progress(result.percent);
              break;
            case 'completed':
              return resolve(result.result);
            case 'failed':
              return reject(result.result.error);
          }
          window.setTimeout(() => update(), 4000);
        }
        update();
      })
    }
  }
}
</script>

<style scoped>

</style>
