<template>
  <v-app>
    <v-main class="authorization fill-height">
      <slot />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "AuthorizationLayout"
}
</script>

<style scoped>
.authorization {
  background: #F4F5F7;
}
</style>