import { render, staticRenderFns } from "./OwnPositionTab.vue?vue&type=template&id=6bcb6220&scoped=true&"
import script from "./OwnPositionTab.vue?vue&type=script&lang=js&"
export * from "./OwnPositionTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bcb6220",
  null
  
)

export default component.exports