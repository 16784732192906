import Axios from 'axios'
import { store } from '@/store'

export const BACKEND_BASE_URL = process.env.VUE_APP_BACKEND_BASE_URL;
export const FRONTEND_BASE_URL = process.env.VUE_APP_FRONTEND_BASE_URL;

export const axios = Axios.create({
    baseURL: BACKEND_BASE_URL
})

store.state.auth.token = localStorage.getItem('token')

axios.interceptors.request.use(function(config) {
    if(store.state.auth.token) {
        config.headers.common['Authorization'] = `Bearer ${store.state.auth.token}`;
    }

    return config;

}, function(error) {
    return Promise.reject(error)
});

axios.interceptors.response.use(function(response) {

    return response;

}, function(error) {
    // log out on 401 status code
    if (error.response && error.response.status === 401) {
        store.dispatch('logout');
    }

    return Promise.reject(error);
})

const API_ERR_GENERAL = -1;
const API_ERR_VALIDATION = -2;
const API_ERR_NOT_FOUND = -3;

function handleAPIError(error) {

    let response = error.response;
    if (!response) {
        return error;
    }

    if (response.status === 500) {
        store.dispatch("notify", "Ошибка сервера")
        return error;
    }

    if (response.data) {
        if (typeof response.data !== "object") {
            store.dispatch("notify", "Некорректный ответ сервера");
            return error;
        }

        const backend_error = response.data.error;
        if (backend_error) {
            switch (backend_error.code) {
                case API_ERR_VALIDATION:
                    let errors = backend_error.details.fields
                    if (errors) {
                        // add "fields" property to error
                        error.fields = errors;

                        error.message = errors[Object.keys(errors)[0]]; // set first message
                    }
                    break;

                default:
                    error.message = backend_error.message;

                    store.dispatch("notify", backend_error.message);
                    break;
            }
        }
    }

    return error;
}

export function callAPI(method, url, data = {}) {
    return new Promise((resolve, reject) => {
        return axios({ method, url, data })
            .then((response) => {
                resolve(response.data.result);
            })
            .catch((axiosError) => {
                reject(handleAPIError(axiosError));
            });
    });
}

export function listCategories(payload) {
    return callAPI('POST', '/api/user/listCategories', payload);
}

export function listProducts(payload) {
    return callAPI('POST', '/api/operator/listProducts', payload);
}

export function getProduct(payload) {
    return callAPI('POST', '/api/user/getProduct', payload);
}

export function getUnits() {
    return callAPI('GET', '/api/user/getUnits');
}

export function createCategory(payload) {
    return callAPI('POST', '/api/operator/createCategory', payload);
}

export function editCategory(payload) {
    return callAPI('POST', '/api/operator/editCategory', payload);
}

export function deleteCategory(payload) {
    return callAPI('POST', '/api/operator/deleteCategory', payload);
}

export function login(payload) {
    return callAPI('POST', '/api/operator/login', payload);
}

export function logout(payload) {
    return callAPI('POST', '/api/operator/logout', payload);
}

export function listWalletRequests(payload) {
    return callAPI('POST', '/api/operator/listWalletRequests', payload);
}

export function approveWithdrawalRequest(payload) {
    return callAPI('POST', '/api/operator/approveWithdrawalRequest', payload);
}

export function rejectWithdrawalRequest(payload) {
    return callAPI('POST', '/api/operator/rejectWithdrawalRequest', payload);
}

export function listSettings(payload) {
    return callAPI('POST', '/api/operator/listSettings', payload);
}

export function setSettings(payload) {
    return callAPI('POST', '/api/operator/setSettings', payload);
}

export function approveDepositRequest(payload) {
    return callAPI('POST', '/api/operator/approveDepositRequest', payload);
}

export function rejectDepositRequest(payload) {
    return callAPI('POST', '/api/operator/rejectDepositRequest', payload);
}

export function getUploadUrl(payload) {
    return callAPI('POST', '/api/operator/getUploadURL', payload);
}

export function archiveProduct(payload) {
    return callAPI('POST', '/api/operator/archiveProduct', payload);
}

export function approveUserProduct(payload) {
    return callAPI('POST', '/api/operator/approveUserProduct', payload);
}

export function rejectUserProduct(payload) {
    return callAPI('POST', '/api/operator/rejectUserProduct', payload);
}

export function createProduct(payload) {
    return callAPI('POST', '/api/operator/createProduct', payload);
}

export function editProduct(payload) {
    return callAPI('POST', '/api/operator/editProduct', payload);
}

export function orderCategories(payload) {
    return callAPI('POST', '/api/operator/orderCategories', payload);
}

export function importProductsFromZIP(payload) {
    return callAPI('POST', '/api/operator/importProductsFromZIP', payload);
}

export function uploadProductImage(payload) {
    let formData = new FormData()
    formData.append('file', payload)

    return callAPI('POST', '/api/operator/uploadProductImage', formData);
}

export function getOrganizationsList (payload = {page: 0, limit: 0}) {
    return callAPI('POST', '/api/operator/listOrganizations', payload);
}

export function getOrganization (payload) {
    return callAPI('POST', '/api/operator/getOrganization', payload);
}

export function getOrganizationEmployees (payload) {
    return callAPI('POST', '/api/operator/listOrganizationEmployees', payload);
}

export function listUsers (payload = {page: 0, limit: 0}) {
    return callAPI('POST', '/api/operator/listUsers', payload);
}

export function setOrganizationOwner (payload) {
    return callAPI('POST', '/api/operator/setOrganizationOwner', payload);
}

export function listDeactivationRequests (payload) {
    return callAPI('POST', '/api/operator/listDeactivationRequests', payload);
}

export function deleteOrganization (payload) {
    return callAPI('POST', '/api/operator/deleteOrganization', payload);
}

export function declineDeactivationRequest (payload) {
    return callAPI('POST', '/api/operator/declineDeactivationRequest', payload);
}

export function listAccreditationRequests (payload) {
    return callAPI('POST', '/api/operator/listAccreditationRequests', payload);
}

export function approveTradeAdmissionRequest (payload) {
    return callAPI('POST', '/api/operator/approveTradeAdmissionRequest', payload);
}

export function rejectTradeAdmissionRequest (payload) {
    return callAPI('POST', '/api/operator/rejectTradeAdmissionRequest', payload);
}

export function approveAccreditationRequest (payload) {
    return callAPI('POST', '/api/operator/approveAccreditationRequest', payload);
}

export function rejectAccreditationRequest (payload) {
    return callAPI('POST', '/api/operator/rejectAccreditationRequest', payload);
}

export function detachOrganization (payload) {
    return callAPI('POST', '/api/operator/detachOrganization', payload);
}

export function getAccreditationRequest (payload) {
    return callAPI('POST', '/api/operator/getAccreditationRequest', payload);
}

export function addOrganizationBonuses (payload) {
    return callAPI('POST', '/api/operator/addOrganizationBonuses', payload);
}

export function listOrganizationGroupChangeRequests(payload) {
  return callAPI("POST", "/api/operator/listOrganizationGroupChangeRequests", payload);
}

export function approveOrganizationGroupChangeRequest(payload) {
  return callAPI("POST", "/api/operator/approveOrganizationGroupChangeRequest", payload);
}

export function rejectOrganizationGroupChangeRequest(payload) {
  return callAPI("POST", "/api/operator/rejectOrganizationGroupChangeRequest", payload);
}

export function countOrganizationGroupChangeRequests() {
  return callAPI("POST", "/api/operator/countOrganizationGroupChangeRequests");
}

export function listUserOrganizationGroups(payload) {
  return callAPI("POST", "/api/operator/listUserOrganizationGroups", payload);
}
