import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ru from "vuetify/lib/locale/ru";

Vue.use(Vuetify);

export default new Vuetify({
    options: {
        customProperties: true
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: "#1B7DC9",
                secondary: "#8C8C8C",
                accent: "#82B1FF",
                error: "#FF4848",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#D4AA3D",
                danger: "#FFE2E2",
                text: {
                    primary: "#000000",
                    secondary: "#8C8C8C",
                    danger: "#FF4545"
                }
            },
        },
    },
    lang: {
        locales: { ru },
        current: "ru",
    },
});
