<template>
  <v-container>
    <h1 class="mb-5">
      Профиль
    </h1>
    <template v-if="loadingProfile">
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </template>
    <template v-else>
      <div class="profile pa-5">
        <h2 v-if="profile">
          {{ formatFullName(profile.name, profile.last_name, profile.middle_name) }}
        </h2>
      </div>
    </template>
  </v-container>
</template>

<script>
import { callAPI } from "@/services/api";
import { formatFullName } from "@/utils/helpers";

export default {
  name: "ProfileView",
  components: {
  },
  data() {
    return {
      profile: null,
      loadingProfile: false
    }
  },
  mounted() {
    this.loadProfile();
  },
  methods: {
    formatFullName,
    async loadProfile() {
      this.loadingProfile = true;
      this.profile = await callAPI('GET', '/api/getProfile');
      this.loadingProfile = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.profile {
  border: 1px solid #D6D8DE;
}
</style>