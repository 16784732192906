<template>
  <v-dialog
    v-model="show"
    max-width="50vw"
  >
    <v-card>
      <v-card-title class="d-flex">
        <v-spacer />
        <v-btn
          icon
          @click="show = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <div class="image-container">
        <v-img
          :src="image"
          max-height="50vh"
          aspect-ratio="1"
          contain
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ImageDialog",
  props: {
    value: Boolean,
    image: {
      type: String,
      default: ''
    }
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.image-container {
  padding-bottom: 20px;
}
</style>
