<template>
  <v-container>
    <h1>Организации</h1>
    <div>Управление организациями</div>

    <div style="height: 1rem;"></div>

    <v-btn-toggle v-model="mode" mandatory>
      <v-btn
        value="organizationsList"
        color="white"
        @click="$router.push({ path: '/organizations', query: {} })"
      >
        Организации
      </v-btn>
      <v-btn
        value="accreditationRequests"
        color="white"
        @click="
          $router.push({
            path: '/organizations',
            query: { tab: 'accreditationRequests' },
          })
        "
      >
        Заявления на аккредитацию организаций
      </v-btn>
      <v-btn
        value="deactivationRequests"
        color="white"
        @click="
          $router.push({
            path: '/organizations',
            query: { tab: 'deactivationRequests' },
          })
        "
      >
        Заявления на деактивацию организаций
      </v-btn>
      <v-btn
        value="organizationGroupRequests"
        color="white"
        @click="
          $router.push({
            path: '/organizations',
            query: { tab: 'organizationGroupRequests' },
          })
        "
      >
        Заявления на перемещение организаций
        <v-badge
          v-if="changeReqCount"
          inline
          class="ml-1"
          color="#FF0000"
          :content="changeReqCount"
        />
      </v-btn>
    </v-btn-toggle>

    <div class="mt-10">
      <v-slide-x-transition mode="out-in">
        <organizations-list-tab v-if="mode === 'organizationsList'" />
        <accreditation-requests-tab
          v-else-if="mode === 'accreditationRequests'"
        />
        <deactivation-requests-tab
          v-else-if="mode === 'deactivationRequests'"
        />
        <OrganizationGroupReqestsTab
          v-else-if="mode === 'organizationGroupRequests'"
          @updateCount="getChangeGroupCount"
        />
      </v-slide-x-transition>
    </div>
  </v-container>
</template>

<script>
import DeactivationRequestsTab from "@/components/Orgranizations/DeactivationRequests/DeactivationRequestsTab";
import AccreditationRequestsTab from "@/components/Orgranizations/AccreditationRequests/AccreditationRequestsTab";
import OrganizationsListTab from "@/components/Orgranizations/OrgranizationsList/OrganizationsListTab";
import OrganizationGroupReqestsTab from "@/components/Orgranizations/OrganizationGroupRequests/OrganizationGroupReqestsTab.vue";
import { countOrganizationGroupChangeRequests } from "@/services/api";

export default {
  name: "OrganizationsView",

  components: {
    DeactivationRequestsTab,
    AccreditationRequestsTab,
    OrganizationsListTab,
    OrganizationGroupReqestsTab,
  },

  data() {
    return {
      mode: "organizationsList",
      changeReqCount: null,
    };
  },

  beforeMount() {
    if (this.$route.query?.tab) {
      this.mode = this.$route.query?.tab;
    }
  },
  methods: {
    async getChangeGroupCount() {
      this.changeReqCount = await countOrganizationGroupChangeRequests();
    },
  },
  async created() {
    this.getChangeGroupCount();
  },
};
</script>

<style scoped></style>
