var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('h1',[_vm._v("Организации")]),_c('div',[_vm._v("Управление организациями")]),_c('div',{staticStyle:{"height":"1rem"}}),_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.mode),callback:function ($$v) {_vm.mode=$$v},expression:"mode"}},[_c('v-btn',{attrs:{"value":"organizationsList","color":"white"},on:{"click":function($event){return _vm.$router.push({ path: '/organizations', query: {} })}}},[_vm._v(" Организации ")]),_c('v-btn',{attrs:{"value":"accreditationRequests","color":"white"},on:{"click":function($event){return _vm.$router.push({
          path: '/organizations',
          query: { tab: 'accreditationRequests' },
        })}}},[_vm._v(" Заявления на аккредитацию организаций ")]),_c('v-btn',{attrs:{"value":"deactivationRequests","color":"white"},on:{"click":function($event){return _vm.$router.push({
          path: '/organizations',
          query: { tab: 'deactivationRequests' },
        })}}},[_vm._v(" Заявления на деактивацию организаций ")]),_c('v-btn',{attrs:{"value":"organizationGroupRequests","color":"white"},on:{"click":function($event){return _vm.$router.push({
          path: '/organizations',
          query: { tab: 'organizationGroupRequests' },
        })}}},[_vm._v(" Заявления на перемещение организаций "),(_vm.changeReqCount)?_c('v-badge',{staticClass:"ml-1",attrs:{"inline":"","color":"#FF0000","content":_vm.changeReqCount}}):_vm._e()],1)],1),_c('div',{staticClass:"mt-10"},[_c('v-slide-x-transition',{attrs:{"mode":"out-in"}},[(_vm.mode === 'organizationsList')?_c('organizations-list-tab'):(_vm.mode === 'accreditationRequests')?_c('accreditation-requests-tab'):(_vm.mode === 'deactivationRequests')?_c('deactivation-requests-tab'):(_vm.mode === 'organizationGroupRequests')?_c('OrganizationGroupReqestsTab',{on:{"updateCount":_vm.getChangeGroupCount}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }