<template>
  <div>
    <h2>Тарифные планы</h2>

    <div style="height: 1rem;"></div>

    <v-btn-toggle
      v-model="tab"
      mandatory
    >
      <v-btn
        value="customer_plans"
        color="white"
      >
        Для покупателей
      </v-btn>
      <v-btn
        value="supplier_plans"
        color="white"
      >
        Для поставщиков
      </v-btn>
    </v-btn-toggle>

    <div style="height: 1rem;"></div>

    <template v-if="tab === 'customer_plans'">
      <customer-plans-table />
    </template>
    <template v-else>
      <supplier-plans-table />
    </template>
  </div>
</template>

<script>
import CustomerPlansTable from "@/components/Finance/Plans/CustomerPlansTable.vue";
import SupplierPlansTable from "@/components/Finance/Plans/SupplierPlansTable.vue";

export default {

  components: {
    CustomerPlansTable,
    SupplierPlansTable
  },

  data() {
    return {
      tab: 'customer_plans'
    }
  }
}
</script>

<style scoped>

</style>
