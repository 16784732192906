<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card :loading="loading">
      <v-card-title>
        Выберите группу из списка

        <v-spacer />

        <v-btn icon @click="dialog = false">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mb-0 dialog-body">
        <p v-if="!loading && !groups.length">
          У пользователя нет ни одной Группы компаний. Необходимо создать группу
          и вернуться к процедуре прикрепления организации
        </p>
        <v-select
          v-else
          placeholder="Выберите группу"
          :items="groups"
          item-text="name"
          item-value="id"
          v-model="group"
          outlined
          dense
        />
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-container>
          <v-row v-if="!loading && !groups.length">
            <v-col>
              <v-btn color="primary" outlined block @click="dialog = false">
                Закрыть
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col>
              <v-btn color="primary" outlined block @click="dialog = false">
                Отменить
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                type="submit"
                block
                elevation="0"
                :disabled="!isGroupSelected"
                @click="
                  $emit('submit', group);
                  $emit('input', false);
                "
              >
                Применить
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { listUserOrganizationGroups } from "@/services/api";

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      group: null,
      groups: [],
      loading: false,
    };
  },
  watch: {
    dialog(isOpen) {
      if (isOpen) {
        this.getGroups();
      } else {
        this.groups = [];
      }
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isGroupSelected() {
      return !!this.group;
    },
  },
  methods: {
    async getGroups() {
      this.loading = true;
      try {
        this.groups = await listUserOrganizationGroups({
          user_id: this.user?.item?.id,
        });
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Ошибка",
          text: err.message,
          duration: 5000,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
