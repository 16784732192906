<template>
  <v-container>
    <h1>Процедуры</h1>
    <div>Управление закупочными процедурами</div>

    <div style="height: 1rem" />

    <div style="display: flex; justify-content: space-between; align-items: center;">
      <v-checkbox label="Показывать черновики и отозванные" v-model="showRevokedAndDrafts" @change="fetchTableItems(true, true)" :disabled="table.loading"></v-checkbox>
      <search-input
          v-model="search.value"
          label="Поиск по номеру закупки"
          placeholder="Введите часть номера закупки для поиска"
          style="max-width: 400px"
          @search="onSearch()"
      />
    </div>

    <div style="height: 1rem" />

    <v-data-table
        :headers="table.headers"
        :loading="table.loading"
        loading-text="Загрузка..."
        :items="table.items"
        :server-items-length="table.total"
        :page.sync="table.page"
        :items-per-page.sync="table.limit"
        :footer-props="{ 'items-per-page-options': [ 10, 30, 50, 100 ] }"
        @update:items-per-page="fetchTableItems(false, true)"
        @update:page="fetchTableItems(false, true)"
        disable-pagination
        style="border: 1px solid rgba(0, 0, 0, 0.12)"
    >
      <template #[`item.id`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-chip small style="min-width: 80px; justify-content: center;" :color="getStatusColor(item.status)" v-on="on" v-bind="attrs">{{ item.id }}</v-chip>
          </template>
          <div>{{ getStatusText(item.status) }}</div>
        </v-tooltip>
      </template>
      <template #[`item.name`]="{ item }">
        <div style="max-width: 300px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-weight: 500;" :title="item.name">{{ item.name }}</div>
        <div></div>
      </template>
      <template #[`item.organization_inn`]="{ item }">
        <div v-if="item.organization_inn" style="display: flex; flex-direction: row; align-items: center;">
          <v-avatar class="elevation-1 mr-2 my-2" color="white">
            <v-img v-if="item.organization_group_logo" :src="item.organization_group_logo.xs"/>
            <v-icon v-else style="opacity: 0.25;">mdi-image-off</v-icon>
          </v-avatar>
          <div style="font-size: small;">
            <div style="max-width: 200px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" :title="item.organization_group_name">{{ item.organization_group_name }}</div>
            <div style="opacity: 0.5;" :title="`Организация ${item.organization_name}`">ИНН {{ item.organization_inn }}</div>
          </div>
        </div>
        <div v-else style="margin-left: 16px;">&mdash;</div>
      </template>
      <template #[`item.auction_opens_at`]="{ item }">
        <div style="white-space: nowrap; display: flex; justify-content: start; align-items: center;">
          <div>{{ formatDate(item.auction_opens_at, true) ?? '&mdash;' }}</div>
        </div>
      </template>
      <template #[`item.offers_deadline_at`]="{ item }">
        <div style="white-space: nowrap; display: flex; justify-content: start; align-items: center;">
          <div class="mr-2">{{ formatDate(item.offers_deadline_at, true) ?? '&mdash;' }}</div>
          <v-tooltip v-if="item.offers_deadline_at && new Date(item.offers_deadline_at) >= new Date() && item.status === 'open'" top>
            <template #activator="{ on, attrs }">
              <v-btn
                  icon
                  class="mr-2"
                  @click="changeDeadlineDate(item)"
                  v-on="on"
                  v-bind="attrs"
              >
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
            </template>
            <div>Изменить дату</div>
          </v-tooltip>
        </div>
      </template>
      <template #[`item.auction_closes_at`]="{ item }">
        <div style="white-space: nowrap; display: flex; justify-content: start; align-items: center;">
          <div class="mr-2">{{ formatDate(item.auction_closes_at, true) ?? '&mdash;' }}</div>
          <v-tooltip v-if="item.auction_closes_at && new Date(item.auction_closes_at) >= new Date() && item.status === 'open'" top>
            <template #activator="{ on, attrs }">
              <v-btn
                  icon
                  class="mr-2"
                  @click="changeClosingDate(item)"
                  v-on="on"
                  v-bind="attrs"
              >
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
            </template>
            <div>Изменить дату</div>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { callAPI } from "@/services/api";
import { formatDate } from "@/utils/helpers";
import SearchInput from "@/components/SearchInput.vue";

export default {

  components: { SearchInput },

  inject: [ 'alert' ],

  data() {
    return {
      search: {
        value: '',
        debounceTimer: null,
        loading: false
      },
      table: {
        loading: true,
        headers: [
          {
            text: 'Номер',
            sortable: false,
            align: 'center',
            value: 'id'
          },
          {
            text: 'Наименование',
            sortable: false,
            value: 'name'
          },
          {
            text: 'Организатор',
            sortable: false,
            value: 'organization_inn'
          },
          {
            text: 'Начало',
            sortable: false,
            align: 'start',
            value: 'auction_opens_at',
            width: 200
          },
          {
            text: 'Прием предложений до',
            sortable: false,
            align: 'start',
            value: 'offers_deadline_at',
            width: 200
          },
          {
            text: 'Подведение итогов',
            sortable: false,
            align: 'start',
            value: 'auction_closes_at',
            width: 200
          }
        ],
        page: 1,
        limit: 10,
        total: 0,
        items: []
      },
      showRevokedAndDrafts: false
    }
  },

  beforeMount() {
    this.fetchTableItems();
  },

  methods: {
    formatDate,

    async fetchTableItems(reset, clear) {
      this.table.loading = true;
      if (reset) { this.table.page = 1; }
      if (clear) { this.table.items.splice(0, this.table.items.length); }
      const result = await callAPI('POST', '/api/operator/listProcurements', {
        page: this.table.page,
        limit: this.table.limit,
        search: this.search.value,
        show_revoked_and_drafts: this.showRevokedAndDrafts
      });
      this.table.total = result.total;
      this.table.items = result.items;
      this.table.loading = false;
    },

    onSearch() {
      // cancel pending call
      clearTimeout(this.search.debounceTimer);
      // delay new call 500ms
      this.search.debounceTimer = setTimeout(() => {
        this.search.loading = true;
        this.table.page = 1; // reset page on search
        this.fetchTableItems()
            .finally(() => this.search.loading = false);
      }, 500);
    },

    getStatusText(status) {
      switch (status) {
        case 'revoked': return 'Отозвана';
        case 'closed': return 'Завершена';
        case 'open': return 'Открыта';
        case 'draft': return 'Черновик';
        default: return status;
      }
    },
    getStatusColor(status) {
      switch (status) {
        case 'revoked': return 'error';
        case 'closed': return 'success';
        case 'open': return 'warning';
      }
    },

    changeDeadlineDate(item) {
      this.$root.$prompt({
        title: 'Дата окончания приема предложений',
        value: item.offers_deadline_at,
        type: 'date',
        ok: 'Сохранить'
      }).then(async ({ value, callback }) => {
        try {
          await callAPI('POST', '/api/operator/changeProcurementDeadlineDate', { procurement_id: item.id, offers_deadline_at: value });
          this.fetchTableItems();
        } catch (error) {
          this.alert({ dialogType: 'error', text: error });
        } finally {
          callback();
        }
      });
    },

    changeClosingDate(item) {
      this.$root.$prompt({
        title: 'Дата подведения итогов',
        value: item.auction_closes_at,
        type: 'date',
        ok: 'Сохранить'
      }).then(async ({ value, callback }) => {
        try {
          await callAPI('POST', '/api/operator/changeProcurementClosingDate', { procurement_id: item.id, auction_closes_at: value });
          this.fetchTableItems();
        } catch (error) {
          this.alert({ dialogType: 'error', text: error });
        } finally {
          callback();
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
