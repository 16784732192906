import Vue from 'vue';
import Vuex from 'vuex';
import { login } from "@/services/api";


Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        loading: false,
        auth: {
            user: {},
            token: null
        },
        alert: {
            title: null,
            text: null,
            visible: false,
            okOnly: false,
            type: null,
            onOkCallback: null,
            onCancelCallback: null
        },
        toast: {
            value: false,
            text: ''
        }
    },
    getters: {},
    mutations: {
        SET_TOKEN(state, token) {
            state.auth.token = token
        },
        SET_LOADING(state, status) {
            state.loading = status
        },
        SET_ALERT(state, alert) {
            state.alert = Object.assign(state.alert, alert);
            state.alert.visible = true;
        },
        SET_TOAST(state, text) {
            state.toast.text = text;
            state.toast.value = true;
        }
    },
    actions: {

        showError(state, text) {
            store.commit('SET_ALERT', {
                type: 'error',
                okOnly: true,
                text: text ?? 'Произошла неизвестная ошибка'
            })
        },

        notify(state, text) {
            store.commit('SET_TOAST', text)
        },

        // eslint-disable-next-line no-unused-vars
        async login({ commit, dispatch }, payload) {
            await login(payload)
                .then(data => {
                    localStorage.setItem('token', data)
                    commit('SET_TOKEN', data)
                })
        },
        async logout({ commit }) {
            localStorage.removeItem('token')
            commit('SET_TOKEN', null)
            window.location.href = ""
        },
        async init({ commit, state }) {
            const token = localStorage.getItem('token')
            if (token) {
                commit('SET_TOKEN', token)
            }
        },
    },
});
