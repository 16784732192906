import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import vuetify from './plugins/vuetify'
import "./plugins/vee-validate";
import Notifications from 'vue-notification'

import './scss/index.scss';
import './scss/typography.scss';
import {store} from "@/store";

Vue.config.productionTip = false

Vue.use(Notifications);

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
