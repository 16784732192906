<template>
  <v-dialog v-model="shown" max-width="600" :persistent="loading" @click:outside="hide()">
    <v-card>
      <v-card-title>
        {{ title }}
        <v-spacer/>
        <v-btn icon @click="hide()" :disabled="loading"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-subtitle v-if="text" v-html="text"></v-card-subtitle>
      <v-card-text>
        <template v-if="type === 'select'">
          <v-autocomplete :label="label" dense outlined hide-details v-model="value" :disabled="loading" :items="items" item-text="name" item-value="id" return-object></v-autocomplete>
        </template>
        <template v-if="type === 'text'">
          <v-text-field :label="label" dense outlined hide-details v-model="value" :disabled="loading"/>
        </template>
        <template v-if="type === 'date'">
          <v-row dense>
            <v-col>
              <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      :value="formatDate(value, false)"
                      :label="label"
                      append-icon="mdi-calendar"
                      outlined
                      dense
                      readonly
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                  />
                </template>
                <v-date-picker
                    v-model="valueDate"
                    :min="new Date().toISOString().substr(0, 10)"
                    :first-day-of-week="1"
                    @input="menu = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="auto">
              <v-select
                  v-model="valueTime"
                  style="width: 150px;"
                  dense
                  outlined
                  hide-details
                  append-icon="mdi-clock-outline"
                  :items="[ valueTime, '00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30' ].sort()"
              />
            </v-col>
          </v-row>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn color="normal" elevation="0" large block outlined text @click="cancel()" :disabled="loading">Отмена</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn color="primary" elevation="0" large block @click="confirm()" :loading="loading">{{ ok }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatDate } from "@/utils/helpers";

export default {

  data() {
    return {
      shown: false,
      title: '',
      text: '',
      label: '',
      type: '',
      value: '',
      items: [],
      menu: null,
      ok: 'Подтвердить',
      loading: false,
      resolve: null,
      reject: null
    }
  },

  computed: {
    valueTime: {
      get() {
        const date = new Date(this.value);
        return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
      },
      set(value) {
        const time = value.split(':', 2);
        this.value.setHours(parseInt(time[0]), parseInt(time[1]), 0, 0);
      }
    },
    valueDate: {
      get() {
        return this.value.toISOString().substr(0, 10);
      },
      set(value) {
        const date = new Date(value);
        this.value.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
      }
    }
  },

  mounted() {
    this.$root.$prompt = this.show;
  },

  methods: {
    show(params) {
      this.title = params.title;
      this.text = params.text;
      this.label = params.label;
      this.type = params.type ?? 'text';
      this.value = params.value;
      this.items = params.items;
      this.ok = params.ok ?? 'Подтвердить';
      this.loading = false;
      this.shown = true;

      if (this.type === 'date' && !(this.value instanceof Date)) { // value should be Date object if prompt type is date
        this.value = new Date(this.value);
      }

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      })
    },

    hide() {
      this.shown = false;
    },

    confirm() {
      this.loading = true;
      this.resolve({ value: this.value, callback: () => {
        this.loading = false;
        this.hide();
      }});
    },

    cancel() {
      this.reject();
      this.hide();
    },

    formatDate
  }
}
</script>
