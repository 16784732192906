<template>
  <ValidationObserver>
    <ValidationProvider
      v-for="index in categoriesChainLength"
      v-slot="{ errors }"
      :key="index"
      :name="fieldName(index)"
      rules="required"
    >
      <v-select
        v-model="categoriesChain[index - 1]"
        :items="categoriesWithLevels[index - 1]"
        item-text="name"
        item-value="id"
        :label="label(index)"
        :placeholder="placeholder(index)"
        outlined
        dense
        :error-messages="errors"
        @change="onCategoryChange(index)"
      />
    </ValidationProvider>
  </ValidationObserver>
</template>

<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {

  components: {
    ValidationObserver,
    ValidationProvider
  },

  props: {
    categories: {
      type: Array,
      default: () => []
    },
    chain: {
      type: Array,
      default: () => []
    },
    selectedCategory: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      categoriesChainLength: 1,
      categoriesChain: [],
      categoriesWithLevels: []
    }
  },
  watch: {
    selectedCategory: {
      immediate: true,
      handler (value) {
        if (!value) {
          this.categoriesChain = [];
          this.categoriesWithLevels = [];
        }
      }
    }
  },
  beforeMount() {
    this.buildCategoriesWithLevels()
    if (this.chain.length !== 0) {
      this.categoriesChain = this.chain
      this.categoriesChainLength = this.chain.length

      this.buildCategoriesWithLevels()
    }
  },
  methods: {
    fieldName(index) {
      return index > 1 ? 'Подкатегория' : 'Категория'
    },
    buildCategoriesWithLevels() {
      this.categoriesWithLevels = []

      for (let i = 0; i < this.categoriesChainLength; i++) {
        let categories = this.getCategoriesByLevel(i)
        if (categories) {
          this.categoriesWithLevels.push(categories)
        } else {
          this.categoriesChainLength -= 1
        }
      }
    },
    getCategoriesByLevel(level) {
      if (level === 0) {
        return this.getRootCategories()
      } else {
        let categories = this.categories.filter(item => item.parent_category_id === this.categoriesChain[level - 1])

        if (categories.length === 0) {
          return false
        } else {
          return categories
        }
      }
    },
    getRootCategories() {
      return this.categories.filter(item => item.parent_category_id === 0)
    },
    onCategoryChange(index) {
      this.$emit('change', this.categoriesChain[index - 1])
      if (index === 1) {
        this.categoriesChain.splice(index, 1)
      }
      this.categoriesChainLength = index + 1
      this.buildCategoriesWithLevels()
    },
    label(index) {
      return index === 1 ? 'Категория' : 'Подкатегория'
    },
    placeholder(index) {
      return index === 1 ? 'Выберите категорию' : 'Выберите подкатегорию'
    }
  }
}
</script>

<style scoped>

</style>
