<template>
  <v-dialog
    v-model="show"
    width="820"
    scrollable
  >
    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-card>
      <v-card-title class="d-flex">
        <span class="text-h5">
          <template v-if="mode === 'deposit'">
            Просмотр заявки на ввод средств
          </template>
          <template v-if="mode === 'withdrawal'">
            Просмотр заявки на вывод средств
          </template>
        </span>
        <v-spacer />
        <v-btn
          icon
          @click="show = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="my-0 dialog-body">
        <v-container>
          <form-row label="Номер заявки">
            {{ item.id }}
          </form-row>

          <form-row label="Организация-плательщик">
            <div class="mb-4">
              {{ item.organization_name }}
            </div>
            <v-btn
              color="primary"
              outlined
              @click="openOrganizationDialog(item.organization_inn)"
            >
              Посмотреть организацию <v-icon class="ml-2">
                mdi-briefcase
              </v-icon>
            </v-btn>
          </form-row>
          <form-row label="Статус">
            <v-chip
              v-if="item.status === 'completed'"
              color="success"
              small
            >
              Завершена
            </v-chip>
            <v-chip
              v-else-if="item.status === 'pending'"
              color="info"
              small
            >
              Обрабатывается
            </v-chip>
            <v-chip
              v-else-if="item.status === 'rejected'"
              color="error"
              small
            >
              Отклонена
            </v-chip>
            <v-chip
              v-else-if="item.status === 'canceled'"
              color="error"
              outlined
              small
            >
              Аннулирована
            </v-chip>
          </form-row>


          <form-row label="Дата создания">
            {{ formatDateTimeHumane(item.created_at) }}
          </form-row>

          <form-row label="Дата обновления (смены статуса)">
            {{ fallback(formatDateTimeHumane(item.updated_at), 'Статус ещё не менялся') }}
          </form-row>

          <form-row label="Назначение">
            <template v-if="item.type === 'deposit'">
              Ввод
            </template>
            <template v-else-if="item.type === 'withdrawal'">
              Вывод
            </template>
            <template v-else>
              {{ item.type }}
            </template>
          </form-row>

          <form-row label="ФИО автора заявки">
            {{ fallback(formatFullName(item.user_name, item.user_last_name, item.user_middle_name)) }}
          </form-row>

          <form-row
            v-if="item.updated_at"
            label="ФИО оператора"
          >
            {{ fallback(formatFullName(item.operator_name, item.operator_last_name, item.operator_middle_name)) }}
          </form-row>

          <form-row label="Сумма">
            {{ item.amount }} руб.
          </form-row>
        </v-container>
      </v-card-text>
      <v-card-actions v-if="item.status === 'pending'">
        <v-container>
          <v-row>
            <v-col>
              <v-btn
                elevation="0"
                block
                color="success"
                @click="approve"
              >
                <template v-if="mode === 'deposit'">
                  Зачислить деньги в кошелек
                </template>
                <template v-if="mode === 'withdrawal'">
                  Заявка на вывод исполнена
                </template>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                block
                elevation="0"
                color="error"
                @click="reject"
              >
                Отклонить заявку
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
    <success-dialog
      v-model="successDialog"
      :text="successDialogText"
    />
    <error-dialog v-model="errorDialog" />

    <organization-dialog
      v-model="organizationDialog"
      :org-id="organizationInn"
      hide-actions
      @close="organizationDialog=false"
    />
  </v-dialog>
</template>

<script>
import FormRow from "@/components/FormRow";
import { formatFullName, fallback, formatDateTimeHumane } from "@/utils/helpers";
import { approveWithdrawalRequest, rejectWithdrawalRequest, approveDepositRequest, rejectDepositRequest } from "@/services/api";
import SuccessDialog from "@/components/Dialogs/SuccessDialog";
import ErrorDialog from "@/components/Dialogs/ErrorDialog";
import OrganizationDialog from "@/components/Orgranizations/OrgranizationsList/OrganizationDialog";

export default {
  name: "RequestDialog",
  components: {
    FormRow,
    SuccessDialog,
    ErrorDialog,
    OrganizationDialog
  },

  props: {
    value: Boolean,
    label: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: "deposit"
    },
    item: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      successDialog: false,
      loading: false,
      errorDialog: false,
      organizationDialog: false,
      organizationInn: '',
      successDialogText: ''
    }
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  beforeMount() {
  },

  methods: {
    formatDateTimeHumane,
    fallback,
    formatFullName,

    openOrganizationDialog(inn) {
      this.organizationDialog = true;
      this.organizationInn = inn;
    },

    async approve() {
      try {
        this.loading = true
        if (this.mode === 'deposit') {
          await approveDepositRequest({
            request_id: this.item.id
          })
          this.successDialogText = 'Деньги были зачислены в кошелек пользователя'
        } else {
          await approveWithdrawalRequest({
            request_id: this.item.id
          })
          this.successDialogText = 'Деньги были выведены с кошелька пользователя'
        }
        this.successDialog = true
        this.show = false
        this.$emit('done')
      } catch (e) {
        this.errorDialog = true
      } finally {
        this.loading = false
      }
    },

    async reject() {
      try {
        this.loading = true
        if (this.mode === 'deposit') {
          await rejectDepositRequest({
            request_id: this.item.id
          })
        } else {
          await rejectWithdrawalRequest({
            request_id: this.item.id
          })
        }
        this.successDialogText = 'Заявка была отклонена'
        this.successDialog = true
        this.show = false
        this.$emit('done')
      } catch (e) {
        this.errorDialog = true
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
