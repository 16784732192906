<template>
  <v-container fluid class="px-10">
    <h1>Теги</h1>
    <div>Администрирование тегов</div>

    <div style="height: 1rem" />

    <div style="display: flex; justify-content: space-between; align-items: center;">
      <div style="display: flex; align-items: center;">
        <search-input
            v-model="search.value"
            label="Поиск по названию"
            placeholder="Введите часть названия для поиска"
            style="width: 400px"
            @search="onSearch()"
        />
        <div style="width: 1rem;"></div>
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
              <v-checkbox label="Пользовательские" v-model="showOnlyTagsCreatedByUsers" @change="fetchTableItems(true, false)" :disabled="table.loading"></v-checkbox>
            </div>
          </template>
          <div>Показывать только созданные пользователями теги</div>
        </v-tooltip>
      </div>
      <v-btn
          outlined
          @click="downloadXLSX()"
          :loading="downloading"
      >
        <v-icon class="mr-3" style="opacity: 0.75;">mdi-download</v-icon> Скачать в формате .xlsx
      </v-btn>
    </div>

    <div style="height: 1rem" />

    <div style="position: relative;">
      <v-data-table
          :headers="table.headers"
          :loading="table.loading"
          loading-text="Загрузка..."
          :items="table.items"
          :server-items-length="table.total"
          :page.sync="table.page"
          :items-per-page.sync="table.limit"
          :footer-props="{ 'items-per-page-options': [ 10, 30, 50, 100 ] }"
          @update:items-per-page="fetchTableItems(false, true)"
          @update:page="fetchTableItems(false, true)"
          disable-pagination
          style="border: 1px solid rgba(0, 0, 0, 0.12)"
          class="tags-table"
          :item-class="(item) => item.hidden ? 'disabled' : ''"
      >
        <template #[`item.id`]="{ item }">
          <v-chip small>{{ item.id }}</v-chip>
        </template>
        <template #[`item.creator_full_name`]="{ item }">
          <div v-if="item.creator_full_name" style="font-weight: 500;">{{ item.creator_full_name }} #{{ item.creator_user_id }}</div>
          <div v-else style="opacity: 0.5;">Оператор Atis Trade</div>
        </template>
        <template #[`item.name`]="{ item }">
          <div :style="{ opacity: item.hidden ? 0.5 : 1.0 }">{{ item.name }}</div>
        </template>
        <template #[`item.usage`]="{ item }">
          <div class="text-right" style="display: grid; grid-template-rows: auto auto; grid-template-columns: auto auto; grid-column-gap: 1em; padding-top: 0.5rem; padding-bottom: 0.5rem;">
            <div class="text-caption" style="opacity: 0.75;">Количество профилей покупателей, в которых указан тег:</div>
            <div class="text-left" :class="{ 'font-weight-medium': item.usage_in_customer_organization_groups > 0 }">{{ item.usage_in_customer_organization_groups }}</div>

            <div class="text-caption" style="opacity: 0.75;">Количество профилей поставщиков, в которых указан тег:</div>
            <div class="text-left" :class="{ 'font-weight-medium': item.usage_in_supplier_organization_groups > 0 }">{{ item.usage_in_supplier_organization_groups }}</div>

            <div class="text-caption" style="opacity: 0.75;">Количество опубликованных (открытых) закупок, в которых указан тег:</div>
            <div class="text-left" :class="{ 'font-weight-medium': item.usage_in_open_procurements > 0 }">{{ item.usage_in_open_procurements }}</div>

            <div class="text-caption" style="opacity: 0.75;">Количество закрытых закупок, в которых указан тег:</div>
            <div class="text-left" :class="{ 'font-weight-medium': item.usage_in_closed_procurements > 0 }">{{ item.usage_in_closed_procurements }}</div>
          </div>
        </template>
        <template #[`item.actions`]="{ item }">
          <div style="white-space: nowrap;">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                    icon
                    class="mr-2"
                    @click="onClickNameEdit(item)"
                    v-on="on"
                    v-bind="attrs"
                >
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn>
              </template>
              <div>Редактировать название</div>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                    icon
                    class="mr-2"
                    @click="item.hidden ? onClickShow(item) : onClickHide(item)"
                    v-on="on"
                    v-bind="attrs"
                >
                  <v-icon>{{ item.hidden ? 'mdi-eye-outline' : 'mdi-eye-off-outline' }}</v-icon>
                </v-btn>
              </template>
              <div>{{ item.hidden ? 'Восстановить' : 'Скрыть' }}</div>
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                    icon
                    @click="onClickDuplicate(item)"
                    v-on="on"
                    v-bind="attrs"
                >
                  <v-icon>mdi-content-duplicate</v-icon>
                </v-btn>
              </template>
              <div>Объединить</div>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
      <v-fade-transition>
        <v-overlay v-if="search.loading" absolute color="white"/>
      </v-fade-transition>
    </div>

    <v-overlay v-if="duplicateDialog.loading">
      <v-progress-circular
          indeterminate
          size="64"
      />
    </v-overlay>
  </v-container>
</template>

<style>
.v-application .v-data-table.tags-table tr.disabled {
  background-color: #FFF0F3;
}
</style>

<script>
import { callAPI } from "@/services/api";
import { formatDate, formatDateHumane, formatDateTimeHumane } from "@/utils/helpers";
import SearchInput from "@/components/SearchInput.vue";

export default {

  components: { SearchInput },

  inject: [ 'alert' ],

  data() {
    return {
      search: {
        value: '',
        debounceTimer: null,
        loading: false
      },
      table: {
        loading: true,
        headers: [
          {
            text: 'ID',
            sortable: false,
            align: 'center',
            value: 'id',
            width: 10
          },
          {
            text: 'Автор',
            sortable: false,
            value: 'creator_full_name'
          },
          {
            text: 'Название тега',
            sortable: false,
            value: 'name'
          },
          {
            text: 'Статистика',
            sortable: false,
            align: 'center',
            value: 'usage'
          },
          {
            text: '',
            sortable: false,
            value: 'actions',
            width: 155,
          }
        ],
        page: 1,
        limit: 10,
        total: 0,
        items: []
      },
      downloading: false,
      duplicateDialog: {
        loading: false
      },
      showOnlyTagsCreatedByUsers: false
    }
  },

  beforeMount() {
    this.fetchTableItems();
  },

  methods: {
    async fetchTableItems(reset, clear) {
      this.table.loading = true;
      if (reset) { this.table.page = 1; }
      if (clear) { this.table.items.splice(0, this.table.items.length); }
      const result = await callAPI('POST', '/api/operator/listTags', {
        page: this.table.page,
        limit: this.table.limit,
        search: this.search.value,
        only_created_by_users: this.showOnlyTagsCreatedByUsers
      });
      this.table.total = result.total;
      this.table.items = result.items;
      this.table.loading = false;
    },

    onSearch() {
      // cancel pending call
      clearTimeout(this.search.debounceTimer);
      // delay new call 500ms
      this.search.debounceTimer = setTimeout(() => {
        this.search.loading = true;
        this.table.page = 1; // reset page on search
        this.fetchTableItems()
            .finally(() => this.search.loading = false);
      }, 500);
    },

    onClickNameEdit(item) {
      this.$root.$prompt({
        title: 'Редактировать название тега',
        label: 'Название тега',
        value: item.name,
        ok: 'Редактировать'
      }).then(async ({ value, callback }) => {
        try {
          await callAPI('POST', '/api/operator/editTag', { id: item.id, name: value });
          this.fetchTableItems();
        } catch (error) {
          this.alert({ dialogType: 'error', text: error });
        } finally {
          callback();
        }
      })
    },

    onClickShow(item) {
      this.$root.$confirm({
        title: 'Вернуть выбранный тег?',
        text: `Тег ${item.name} будет возвращен из скрытых тегов и станет виден всем пользователям платформы`,
        ok: 'Вернуть тег'
      }).then(async (callback) => {
        try {
          await callAPI('POST', '/api/operator/editTag', { id: item.id, hidden: false });
          this.fetchTableItems();
        } catch (error) {
          this.alert({ dialogType: 'error', text: error });
        } finally {
          callback();
        }
      });
    },

    onClickHide(item) {
      this.$root.$confirm({
        title: 'Скрыть выбранный тег?',
        text: `Тег "${item.name}" будет убран из общего списка тегов`,
        ok: 'Скрыть тег'
      }).then(async (callback) => {
        try {
          await callAPI('POST', '/api/operator/editTag', { id: item.id, hidden: true });
          this.fetchTableItems();
        } catch (error) {
          this.alert({ dialogType: 'error', text: error });
        } finally {
          callback();
        }
      });
    },

    async onClickDuplicate(item) {

      this.duplicateDialog.loading = true;
      const tags = await callAPI('POST', '/api/operator/listTags');
      this.duplicateDialog.loading = false;

      this.$root.$prompt({
        title: 'Объединить с другим тегом',
        text: `Тег "${item.name}" будет заменен на выбранный из списка тег:`,
        type: 'select',
        value: item,
        items: tags.items,
        ok: 'Подтвердить'
      }).then(async ({ value, callback }) => {
        callback();

        this.$root.$confirm({
          title: 'Подтверждение',
          text: `Вы подтверждаете, что тег "${item.name}" заменится на тег "${value.name}" во всех профилях компаний и закупочных процедурах, вследствие чего будет безвозвратно стёрт с платформы?<br><br><div class="error--text">Внимание! Данное действие необратимо!</div>`,
          ok: 'Объединить теги',
          okColor: "error"
        }).then(async (callback) => {
          try {
            await callAPI('POST', '/api/operator/replaceTag', { id: item.id, replace_by_tag_id: value.id });
            this.fetchTableItems();
          } catch (error) {
            this.alert({ dialogType: 'error', text: error });
          } finally {
            callback();
          }
        });
      });
    },

    async downloadXLSX() {
      this.downloading = true;
      try {
        const result = await callAPI('POST', '/api/operator/exportTags');

        // download document
        const a = document.createElement('a');
        a.href = result.document_url;
        a.download = result.document_name;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (error) {
        this.alert({ dialogType: 'error', text: error });
      } finally {
        this.downloading = false;
      }
    }
  }
}
</script>

