<template>
  <div style="position: relative; display: inline-block;">
    <v-btn
      class="filter-btn"
      elevation="0"
      outlined
      @click="$emit('click')"
    >
      <FilterIcon class="mr-2" />

      {{ label }}
    </v-btn>

    <v-scale-transition origin="center">
      <v-btn
        v-if="applied"
        icon
        fab
        dark
        small
        class="filter-btn__clear"
        @click="clear"
      >
        <v-icon dark>
          mdi-close
        </v-icon>
      </v-btn>
    </v-scale-transition>
  </div>
</template>

<script>
import FilterIcon from "@/assets/icons/FilterIcon";

export default {
  name: "FilterButton",
  components: {
    FilterIcon
  },
  props: {
    label: {
      type: String,
      default: 'Фильтры'
    },
    applied: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    clear (e) {
      e.stopPropagation();
      this.$emit('clear');
    }
  }
}
</script>

<style scoped lang="scss">
.filter-btn {
  position: relative;
}
.filter-btn__clear {
  position: absolute;
  width: 16px;
  height: 16px;
  top: -8px;
  right: -8px;
  background: #1867c0;

  .v-icon {
    font-size: 14px !important;
    z-index: 1;
  }
}
</style>
