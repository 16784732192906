<template>
  <v-row>
    <v-col cols="6">
      <b>{{ label }}</b>
    </v-col>
    <v-col>
      <slot />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "FormRow",
  props: {
    label: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>