<template>
  <v-dialog
    v-model="show"
    width="520"
  >
    <v-card>
      <v-card-title class="d-flex">
        <span class="text-h5">Комментарий к деактивации</span>
        <v-spacer />
        <v-btn
          icon
          @click="show = false"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="mb-0 dialog-body">
        <v-textarea
          label="Комментарий"
          placeholder="Комментарий к деактивации организации"
          outlined
          readonly
          :value="comment"
          hide-details
        />
      </v-card-text>

      <v-card-actions>
        <v-container class="pa-0">
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                outlined
                block
                @click="show = false"
              >
                Закрыть
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  name: "DeactivationCommentDialog",

  props: {
    value: Boolean,
    comment: {
      type: String,
      default: ''
    }
  },

  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped>

</style>
