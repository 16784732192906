<template>
  <v-app>
    <v-app-bar
      app
      clipped-left
      short
      class="elevation-0"
      style="border-bottom: 1px solid rgba(0,0,0,0.1)"
    >
      <v-btn
        icon
        @click="drawer = !drawer"
      >
        <v-icon>
          mdi-menu
        </v-icon>
      </v-btn>
      <router-link
        to="/"
        style="display: contents"
      >
        <img
          alt="logo"
          style="padding-left: 10px;"
          src="@/assets/logo.svg"
        >
      </router-link>
      <v-spacer />
      <div class="d-flex">
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                mdi-account-circle
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              link
              to="/profile"
            >
              <v-list-item-title>Профиль</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$store.dispatch('logout')">
              <v-list-item-title>Выход</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      clipped
      app
      style="background: #F6F6F8;"
      floating
    >
      <v-list class="d-flex flex-column pa-0 atis-sidenav">
        <v-list-item
            class="mt-auto"
            exact
            active-class="gray-200 active"
            link
            to="/"
        >
          <v-list-item-action>
            <v-icon color="primary">
              mdi-account
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Пользователи</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            class="mt-auto"
            exact
            active-class="gray-200 active"
            link
            to="/organizations"
        >
          <v-list-item-action>
            <v-icon color="primary">
              mdi-briefcase
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Организации</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            class="mt-auto"
            exact
            active-class="gray-200 active"
            link
            to="/procedures"
        >
          <v-list-item-action>
            <v-icon color="primary">
              mdi-cube
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Процедуры</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          class="mt-auto"
          exact
          active-class="gray-200 active"
          link
          to="/catalog"
        >
          <v-list-item-action>
            <v-icon color="primary">
              mdi-format-list-bulleted-square
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Каталог</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          class="mt-auto"
          exact
          active-class="gray-200 active"
          link
          to="/tags"
        >
          <v-list-item-action>
            <v-icon color="primary">
              mdi-tag-multiple
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Теги</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          class="mt-auto"
          exact
          active-class="gray-200 active"
          link
          to="/finance"
        >
          <v-list-item-action>
            <v-icon color="primary">
              mdi-cash-multiple
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Финансы</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            class="mt-auto"
            exact
            active-class="gray-200 active"
            link
            to="/operators"
        >
          <v-list-item-action>
            <v-icon color="primary">
              mdi-account-tie
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Операторы</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          class="mt-auto"
          exact
          active-class="gray-200 active"
          link
          to="/knowledge_base"
        >
          <v-list-item-action>
            <v-icon color="primary">
              mdi-account-school
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>База знаний</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          class="mt-auto"
          exact
          active-class="gray-200 active"
          link
          to="/about"
        >
          <v-list-item-action>
            <v-icon color="primary">
              mdi-information
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Раздел "О нас"</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
<!--        <v-list-item
          class="mt-auto"
          exact
          active-class="gray-200 active"
          link
          to="/deployment"
        >
          <v-list-item-action>
            <v-icon color="primary">
              mdi-server
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Деплои</v-list-item-title>
          </v-list-item-content>
        </v-list-item>-->
        <v-list-item
          class="mt-auto"
          exact
          active-class="gray-200 active"
          link
          to="/scheduler"
        >
          <v-list-item-action>
            <v-icon color="primary">
              mdi-calendar-clock
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Планировщик</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template #append>
        <v-list class="d-flex flex-column">
          <v-list-item
            class="mt-auto"
            exact
            active-class="gray-200 active"
            link
            to="/profile"
          >
            <v-list-item-action>
              <v-icon>
                mdi-account-circle
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Профиль</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-main>
      <div style="padding: 0 40px 40px;">
        <slot />
      </div>
    </v-main>

    <common-alert-dialog
      v-model="alert.visible"
      :text="alert.text"
      :type="alert.type"
      :title="alert.title"
      :ok-only="alert.okOnly"
      :on-ok-callback="alert.onOkCallback"
      :on-cancel-callback="alert.onCancelCallback"
    />

    <confirmation-dialog ref="confirmationDialog"/>

    <prompt-dialog />

    <toast-notification />

    <v-overlay v-if="$store.state.loading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
  </v-app>
</template>

<script>
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import PromptDialog from "@/components/PromptDialog.vue";
import ToastNotification from "@/components/ToastNotification.vue";
import CommonAlertDialog from "@/components/Dialogs/CommonAlertDialog";

import { mapState } from 'vuex';

export default {
  name: "DefaultLayout",
  components: {
    ConfirmationDialog,
    PromptDialog,
    ToastNotification,
    CommonAlertDialog
  },
  data() {
    return {
      drawer: localStorage.getItem('drawer') !== 'false'
    }
  },
  computed: {
    ...mapState({
      alert: 'alert'
    })
  },
  provide: {
    alert(params) {
      let text = 'Произошла неизвестная ошибка';
      if (params.text instanceof Error) {
        text = params.text.message;
      }
      if (typeof params.text === "string") {
        text = params.text;
      }

      this.$store.commit('SET_ALERT', {
        type: params.dialogType,
        okOnly: params.dialogType === 'error' || params.dialogType === 'success',
        text: text
      })
    }
  },
  watch: {
    drawer(value) {
      localStorage.setItem('drawer', value)
    }
  }
}
</script>

<style scoped>
/*.profile-list-item-title {*/
/*  white-space: normal;*/
/*}*/

.v-navigation-drawer .v-navigation-drawer__content {
  display: flex!important;
  flex-direction: column;

}

.v-application--is-ltr .v-list-item__action {
    margin-right: 24px;
}
.atis-sidenav .v-list-item__title {
    font-size: small;
}
.atis-sidenav .v-list-item--active {
  font-weight: 500;
}
</style>
